/**
 * Dynamically updates fields based on multiple conditions.
 * @param {Array} prevFields - The existing list of fields.
 * @param {Object} parsedDetails - Parsed form details containing values.
 * @param {Object} ticketData - Ticket-related data (e.g., service year).
 * @param {Function} adjustYear - Function to adjust the year dynamically.
 * @param {Array} conditions - Array of condition configurations.
 * @returns {Array} Updated list of fields.
 */
export const handleConditionalFields = (prevFields, parsedDetails, ticketData, adjustYear, conditions) => {
    let updatedFields = [...prevFields];

    conditions.forEach(({ triggerField, fieldNames }) => {
        const dynamicFieldNames = fieldNames.map((fieldName) => 
            fieldName
                .replace("{year}", ticketData.service_year_value)
                .replace("{year_minus_1}", adjustYear(ticketData.service_year_value, -1))
                .replace("{year_minus_2}", adjustYear(ticketData.service_year_value, -2))
        );

        const hasConditionalFields = dynamicFieldNames.some(
            (name) => parsedDetails[name]
        );

        const alreadyIncludesExtraFields = dynamicFieldNames.some((name) =>
            updatedFields.some((field) => field.name === name)
        );

        if (hasConditionalFields && !alreadyIncludesExtraFields) {
            const targetIndex = updatedFields.findIndex(
                (field) => 
                    field.name === triggerField.replace("{year}", ticketData.service_year_value)
            );

            if (targetIndex !== -1) {
                updatedFields.splice(
                    targetIndex + 1,
                    0,
                    ...dynamicFieldNames.map((name) => ({
                        name,
                        type: "typography-textfield",
                    }))
                );
            }
        }
    });

    return updatedFields;
};
