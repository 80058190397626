import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { Box, Button, Grid, TextField, Typography, TextareaAutosize, Radio, RadioGroup, FormControlLabel, IconButton } from '@mui/material';
import { getValidAccessToken } from '../../../utils/tokenUtils';
import jsPDF from 'jspdf';
import RemoveIcon from '@mui/icons-material/Remove';
import autoTable from "jspdf-autotable";
import BusinessFormationForm from "./BusinessFormationForm";
import PdfView from "../pdf/PdfView";
import { adjustYear, useAdjustDateFormat } from '../../../hooks/adjustYear';
import { useDynamicFields } from '../../../hooks/useDynamicFields';
import { handleConditionalFields } from '../../../hooks/handleConditionalFields';


const apiUrl = process.env.REACT_APP_API_URL;

const BasicDetailsForm = ({ userType, ticketData, setTicketData, ticketId }) => {
    const [basicDetailsForm, setBasicDetailsForm] = useState({});
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [database, setDatabase] = useState(new Map());

    function toPascalCase(str) {
        return str.replace(/_/g, ' ')
            .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
                index === 0 ? match.toUpperCase() : match.toUpperCase()
            );
    }
    const [dependentsForm, setDependentsForm] = useState({});
    const [stateDetailsForm, setStateDetailsForm] = useState({});
    const [incomesForm, setIncomesForm] = useState({});
    const [rentalForm, setRentalForm] = useState({});
    const [expensesForm, setExpensesForm] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [isDocumentVerified, setIsDocumentVerified] = useState(ticketData.organizer_verified);


    useEffect(() => {
      const fetchBasicDetailsForm = async () => {
        try {
          const token = await getValidAccessToken();
          const response = await axios.post(
            `${apiUrl}/tax-organizer-details/`,
            {
              tax_organizer_id: ticketData.tax_organizer_id,
              column_names: "basic_details_form",
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          const details = response.data.data.basic_details_form;

          if (details) {
            const parsedDetails = JSON.parse(details);
            setBasicDetailsForm(parsedDetails);
            setPersonalDetailsValues(parsedDetails.personalDetailsValues);
            setSpouseDetailsValues(parsedDetails.spouseDetailsValues);
            setBankDetailsValues(parsedDetails.bankDetailsValues);
            setMmailingAddressDetailsValues(
              parsedDetails.mailingAddressDetailsValues
            );
            const conditions = [{
                triggerField: "did_you_reside_at_least_6_months_in_US_during_{year}",
                fieldNames: [
                    "number_of_days_resided_in_US_during_{year}?",
                    "number_of_days_resided_in_US_during_{year_minus_1}?",
                    "number_of_days_resided_in_US_during_{year_minus_2}?",
                ],
            },
            {
                triggerField: "was_there_any_change_in_your_visa_during_{year}",
                fieldNames: [
                    "can_you_specify_the_visa_type_during_{year}",
                ],
            },
            {
                triggerField: "current_visa_type",
                fieldNames: [
                    "can_you_specify_the_visa_type",
                ],
            },
            {
                triggerField: "marital_status",
                fieldNames: [
                    "date_of_mariage",
                ],
            }
        
        ];
        

       
            // Dynamically add fields based on saved data
            setPersonalDetailsFields((prevFields) =>
                handleConditionalFields(prevFields, parsedDetails.personalDetailsValues, ticketData, adjustYear, conditions)
            );
            setSpouseDetailsFields((prevFields) =>
                handleConditionalFields(prevFields, parsedDetails.spouseDetailsValues, ticketData, adjustYear, conditions)
            );
          }
        } catch (error) {
          console.error("Error fetching business formation details:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchBasicDetailsForm();
    }, [ticketData.tax_organizer_id,ticketData.service_year_value, adjustYear]);
    

    useEffect(() => {
        const fetchbookKeepingForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'dependents_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.dependents_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setDependentsForm(parsedDetails);
                    //setVehicleInfo(parsedDetails.vehicleInfo || []);
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchbookKeepingForm();
    }, [ticketData.tax_organizer_id]);



    useEffect(() => {
        const fetchbookKeepingForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'state_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.state_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setStateDetailsForm(parsedDetails);
                   // setTaxPayerResidency(parsedDetails.taxPayerResidency || []);
                   // setSpouseResidency(parsedDetails.spouseResidency || []);
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchbookKeepingForm();
    }, [ticketData.tax_organizer_id]);

    useEffect(() => {
        const fetchbookKeepingForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'income_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.income_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setIncomesForm(parsedDetails);
                   // setIncomeValues(parsedDetails.incomeValues || []);
                   // if (Array.isArray(parsedDetails.foreignIncome) && parsedDetails.foreignIncome.length > 0) {
                      //  setForeignIncome(parsedDetails.foreignIncome);}

                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchbookKeepingForm();
    }, [ticketData.tax_organizer_id]);

    useEffect(() => {
        const fetchbookKeepingForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'rental_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.rental_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setRentalForm(parsedDetails);
                    //setRentalInfo(parsedDetails.rentalInfo || []);
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchbookKeepingForm();
    }, [ticketData.tax_organizer_id]);

    useEffect(() => {
        const fetchbookKeepingForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'expenses_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.expenses_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setExpensesForm(parsedDetails);
                    //setDayCareValues(parsedDetails.dayCareValues || []);
                   // setVehicleValues(parsedDetails.vehiclaValues || []);
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchbookKeepingForm();
    }, [ticketData.tax_organizer_id]);

    const handleSubmit = async () => {
        try {
            setSuccessMessage('');

            const token = await getValidAccessToken();
            const response = await axios.post(
                `${apiUrl}/update-taxorganizer-field/`,
                {
                    tax_organizer_id: ticketData.tax_organizer_id,
                    column_name: 'basic_details_form',
                    column_value: JSON.stringify({ ...basicDetailsForm, personalDetailsValues, spouseDetailsValues, bankDetailsValues, mailingAddressDetailsValues }),
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
           // console.log('Form saved successfully:', response.data);
            setSuccessMessage('Updated successfully!');
        } catch (error) {
            console.error('Error saving form:', error);
            setSuccessMessage('Error updating form. Please try again.');
        }
    };
        // Define your initial static fields
        const initialPersonalDetailsFields = [
            { name: "first_name", type: "textbox" },
            { name: "middle_name", type: "textbox" },
            { name: "last_name", type: "textbox" },
            { name: "phone_no", type: "textbox" },
            { name: "email", type: "email" },
            { name: "ssn", type: "ssn" },
            { name: "date_of_birth", type: "date" },
            { name: "occupation", type: "textbox" },
            { name: "employer", type: "textbox" },
            { name: "designation", type: "textbox" },
            { name: "first_port_of_entry_into_united_states", type: "date" },
            { name: "current_visa_type", type: "radio", options: ["CITIZEN", "GREEN_CARD", "H1B", "H4", "L1", "L2", "F1 CPT", "F1 OPT", "F2", "J1", "J2", "B1", "B2", "OTHERS"] },
            { name: `was_there_any_change_in_your_visa_during_${ticketData.service_year_value}`, type: "radio", options: ["Yes", "No"] },
            { name: `did_you_reside_at_least_6_months_in_US_during_${ticketData.service_year_value}`, type: "radio", options: ["Yes", "No"] },
            { name: `will_you_reside_at_least_6_months_in_US_in_${adjustYear(ticketData.service_year_value, 1)}`, type: "radio", options: ["Yes", "No"] },
            { name: "yearly_household_income", type: "radio", options: ["UPTO $130K", "BETWEEN $130k - $250K", "ABOVE $250K"] },
            { name: "marital_status", type: "radio", options: ["Married", "Single", "Legally Separated", "Widow/Widower"] },
        ];
    
        // Define your dynamic field conditions
        const dynamicFieldConditions = {
            [`current_visa_type`]: {
                dynamicFields: [
                    { name: `can_you_specify_the_visa_type`, type: "typography-textfield" },
                ],
                conditionChecker: (value) => value === "OTHERS",
            },
            [`was_there_any_change_in_your_visa_during_${ticketData.service_year_value}`]: {
                dynamicFields: [
                    { name: `can_you_specify_the_visa_type_during_${ticketData.service_year_value}`, type: "typography-textfield" },
                ],
                conditionChecker: (value) => value === "Yes",
            },
            [`did_you_reside_at_least_6_months_in_US_during_${ticketData.service_year_value}`]: {
                dynamicFields: [
                    { name: `number_of_days_resided_in_US_during_${ticketData.service_year_value}?`, type: "typography-textfield" },
                    { name: `number_of_days_resided_in_US_during_${adjustYear(ticketData.service_year_value, -1)}?`, type: "typography-textfield" },
                    { name: `number_of_days_resided_in_US_during_${adjustYear(ticketData.service_year_value, -2)}?`, type: "typography-textfield" },
                ],
                conditionChecker: (value) => value === "No",
            },
            ["marital_status"]:{
                dynamicFields: [
                    { name: `date_of_mariage`, type: "date" },
                ],
                conditionChecker: (value) => value === "Married",
            }
        };
    
        // Use the custom hook
        const [personalDetailsFields, updateFields, setPersonalDetailsFields] = useDynamicFields(
            initialPersonalDetailsFields,
            dynamicFieldConditions
        );
    
        // Form values state
        const [personalDetailsValues, setPersonalDetailsValues] = useState(() => {
            const initialValues = {};
            initialPersonalDetailsFields.forEach((field) => {
                initialValues[field.name] = ""; // Default value
            });
            return initialValues;
        });
    
        // Handle field value changes
        const handlePDInputChange = (fieldName, value) => {
            updateFields(fieldName, value, personalDetailsValues);
        
            setPersonalDetailsValues((prevValues) => ({
                ...prevValues,
                [fieldName]: value,
            }));
        };
    
    const initialspouseDetailsFields = [
        { name: 'first_name', type: 'textbox' },
        { name: 'middle_name', type: 'textbox' },
        { name: 'last_name', type: 'textbox' },
        { name: 'phone_no', type: 'textbox' },
        { name: 'email', type: 'email' },
        { name: 'ssn', type: 'ssn' },
        { name: 'date_of_birth', type: 'date' },
        { name: 'occupation', type: 'textbox' },
        { name: 'employer', type: 'textbox' },
        { name: 'designation', type: 'textbox' },
        { name: 'first_port_of_entry_into_united_states', type: 'date' },
        { name: 'current_visa_type', type: 'radio', options: ['CITIZEN', 'GREEN_CARD', 'H1B', 'H4', 'L1', 'L2', 'F1 CPT', 'F1 OPT', 'F2', 'J1', 'J2', 'B1', 'B2', 'OTHERS'] },
        { name: `was_there_any_change_in_your_visa_during_${ticketData?.service_year_value}`, type: 'radio', options: ['Yes', 'No'] },
        { name: `did_you_reside_at_least_6_months_in_US_during_${ticketData.service_year_value}`, type: 'radio', options: ['Yes', 'No'] },
        { name: `will_you_reside_at_least_6_months_in_US_in_${adjustYear(ticketData.service_year_value,1)}`, type: 'radio', options: ['Yes', 'No'] },
    ];
    const dynamicFieldConditionsSpouse = {
        [`current_visa_type`]: {
            dynamicFields: [
                { name: `can_you_specify_the_visa_type`, type: "typography-textfield" },
            ],
            conditionChecker: (value) => value === "OTHERS",
        },
        [`was_there_any_change_in_your_visa_during_${ticketData.service_year_value}`]: {
            dynamicFields: [
                { name: `can_you_specify_the_visa_type_during_${ticketData.service_year_value}`, type: "typography-textfield" },
            ],
            conditionChecker: (value) => value === "Yes",
        },
        [`did_you_reside_at_least_6_months_in_US_during_${ticketData.service_year_value}`]: {
            dynamicFields: [
                { name: `number_of_days_resided_in_US_during_${ticketData.service_year_value}?`, type: "typography-textfield" },
                { name: `number_of_days_resided_in_US_during_${adjustYear(ticketData.service_year_value, -1)}?`, type: "typography-textfield" },
                { name: `number_of_days_resided_in_US_during_${adjustYear(ticketData.service_year_value, -2)}?`, type: "typography-textfield" },
            ],
            conditionChecker: (value) => value === "No",
        },
    };
     // Use the custom hook
     const [spouseDetailsFields, updateFieldsSpouse, setSpouseDetailsFields] = useDynamicFields(
        initialspouseDetailsFields,
        dynamicFieldConditionsSpouse
    );
    const [spouseDetailsValues, setSpouseDetailsValues] = useState(() => {
        // Initialize all form fields with a default value of 0
        const initialValues = {};
        initialspouseDetailsFields.forEach(field => {
            initialValues[field.name] = ''; // Initialize as 0
        });
        return initialValues;
    });



    const handleSDInputChange = (fieldName, value) => {
    
        // Update the form values
        updateFieldsSpouse(fieldName, value, spouseDetailsFields);
        // Set the updated form values
        setSpouseDetailsValues((prevValues) => ({
            ...prevValues,
            [fieldName]: value,
        }));
    };


    const BankDetailsFields = [
        { name: 'bank_name', type: 'textbox' },
        { name: 'rounting_number_electronic_only', type: 'number' },
        { name: 'account_number', type: 'number' },
        { name: 'account_holder_name', type: 'textbox' },
        { name: 'account_type', type: 'radio', options: ['Checking', 'Savings'] },
    ];


    const [bankDetailsValues, setBankDetailsValues] = useState(() => {
        // Initialize all form fields with a default value of 0
        const initialValues = {};
        BankDetailsFields.forEach(field => {
            initialValues[field.name] = ''; // Initialize as 0
        });
        return initialValues;
    });



    const handleBDInputChange = (fieldName, value) => {
        // Update the form values
        const updatedFormValues = { ...bankDetailsValues, [fieldName]: value };
        // Set the updated form values
        setBankDetailsValues(updatedFormValues);
    };



    const mailingAddressFields = [
        { name: 'apartment_no', type: 'textbox' },
        { name: 'address_line1', type: 'textarea' },
        { name: 'address_line2', type: 'textarea' },
        { name: 'city', type: 'textbox' },
        { name: 'state', type: 'textbox' },
        { name: 'zip_code', type: 'textbox' },
        { name: 'country', type: 'textbox' },
        { name: 'comments', type: 'textarea',helpertext:"hello please fill this return as 1040" },
    ];


    const [mailingAddressDetailsValues, setMmailingAddressDetailsValues] = useState(() => {
        // Initialize all form fields with a default value of 0
        const initialValues = {};
        mailingAddressFields.forEach(field => {
            initialValues[field.name] = ''; // Initialize as 0
        });
        return initialValues;
    });



    const handleMDInputChange = (fieldName, value) => {
        // Update the form values
        const updatedFormValues = { ...mailingAddressDetailsValues, [fieldName]: value };
        // Set the updated form values
        setMmailingAddressDetailsValues(updatedFormValues);
    };

  /*  const handleDownloadPdf = () => {
        new PdfView();
    }*/
        let formatedDOBPerson = useAdjustDateFormat(JSON.stringify(personalDetailsValues.date_of_birth));
        let formatedDOBSpouse = useAdjustDateFormat(JSON.stringify(spouseDetailsValues.date_of_birth));

    const handleDownloadPdf = () => {
        const maxHeight = 280;
        const doc = new jsPDF();
        let y = 10;
        let headValue = 'Save Tax LLC';
        let xOffset = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(headValue) / 2);
        doc.text(headValue, xOffset, y);

        var img = new Image();
        img.src = "favicon.png";

        doc.addImage(img, 10, 2, 25, 25);

        const pageWidth = doc.internal.pageSize.width - 30;
        const halfWidth = pageWidth * 0.5;

        let rowdatabase = [];
       

        let personals = JSON.parse(JSON.stringify(personalDetailsValues))
        let spouse = JSON.parse(JSON.stringify(spouseDetailsValues))
        if (personals.marital_status === 'Married') {
            // Add taxpayer and spouse details
            rowdatabase.push(['First Name', personals.first_name, spouse.first_name]);
            rowdatabase.push(['Middle Name', personals.middle_name, spouse.middle_name]);
            rowdatabase.push(['Last Name', personals.last_name, spouse.last_name]);
            rowdatabase.push(['Phone No', personals.phone_no, spouse.phone_no]);
            rowdatabase.push(['Email', personals.email, spouse.email]);
            rowdatabase.push(['SSN', personals.ssn, spouse.ssn]);
            rowdatabase.push(['Date Of Birth', formatedDOBPerson,formatedDOBSpouse]);
            rowdatabase.push(['Occupation', personals.occupation, spouse.occupation]);
            rowdatabase.push(['Employer', personals.employer, spouse.employer]);
            rowdatabase.push(['Designation', personals.designation, spouse.designation]);
            rowdatabase.push(['First Port of Entry into United States', personals.first_port_of_entry_into_united_states, spouse.first_port_of_entry_into_united_states]);
            rowdatabase.push(['Visa Type', personals.current_visa_type, spouse.current_visa_type]);
            if (personals.current_visa_type === 'OTHERS') {
                rowdatabase.push(['Can you specify the visa type', personals.can_you_specify_the_visa_type, spouse.can_you_specify_the_visa_type]);
            }
            rowdatabase.push(['Was there a change in your Visa', personals[`was_there_any_change_in_your_visa_during_${ticketData.service_year_value}`], spouse[`was_there_any_change_in_your_visa_during_${ticketData.service_year_value}`]]);
            if (personals[`was_there_any_change_in_your_visa_during_${ticketData.service_year_value}`] === 'Yes') {
                rowdatabase.push(['Can you specify the visa type', personals[`can_you_specify_the_visa_type_during_${ticketData.service_year_value}`], spouse[`can_you_specify_the_visa_type_during_${ticketData.service_year_value}`]]);
            }
            rowdatabase.push([`Have you resided at least 6 months in US during ${ticketData.service_year_value}`, personals[`did_you_reside_at_least_6_months_in_US_during_${ticketData.service_year_value}`], spouse[`did_you_reside_at_least_6_months_in_US_during_${ticketData.service_year_value}`]]);
            if (personals[`did_you_reside_at_least_6_months_in_US_during_${ticketData.service_year_value}`] === 'No') {
                rowdatabase.push([`Number of days resided in US during ${ticketData.service_year_value}`, personals[`number_of_days_resided_in_US_during_${ticketData.service_year_value}?`], spouse[`number_of_days_resided_in_US_during_${ticketData.service_year_value}?`]]);
                rowdatabase.push([`Number of days resided in US during ${adjustYear(ticketData.service_year_value, -1)}`, personals[`number_of_days_resided_in_US_during_${adjustYear(ticketData.service_year_value, -1)}?`], spouse[`number_of_days_resided_in_US_during_${adjustYear(ticketData.service_year_value, -1)}?`]]);
                rowdatabase.push([`Number of days resided in US during ${adjustYear(ticketData.service_year_value, -2)}`, personals[`number_of_days_resided_in_US_during_${adjustYear(ticketData.service_year_value, -2)}?`], spouse[`number_of_days_resided_in_US_during_${adjustYear(ticketData.service_year_value, -2)}?`]]);
            }
            rowdatabase.push([`Will you reside at least 6 months in US in ${adjustYear(ticketData.service_year_value, 1)}`, personals[`will_you_reside_at_least_6_months_in_US_in_${adjustYear(ticketData.service_year_value, 1)}`], spouse[`will_you_reside_at_least_6_months_in_US_in_${adjustYear(ticketData.service_year_value, 1)}`]]);
            rowdatabase.push(['Yearly Household Income', personals.yearly_household_income, spouse.yearly_household_income]);
            rowdatabase.push(['Marital Status', personals.marital_status, spouse.marital_status]);
            rowdatabase.push(['Date of Marriage', personals.date_of_mariage, spouse.date_of_mariage]);
        } else {
            // Add only taxpayer details
            rowdatabase.push(['First Name', personals.first_name]);
            rowdatabase.push(['Middle Name', personals.middle_name]);
            rowdatabase.push(['Last Name', personals.last_name]);
            rowdatabase.push(['Phone No', personals.phone_no]);
            rowdatabase.push(['Email', personals.email]);
            rowdatabase.push(['SSN', personals.ssn]);
            rowdatabase.push(['Date Of Birth', formatedDOBPerson]);
            rowdatabase.push(['Occupation', personals.occupation]);
            rowdatabase.push(['Employer', personals.employer]);
            rowdatabase.push(['Designation', personals.designation]);
            rowdatabase.push(['First Port of Entry into United States', personals.first_port_of_entry_into_united_states]);
            rowdatabase.push(['Visa Type', personals.current_visa_type]);
            if (personals.current_visa_type === 'OTHERS') {
                rowdatabase.push(['Can you specify the visa type', personals.can_you_specify_the_visa_type]);
            }
            rowdatabase.push(['Was there a change in your Visa', personals[`was_there_any_change_in_your_visa_during_${ticketData.service_year_value}`]]);
            if (personals[`was_there_any_change_in_your_visa_during_${ticketData.service_year_value}`] === 'Yes') {
                rowdatabase.push(['Can you specify the visa type', personals[`can_you_specify_the_visa_type_during_${ticketData.service_year_value}`]]);
            }
            rowdatabase.push([`Have you resided at least 6 months in USA during ${ticketData.service_year_value}`, personals[`did_you_reside_at_least_6_months_in_US_during_${ticketData.service_year_value}`]]);
            if (personals[`did_you_reside_at_least_6_months_in_USA_during_${ticketData.service_year_value}`] === 'No') {
                rowdatabase.push([`Number of days resided in USA during ${ticketData.service_year_value}`, personals[`number_of_days_resided_in_US_during_${ticketData.service_year_value}?`]]);
                rowdatabase.push([`Number of days resided in USA during ${adjustYear(ticketData.service_year_value, -1)}`, personals[`number_of_days_resided_in_US_during_${adjustYear(ticketData.service_year_value, -1)}?`]]);
                rowdatabase.push([`Number of days resided in USA during ${adjustYear(ticketData.service_year_value, -2)}`, personals[`number_of_days_resided_in_US_during_${adjustYear(ticketData.service_year_value, -2)}?`]]);
            }
            rowdatabase.push([`Will you reside at least 6 months in US in ${adjustYear(ticketData.service_year_value, 1)}`, personals[`will_you_reside_at_least_6_months_in_US_in_${adjustYear(ticketData.service_year_value, 1)}`]]);
            rowdatabase.push(['Yearly Household Income', personals.yearly_household_income]);
            rowdatabase.push(['Marital Status', personals.marital_status]);
        }
        // rowdatabase.push(['comments',personals.comments]);
        autoTable(doc, {
            startY: y + 20, // Starting position for the first table
            head: [
                personals.marital_status === 'Married'?[{content: 'Basic Details', colSpan: 3}]:[{content: 'Basic Details', colSpan: 2}],
                personals.marital_status === 'Married' ? ['', 'Tax Payer', 'Spouse'] : ['', 'Tax Payer']
            ],
            body: rowdatabase,
            headStyles: {
                halign: "center",
                fontStyle: "bold",
                textColor: 0,
                fillColor: [255, 255, 255],
                lineWidth: 0.2,
                lineColor: [0, 0, 0]
            },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 11,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth: "50%"
                    },
                1: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth: "25%"
                },
                2: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth: "25%"
                },
            }
        });
        y = doc.lastAutoTable.finalY + 10;
        let addressdatabase = [];
      //  console.log("Mailing Address " + JSON.stringify(mailingAddressDetailsValues));
        addressdatabase.push(['Apartment No', mailingAddressDetailsValues.apartment_no]);
        addressdatabase.push(['Address', mailingAddressDetailsValues.address_line1]);
        addressdatabase.push(['Address', mailingAddressDetailsValues.address_line2]);
        addressdatabase.push(['City', mailingAddressDetailsValues.city]);
        addressdatabase.push(['State', mailingAddressDetailsValues.state]);
        addressdatabase.push(['Zipcode', mailingAddressDetailsValues.zip_code]);
        addressdatabase.push(['Country', mailingAddressDetailsValues.country]);
        addressdatabase.push(['comments',mailingAddressDetailsValues.comments]);

        autoTable(doc, {
            startY: y + 10, // Starting position for the first table
            head: [
                [{content: 'Address', colSpan: 2}],

            ],
            body: addressdatabase,
            headStyles: {
                halign: "center",
                fontStyle: "bold",
                textColor: 0,
                fillColor: [255, 255, 255],
                lineWidth: 0.2,
                lineColor: [0, 0, 0]
            },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 11,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth: "50%"
                    },
                1: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth: "25%"
                },
            }
        });
        //doc.addPage();
        //y = 0;
        y = doc.lastAutoTable.finalY + 5;
        let i = 0;
        let depentantValues = JSON.parse(JSON.stringify(dependentsForm));
        
        if (depentantValues.vehicleInfo != null){
            depentantValues.vehicleInfo.forEach(depentant => {
                const date = new Date(depentant.date_of_birth);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                const year = date.getFullYear();
                const formatedDOB = `${day}-${month}-${year}`;

                const portDate = new Date(depentant.first_port_of_entry_into_united_states);
                const portDay = String(portDate.getDate()).padStart(2, '0');
                const portMonth = String(portDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                const portYear = portDate.getFullYear();
                const formatedPortDate = `${portDay}-${portMonth}-${portYear}`;
                let dependentdatabase = [];

                dependentdatabase.push(['First Name', depentant.first_name]);
                dependentdatabase.push(['Middle Name', depentant.middle_name]);
                dependentdatabase.push(['Last Name', depentant.last_name]);

                dependentdatabase.push(['Date Of Birth',formatedDOB]);
                dependentdatabase.push(['Phone No', depentant.phone_no]);

                dependentdatabase.push(['Email', depentant.email]);
                dependentdatabase.push(['SSN', depentant.ssn]);
                dependentdatabase.push(['Occupation', depentant.occupation]);
                dependentdatabase.push(['First Port of entry into UnitedStates', formatedPortDate]);
                dependentdatabase.push(['Relationship', depentant.she_or_he_is_my]);
                dependentdatabase.push(['Visa Type', depentant.current_visa_type]);

                dependentdatabase.push(['Was there a change in your Visa', depentant[`was_there_any_change_in_dependent_visa_during_${ticketData.service_year_value}`]]);
                dependentdatabase.push([`Have you resided atleast 6 months in USA during ${ticketData.service_year_value}`, depentant[`did_you_reside_at_least_6_months_in_US_during_${ticketData.service_year_value}`]]);
                dependentdatabase.push([`Will you reside atleast 6 months in USA in ${adjustYear(ticketData.service_year_value,1)}`, depentant[`will_you_reside_at_least_6_months_in_US_in_${adjustYear(ticketData.service_year_value,1)}`]]);
                dependentdatabase.push(['comments', depentant.comments]);


                autoTable(doc, {
                    startY: y + 5, // Starting position for the first table
                    head: [
                        [{content: 'Dependents', colSpan: 2}],
                    ],
                    body: dependentdatabase,
                    headStyles: {
                        halign: "center",
                        fontStyle: "bold",
                        textColor: 0,
                        fillColor: [255, 255, 255],
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0]
                    },
                    columnStyles: {
                        0:
                            {
                                halign: "left",
                                valign: 'middle',
                                fontStyle: "bold",
                                fontSize: 11,
                                textColor: "#000",
                                cellPadding: 1,
                                lineWidth: 0.2,
                                lineColor: [0, 0, 0],
                                cellWidth: "50%"
                            },
                        1: {
                            halign: "center",
                            valign: 'middle',
                            fontStyle: "bold",
                            fontSize: 9,
                            textColor: "#000",
                            cellPadding: 1,
                            lineWidth: 0.2,
                            lineColor: [0, 0, 0],
                            cellWidth: "25%"
                        },

                    }
                });
                y = doc.lastAutoTable.finalY + 10;


                if (i >= 2) {
                    doc.addPage();
                    i = 0;
                    y = 0;
                }
                i++;
            });
        }
        //doc.addPage();
        y = doc.lastAutoTable.finalY + 5;
        let residencyDatabase = [];
        let residencyLines  = [];
        if(stateDetailsForm.taxPayerResidency != null) {
            stateDetailsForm.taxPayerResidency.forEach(database => {
                residencyDatabase = [database.state_resided, database.zip_code, database.resided_from, database.resided_till];
                residencyLines.push(residencyDatabase);
                residencyDatabase = [];

            });
        }
        //y = 20;
        autoTable(doc, {
            startY: y + 5, // Starting position for the first table
            head: [
                [{content:'Taxpayer Residency',colSpan:4}],
                ['State', 'Zipcode','From','Till']
            ],
            body: residencyLines,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 11,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:"25%"
                    },
                1: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
                2: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
                3: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
            }
        });
        y = doc.lastAutoTable.finalY + 5;



        let spouseDatabase = [];
        let spouseLines  = [];
if(stateDetailsForm.spouseResidency != null) {
    stateDetailsForm.spouseResidency.forEach(database => {
        spouseDatabase = [database.state_resided, database.zip_code, database.resided_from, database.resided_till];
        spouseLines.push(spouseDatabase);
        spouseDatabase = [];

    });
}

        autoTable(doc, {
            startY: y + 5, // Starting position for the first table
            head: [
                [{content:'Spouse Residency',colSpan:4}],
                ['State', 'Zipcode','From','Till']
            ],
            body: spouseLines,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 11,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:"25%"
                    },
                1: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
                2: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
                3: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
            }
        });

        y = doc.lastAutoTable.finalY + 5;

        let additionalDatabase = [];
        if(personals.marital_status === 'Married'){
            additionalDatabase.push(['Do You or your Spouse reside in Massachusetts during 2024',stateDetailsForm.do_you_or_your_spouse_reside_in_massachusetts_during_2024]);
        }
        else{
        additionalDatabase.push(['Do You reside in Massachusetts during 2024',stateDetailsForm.do_you_reside_in_massachusetts_during_2024]);
        }
        additionalDatabase.push(['Are You covered by Massachusetts Health Insurance during 2024', stateDetailsForm.are_you_covered_by_massachusetts_health_insurance_during_2024]);
        if(personals.marital_status === 'Married'){
        additionalDatabase.push(['Did You or your Spouse reside in New Jersey or California during 2024', stateDetailsForm.did_you_or_your_spouse_reside_in_new_jersey_or_california_during_2024]);
        }
        else{
        additionalDatabase.push(['Did You reside in New Jersey or California during 2024', stateDetailsForm.did_you_reside_in_new_jersey_or_california_during_2024]);
        }
        additionalDatabase.push(['Did You reside in California or Massachusetts or New Jersey or Minnesota or Wisconsin or Indiana during 2024', stateDetailsForm.did_you_reside_in_california_or_massachusetts_or_new_jersey_or_minnesota_or_wisconsin_or_indiana_during_2024]);
        additionalDatabase.push(['Enter The rent paid in that state during 2024', stateDetailsForm.enter_the_rent_paid_in_that_state_during_2024]);
        additionalDatabase.push(['Did You Reside in Albama during 2024', stateDetailsForm.did_you_reside_in_albama_during_2024]);
        additionalDatabase.push(['comments',stateDetailsForm.comments]);

/*

        console.log("State Details : "+JSON.stringify(stateDetailsForm))
        console.log("incomesForm : "+JSON.stringify(incomesForm))
        console.log("rentalForm : "+JSON.stringify(rentalForm ))
        console.log("[expensesForm : "+JSON.stringify(expensesForm))
*/



        autoTable(doc, {
            startY: y + 5, // Starting position for the first table
            head: [
                [{content:'Additional State Info',colSpan:2}],
            ],
            body:additionalDatabase,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 11,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:"50%"
                    },
                1: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"50%"
                },

            }
        });
        y = doc.lastAutoTable.finalY + 5;
        let incomeDatabase = [];

        incomeDatabase.push([`I am an employee during_${ticketData.service_year_value}`,incomesForm.i_am_an_employee_during_2023 === 'Yes' ? 'Yes' : 'No']);
        incomeDatabase.push([`I am an Independent Contractor during ${ticketData.service_year_value}`, incomesForm.i_am_an_independent_contractor_during_2023 === 'Yes' ? 'Yes' : 'No']);
        incomeDatabase.push([`I have done Stock Transactions during ${ticketData.service_year_value}`, incomesForm.i_have_done_stock_transactions_during_2023 === 'Yes' ? 'Yes' : 'No']);
        incomeDatabase.push([`I have done Crypto Currency Transactions during ${ticketData.service_year_value}`, incomesForm.i_have_done_crypto_currency_transactions_during_2023 === 'Yes' ? 'Yes' : 'No']);
        incomeDatabase.push([`I have received Social Security or Retirement Benefits during ${ticketData.service_year_value}`, incomesForm.i_have_received_social_security_or_retirement_benefits_during_2023 === 'Yes' ? 'Yes' : 'No']);
        incomeDatabase.push([`I have received hsa Distribution during ${ticketData.service_year_value}`, incomesForm.i_have_received_hsa_distribution_during_2023  === 'Yes' ? 'Yes' : 'No']);
        incomeDatabase.push([`I have received interest income during ${ticketData.service_year_value}`, incomesForm.i_have_received_interest_income_during_2023  === 'Yes' ? 'Yes' : 'No']);
        incomeDatabase.push([`I have received non employee compensation during ${ticketData.service_year_value}`, incomesForm.i_have_received_non_employee_compensation_during_2023  === 'Yes' ? 'Yes' : 'No']);
        incomeDatabase.push([`Did you receive advance child tax credit during ${ticketData.service_year_value}`, incomesForm.did_you_receive_advance_child_tax_credit_during_2023  === 'Yes' ? 'Yes' : 'No']);
        incomeDatabase.push(['Did you Made Any Changes in Ownership of the Business?', incomesForm.did_you_made_any_changes_in_ownership_of_business === 'Yes' ? 'Yes' : 'No']);
        incomeDatabase.push([`Did you open_any foreign mutual fund account during ${ticketData.service_year_value}`, incomesForm.did_you_open_any_foreign_mutual_fund_account_during_2023 === 'Yes' ? 'Yes' : 'No']);
        incomeDatabase.push([`Have 10k in all financial accounts outside United States during ${ticketData.service_year_value} tax payer`, incomesForm.have_10k_in_all_financial_accounts_outside_us_during_2023_tax_year_tax_payer === 'Yes' ? 'Yes' : 'No']);
        if(personalDetailsValues.marital_status === 'Married') {
        incomeDatabase.push([`have at-least $100,000/- collectively in all financial accounts outside the United States during ${ticketData.service_year_value} tax payer`, incomesForm.have_10k_in_all_financial_accounts_outside_us_during_2023_tax_payer === 'Yes' ? 'Yes' : 'No']);
        incomeDatabase.push([`have you and your spouse at-least $100,000/- collectively in all financial accounts outside the United States during ${ticketData.service_year_value} `, incomesForm.have_10k_in_all_financial_accounts_outside_us_during_2023_spouse === 'Yes' ? 'Yes' : 'No']);
        }
        else{
            incomeDatabase.push([`have at-least $50,000/- collectively in all financial accounts outside the United States during ${ticketData.service_year_value} `, incomesForm.have_50k_in_all_financial_accounts_outside_us_during_2023_tax_payer === 'Yes' ? 'Yes' : 'No']);
        }
        incomeDatabase.push(['comments',incomesForm.comments]);


        autoTable(doc, {
            startY: y + 5, // Starting position for the first table
            head: [
                [{content:'Incomes',colSpan:2}],
            ],
            body:incomeDatabase,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 11,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:"50%"
                    },
                1: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"50%"
                },

            }
        });



       // doc.addPage();

        y = doc.lastAutoTable.finalY + 5;



        let otherIncomeDatabase = [];
        let otherIncomeLines  = [];
        let pos = 1;
        if(incomesForm.incomeValues != null) {
            incomesForm.incomeValues.forEach(database => {
                otherIncomeDatabase = [pos.toString(), database.income_type, database.amount, database.tax_paid_or_withheld];
                otherIncomeLines.push(otherIncomeDatabase);
                otherIncomeDatabase = [];
                pos++;

            });
        }


        autoTable(doc, {
            startY: y + 5, // Starting position for the first table
            head: [
                [{content:'Other Incomes',colSpan:4}],
                ['S.No', 'Income Type','Amount','Tax Paid / WithHeld']
            ],
            body: otherIncomeLines,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 11,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:"25%"
                    },
                1: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
                2: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
                3: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
            }
        });

        y = doc.lastAutoTable.finalY + 5;



        let foreignIncomeDatabase = [];
        let foreignIncomeLines  = [];
        pos = 1;
        if( incomesForm.foreignIncome != null) {
            incomesForm.foreignIncome.forEach(database => {
                foreignIncomeDatabase = [toPascalCase(database.name), database.value2, database.value3];
                foreignIncomeLines.push(foreignIncomeDatabase);
                foreignIncomeDatabase = [];
                pos++;

            });
        }


        autoTable(doc, {
            startY: y + 5, // Starting position for the first table
            head: [
                [{content:'Foreign Incomes',colSpan:3}],
                ['Foreign Income Type','Income Earned','Tax Paid / WithHeld']
            ],
            body: foreignIncomeLines,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 11,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:"25%"
                    },
                1: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
                2: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },

            }
        });

       // doc.addPage();
        y = doc.lastAutoTable.finalY + 5;




        let expencesIncomeLines  = [];


        expencesIncomeLines.push([toPascalCase(`charitable_contributions_during_${ticketData.service_year_value}`),expensesForm.charitable_contributions_during_2023]);
        expencesIncomeLines.push([toPascalCase(`medical_expenses_during_${ticketData.service_year_value}`), expensesForm.medical_expenses_during_2023]);
        expencesIncomeLines.push([`Property tax paid in USA during ${ticketData.service_year_value}`,expensesForm.property_tax_paid_in_us_during_2023]);
        expencesIncomeLines.push([`Property tax paid outside USA during ${ticketData.service_year_value}`, expensesForm.property_tax_paid_outside_us_during_2023]);

        autoTable(doc, {
            startY: y + 5, // Starting position for the first table
            head: [
                [{content:'Expenses',colSpan:2}],
                ['Expenses Type','Amount']
            ],
            body: expencesIncomeLines,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 11,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:"25%"
                    },
                1: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
                2: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },

            }
        });

        let detectionLines  = [];

        detectionLines.push([`I have paid home mortgage in US during ${ticketData.service_year_value}`,expensesForm.i_have_paid_home_mortgage_in_us_during_2023 === true ? 'Yes' : 'No']);
        detectionLines.push([toPascalCase(`i_have_paid_home_mortgage_outside_us_during_${ticketData.service_year_value}`), expensesForm.i_have_paid_home_mortgage_outside_us_during_2023=== true ? 'Yes' : 'No']);
        detectionLines.push([`I have paid interest on student loan in US during ${ticketData.service_year_value}`,expensesForm.i_have_paid_interest_on_student_loan_in_us_during_2023=== true ? 'Yes' : 'No']);
        detectionLines.push([`I have invested into HSA FSA During ${ticketData.service_year_value}`, expensesForm.i_have_invested_into_hsa_fsa_during_2023=== true ? 'Yes' : 'No']);
        detectionLines.push([`I have invested into Roth IRA During ${ticketData.service_year_value}`, expensesForm.i_have_invested_into_roth_ira_during_2023=== true ? 'Yes' : 'No']);
        detectionLines.push([`I have invested into traditional IRA during ${ticketData.service_year_value}`, expensesForm.i_have_invested_into_traditional_ira_during_2023=== true ? 'Yes' : 'No']);
        detectionLines.push([`I have purchased energy saving equipment in USA during ${ticketData.service_year_value}`, expensesForm.i_have_purchased_energy_saving_equipment_in_us_during_2023=== true ? 'Yes' : 'No']);
        detectionLines.push([`I have incurred education expenses in USA during ${ticketData.service_year_value}`, expensesForm.i_have_incurred_education_expenses_in_us_during_2023=== true ? 'Yes' : 'No']);
        detectionLines.push([`I have invested into ESA during ${ticketData.service_year_value}`, expensesForm.i_have_invested_into_esa_during_2023=== true ? 'Yes' : 'No']);



        y = doc.lastAutoTable.finalY + 5;
        autoTable(doc, {
            startY: y + 5, // Starting position for the first table
            head: [

                ['Deduction Type','']
            ],
            body:  detectionLines,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 11,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:"25%"
                    },
                1: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },


            }
        });


        y = doc.lastAutoTable.finalY + 5;

        let daycareDatabase = [];
        let daycareIncomeLines  = [];
        pos = 1;
        if(expensesForm.dayCareValues != null) {
            expensesForm.dayCareValues.forEach(database => {
                daycareDatabase = [database.child_name, database.name_of_day_care, database.ein_or_ssn, database.address, database.amount];
                daycareIncomeLines.push(daycareDatabase);
                daycareDatabase = [];
                pos++;

            });
        }

        autoTable(doc, {
            startY: y + 5, // Starting position for the first table
            head: [
                [{content:`Incurred Day Care Expenses During ${ticketData.service_year_value}`,colSpan:5}],
                ['Child Name','Name of Day Care','SSN / EIN','Address','Amount']
            ],
            body: daycareIncomeLines,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 11,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:"25%"
                    },
                1: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
                2: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
                3: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
                4: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },

            }
        });

        y = doc.lastAutoTable.finalY + 5;

        let vehicleDatabase = [];
        let vehicleLines  = [];
        pos = 1;
        if(expensesForm.vehiclaValues != null) {
            expensesForm.vehiclaValues.forEach(database => {
                vehicleDatabase = [database.year, database.make,database.model, database.id_number, database.purchase_date, database.cost];
                vehicleLines.push(vehicleDatabase);
                vehicleDatabase = [];
                pos++;

            });
        }

        autoTable(doc, {
            startY: y + 5, // Starting position for the first table
            head: [
                [{content:`Purchased Electric / Hybrid Vehicle during ${ticketData.service_year_value}`,colSpan:6}],
                ['Year','Make','Model','ID Number','Purchase Date','Cost']
            ],
            body: vehicleLines,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 11,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],

                    },
                1: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],

                },
                2: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],

                },
                3: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],

                },
                4: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],

                },
                5: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],

                },

            }
        });

       detectionLines  = [];



        detectionLines.push(['Have you purchased (or) entered into a binding contract to buy a home in United States after April 8th, 2009 but before May 1st, 2010?', expensesForm.have_you_purchased_or_entered_into_a_binding_contract_to_buy_a_home_in_united_states_after_april_8th_2009_but_before_may_1st_2010]);
        if(expensesForm.have_you_purchased_or_entered_into_a_binding_contract_to_buy_a_home_in_united_states_after_april_8th_2009_but_before_may_1st_2010 === 'Yes') {
            detectionLines.push(['Is this the first home that you bought in US?', expensesForm.is_this_the_first_home_that_you_bought_in_us]);
            detectionLines.push(['Is this home used for your principal residence purposes?', expensesForm.is_this_home_used_for_your_principal_residence_purposes]);
            detectionLines.push(['Have you owned a main home at any time during the three years immediately preceding the date of purchase of current home?', expensesForm.have_you_owned_a_main_home_at_any_time_during_the_three_years_immediately_preceding_the_date_of_purchase_of_current_home]);
            detectionLines.push(['Enter the cost price of such home :', expensesForm.enter_the_cost_price_of_such_home]);
            detectionLines.push(['Have you Ever claimed any First Time Home Buyer Credit?', expensesForm.have_you_ever_claimed_any_first_time_home_buyer_credit]);
        }
        detectionLines.push(['comments',expensesForm.comments]);

        y = doc.lastAutoTable.finalY + 5;
        autoTable(doc, {
            startY: y + 5, // Starting position for the first table
            head: [


            ],
            body:  detectionLines,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 11,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:"25%"
                    },
                1: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },


            }
        });
        y = doc.lastAutoTable.finalY + 5;
      //  doc.addPage();
       // y = 0;
        i = 0;
        let rentalValues  = JSON.parse(JSON.stringify(rentalForm ));
        if(rentalValues.rentalInfo != null) {
            rentalValues.rentalInfo.forEach(rental => {
                let dependentdatabase = [];
                dependentdatabase.push(['Property Type', rental.property_type]);
                dependentdatabase.push(['Address', rental.address_of_the_property]);
                dependentdatabase.push(['No of Months Rented', rental.no_of_months_rented]);
                dependentdatabase.push(['No of Months used for personal user', rental.no_of_months_used_for_personal_user]);
                dependentdatabase.push(['Property Owned By', rental.property_owned_by]);
                dependentdatabase.push(['Cost of the Property', rental.cost_price_of_property_at_the_time_of_purchase]);
                dependentdatabase.push(['Date of Purchase', rental.date_of_purchase]);
                dependentdatabase.push(['Rental Income Received', rental.rental_income_received_during_2023]);
                dependentdatabase.push(['Expences Paid', rental.expenses_incurred_on_the_property_during_2023]);

                autoTable(doc, {
                    startY: y + 5, // Starting position for the first table
                    head: [
                        [{content: 'Rental Income', colSpan: 2}],
                    ],
                    body: dependentdatabase,
                    headStyles: {
                        halign: "center",
                        fontStyle: "bold",
                        textColor: 0,
                        fillColor: [255, 255, 255],
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0]
                    },
                    columnStyles: {
                        0:
                            {
                                halign: "left",
                                valign: 'middle',
                                fontStyle: "bold",
                                fontSize: 11,
                                textColor: "#000",
                                cellPadding: 1,
                                lineWidth: 0.2,
                                lineColor: [0, 0, 0],
                                cellWidth: "50%"
                            },
                        1: {
                            halign: "center",
                            valign: 'middle',
                            fontStyle: "bold",
                            fontSize: 9,
                            textColor: "#000",
                            cellPadding: 1,
                            lineWidth: 0.2,
                            lineColor: [0, 0, 0],
                            cellWidth: "25%"
                        },

                    }
                });
                y = doc.lastAutoTable.finalY + 10;


                if (i >= 2) {
                    doc.addPage();
                    i = 0;
                    y = 0;
                }
                i++;
            });
        }



        y = doc.lastAutoTable.finalY + 5;
        let bankdatabase = [];

        bankdatabase.push(['Bank Name', bankDetailsValues.bank_name]);
        bankdatabase.push(['Rounting Number', bankDetailsValues.rounting_number_electronic_only]);
        bankdatabase.push(['Account No', bankDetailsValues.account_number]);
        bankdatabase.push(['Account Holder Name', bankDetailsValues.account_holder_name]);
        bankdatabase.push(['Account Type',bankDetailsValues.account_type]);



        autoTable(doc, {
            startY: y + 5, // Starting position for the first table
            head: [
                [{content: 'Bank Details', colSpan: 2}],

            ],
            body: bankdatabase,
            headStyles: {
                halign: "center",
                fontStyle: "bold",
                textColor: 0,
                fillColor: [255, 255, 255],
                lineWidth: 0.2,
                lineColor: [0, 0, 0]
            },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 11,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth: "50%"
                    },
                1: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth: "25%"
                },
            }
        });
        doc.save('partner_information_details.pdf');
    };
    const populateDynamicForm = ({ data, fields, handleChange }) => (

            <Box mb={2}>
                <Grid container spacing={1} >
                    {fields.map((field) => {

                        const isFullWidthField = field.type === 'radio' || field.type === 'textarea'|| field.type === 'typography-textfield';
                        return (
                            <Grid item xs={12} sm={isFullWidthField ? 12 : 6} key={field.name}>
                                {field.type === 'ssn'? (
                                    <InputMask
                                        mask="999-99-9999"
                                        inputProps={{ style: { fontSize: '16px' } }}
                                        value={data[field.name]}
                                        onChange={(e) => handleChange( field.name, e.target.value)}
                                        disabled={userType !== 'user'}
                                    >
                                        {(inputProps) => <TextField {...inputProps} label={toPascalCase(field.name)} fullWidth size="small" variant="outlined" />}
                                    </InputMask>
                                ) : field.type === 'textbox' ? (
                                    <TextField
                                        label={toPascalCase(field.name)}
                                        inputProps={{ style: { fontSize: '16px' } }}
                                        value={data[field.name]}
                                        onChange={(e) => handleChange( field.name, e.target.value)}
                                        fullWidth
                                        size="small"
                                        disabled={userType !== 'user'

                                        }
                                    />
                                ) : field.type === 'textarea' ? (
                                    <>
                                    {field?.helpertext&&
                                    <Typography variant="caption" style={{ marginTop: "4px", color: "gray" }}>
                                    {field?.helpertext}
                                  </Typography>
                                  }
                                    <TextareaAutosize
                                        inputProps={{ style: { fontSize: '16px' } }}
                                        minRows={3}
                                        placeholder={toPascalCase(field.name)}
                                        value={data[field.name]}
                                        onChange={(e) => handleChange( field.name, e.target.value)}
                                        style={{ width: '100%', fontSize: '16px', padding: '8px' }}
                                        disabled={userType !== 'user'}
                                        helpertext={field?.helpertext}
                                    />
                                    </>
                                ) : field.type === 'number' ? (
                                    <TextField
                                        type="number"
                                        label={toPascalCase(field.name)}
                                        inputProps={{ style: { fontSize: '16px' } }}
                                        value={data[field.name]}
                                        onChange={(e) => handleChange( field.name, e.target.value)}
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        disabled={userType !== 'user'}
                                    />
                                ) : field.type === 'radio' ? (
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography variant="body1" sx={{ fontWeight:'bold', fontSize: '14px', display: 'inline' }}>
                                                {toPascalCase(field.name)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <RadioGroup
                                                row
                                                value={data[field.name]}
                                                onChange={(e) => handleChange(field.name, e.target.value)}
                                            >
                                                {field.options.map((option) => (
                                                    <FormControlLabel
                                                        key={option}
                                                        value={option}
                                                        control={<Radio sx={{ transform: 'scale(0.8)' }} />} // Scale down the radio button
                                                        label={option}
                                                        disabled={userType !== 'user'}
                                                        sx={{
                                                            '& .MuiFormControlLabel-label': {
                                                                fontSize: '0.8rem', // Adjust the label font size
                                                            },
                                                        }}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                ) 
                                : field.type === 'email' ? (
                                    <TextField
                                        label={toPascalCase(field.name)}
                                        inputProps={{ style: { fontSize: '16px' } }}
                                        value={data[field.name]}
                                        onChange={(e) => handleChange( field.name, e.target.value)}
                                        fullWidth
                                        type="email"
                                        size="small"
                                        disabled={userType !== 'user'}
                                    />
                                ) : field.type === 'date' ? (
                                    <TextField
                                        label={toPascalCase(field.name)}
                                        inputProps={{ style: { fontSize: '16px' } }}
                                        value={data[field.name] || ''}
                                        pattern="\d{2,}-\d{2}-\d{4}"
                                        onChange={(e) => handleChange( field.name, e.target.value)}
                                        fullWidth
                                        type="date"
                                        size="small"
                                        disabled={userType !== 'user'}
                                        InputLabelProps={{
                                            shrink: true, // Keep the label above the input
                                        }}
                                    />
                                ) : 
                                 field.type === 'typography-textfield' ? (
                                    <Box style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px',gap:'10px',width:'100%' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '14px',flexShrink: 0  }}>
                                            {toPascalCase(field.name)}
                                        </Typography>
                                        <TextField
                                            inputProps={{ style: { fontSize: '16px', padding:'0px',alignItems:'center',textAlign:'center' } }}
                                            value={data[field.name]}
                                            onChange={(e) => handleChange(field.name, e.target.value)}
                                            fullWidth
                                            size="small"
                                            variant="standard"
                                            disabled={userType !== 'user'}
                                            sx={{padding: '0px', width:'10rem'}}
                                        />
                                    </Box>
                                    
                                    ):
                                null}
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

    );

    const handleVerify = async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.get(`${apiUrl}/verify-document/?ticket_id=${ticketId}&column=organizer`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.success) {
                if (response.data.change_ticket_status) {
                setTicketData((prevData) => ({ ...prevData, ticket_status: 'Under Tax Preparation' }));}
                // console.log('Ticket organizer verified successfully');
                setIsDocumentVerified(true); // Update the organizer verification status
            } else {
                setErrorMessage('Unable to verify organizer.');
            }
        } catch (error) {
            setErrorMessage('Error verifying organizer.');
            console.error('Error verifying organizer:', error);
        }
    };
    const renderFormFields = () => (

        

        <Box mt={0} >
        
            <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontSize: '20px', fontWeight: 'bold', marginRight: '8px' }}>
                    Taxpayer Details:
                </Typography>
                <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}
                {populateDynamicForm({
        data: personalDetailsValues,           // form data
        fields: personalDetailsFields,       // array of field definitions
        handleChange: handlePDInputChange, // handle field change
      })}
                <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}
                {personalDetailsValues?.marital_status === "Married" && (
                <Typography variant="body1" sx={{ fontSize: '20px', fontWeight: 'bold', marginRight: '8px' }}>
                    Spouse Details
                </Typography>
                )}
                <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}

                {personalDetailsValues?.marital_status === "Married" && populateDynamicForm({
        data: spouseDetailsValues,           // form data
        fields: spouseDetailsFields,       // array of field definitions
        handleChange: handleSDInputChange, // handle field change
      })}
                <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}

                <Typography variant="body1" sx={{ fontSize: '20px', fontWeight: 'bold', marginRight: '8px' }}>
                    Bank Details
                </Typography>
                <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}

                {populateDynamicForm({
        data: bankDetailsValues,           // form data
        fields: BankDetailsFields,       // array of field definitions
        handleChange: handleBDInputChange, // handle field change
      })}                <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}


                <Typography variant="body1" sx={{ fontSize: '20px', fontWeight: 'bold', marginRight: '8px' }}>
                    Mailing Address
                </Typography>
                <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}
                {populateDynamicForm({
        data: mailingAddressDetailsValues,           // form data
        fields: mailingAddressFields,       // array of field definitions
        handleChange: handleMDInputChange, // handle field change
      })}
                <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}

            </Grid>

            {/* Render Partners */}

            {/* Entity Type */}

            {(userType === 'user' ) && (
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {BasicDetailsForm ? 'Update' : 'Submit'}
                </Button>
            )}
            {successMessage && (
                <Typography color="success">{successMessage}</Typography>
            )}
        </Box>
    );

    if (loading) return <Typography>Loading...</Typography>;

    return (
        <Box p={0}>
            {userType !== 'user'  ? (
                <>
                    {!BasicDetailsForm ? (
                        <Typography>Details are not provided</Typography>
                    ) : (
                        <>
                        <box>
                            <Button variant="contained" onClick={handleDownloadPdf}>Download PDF</Button>
                            <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}

                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
                        <Typography style={{ paddingRight: '10px' }} variant="body2">Organizer Status:</Typography>
                        {isDocumentVerified ? (
                            <Button sx={{ fontWeight: 'bold' }} variant="contained" color="secondary" disabled>
                                Verified
                            </Button>
                        ) : (userType !== 'user' ? (
                            <Button variant="contained" color="primary" onClick={handleVerify}>
                                Verify
                            </Button>
                        ) : (
                            <Typography variant="body2" color="red" >Not Verified</Typography>
                        ))}
                    </Box>
                    </box>
                            {renderFormFields()}
                        </>
                    )}
                </>
            ) : (
                renderFormFields()
            )}
        </Box>
    );
};

export default BasicDetailsForm;
