// src/components/Header.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Menu, MenuItem } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { handleLogout } from '../utils/tokenUtils';



const Header = () => {
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const username = localStorage.getItem('user_name');

    // Open the menu
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Close the menu
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: 'secondary.main' }}>
            <Toolbar>
                {/* Add the logo image */}
                <Box sx={{ mr: 2 }}>
                    <img
                        src={`${process.env.PUBLIC_URL}/log3.png`}
                        alt="SaveTax LLC Logo"
                        style={{ height: '50px', width: 'auto' }}
                    />
                </Box>
                <Typography variant="h6" sx={{ flexGrow: 1, color: '#ff4700' }}>
                    
                </Typography>
                <Box>
                    {location.pathname === '/signup' ? (
                        <>
                            <Button color="inherit" component={Link} to="/login">
                                Login
                            </Button>
                        </>
                    ) : location.pathname === '/login' ? (
                        <>
                            <Button color="inherit" component={Link} to="/signup">
                                Signup
                            </Button>
                        </>
                    ) : location.pathname === '/home' || location.pathname === '/' ? (
                        <>
                        <Button color="inherit" component={Link} to="/refer-and-earn">
                                Refer & Earn
                            </Button>
                            <Button color="inherit" component={Link} to="/profile">
                                Profile
                            </Button>
                           {/* Welcome Button */}
                           <Button
                                color="inherit"
                                onClick={handleMenuClick}
                            >
                                Welcome, {username}
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </Menu>
                        </>
                    ) : location.pathname === '/create-ticket' || location.pathname === '/ticket-data' || location.pathname === '/refer-and-earn'  ? (
                        <>
                            <Button color="inherit" component={Link} to="/home">
                                Home
                            </Button>
                            <Button color="inherit" component={Link} to="/profile">
                                Profile
                            </Button>
                            {/* Welcome Button */}
                           <Button
                                color="inherit"
                                onClick={handleMenuClick}
                            >
                                Welcome, {username}
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <>
                            <Button color="inherit" component={Link} to="/">
                                Home
                            </Button>
                            
                            <Button color="inherit" component={Link} to="/signup">
                                Signup
                            </Button>
                            <Button color="inherit" component={Link} to="/login">
                                Login
                            </Button>
                        </>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
