import React, { useEffect, useState } from 'react';
import { Container, Typography, Tabs, Tab, Box, Alert, MenuItem, Select, FormControl, InputLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Divider } from '@mui/material';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { getValidAccessToken, getUserType, getUserName } from '../utils/tokenUtils';
import MessagesTab from './TicketTabs/MessagesTab';
import TaxOrganizerTab from './TicketTabs/TaxOrganizerTab';
import MyDocumentsTab from './TicketTabs/MyDocumentsTab';
import TaxDraftTab from './TicketTabs/TaxDraftTab';
import InvoiceTab from './TicketTabs/InvoiceTab';
import FinalDocumentsTab from './TicketTabs/FinalDocumentsTab';
import AssigneeSearch from './AssigneeSearch';

const apiUrl = process.env.REACT_APP_API_URL;


const STATUS_TRANSITIONS = {
    'Upfront Review': ['Need More Information', 'Under Tax Preparation', 'Walkout'],
    'Under Tax Preparation': ['Need More Information', 'Walkout'],
    'Need More Information': ['Under Tax Preparation', 'Walkout'],
    'Draft Sent': ['Draft Approved', 'Payment Made', 'Walkout'],
    'Draft Rejected': ['Walkout'],
    'Draft Approved': ['Walkout'],
    'Payment Made': ['E-Filing', 'ITIN', 'Paper Filing', 'Walkout'],
    'E-Filing': ['8879-Sent', 'Walkout'],
    '8879-Sent': ['8879-Received', 'Walkout'],
    '8879-Received': ['E-Filing Completed', 'Walkout'],
    'E-Filing Completed': ['Final Copies Sent', 'Walkout'],
    'Final Copies Sent': ['Closed', 'Walkout'],
    'ITIN': ['ITIN Applied', 'Walkout'],
    'ITIN Applied': ['ITIN Received', 'Walkout'],
    'ITIN Received': ['State Return Filed', 'Walkout'],
    'State Return Filed': ['Final Copies Sent', 'Walkout'],
    'Paper Filing': ['Final Copies Sent', 'Walkout'],
    'Final Copies Sent': ['Fbar', 'Walkout'],
    'Fbar': ['Fbar Questionnaire Sent', 'Walkout'],
    'Fbar Questionnaire Sent': ['Fbar Questionnaire Received', 'Walkout'],
    'Fbar Questionnaire Received': ['Fbar Sent', 'Walkout'],
    'Fbar Sent': ['Fbar Closed', 'Walkout'],
    'Fbar Closed': ['Closed', 'Walkout']
};


const TicketData = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const ticketId = query.get('ticket_id');

    const [ticketData, setTicketData] = useState(null);
    const [customerData, setCustomerData] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [value, setValue] = useState(0);
    const [userType, setUserType] = useState('');
    const [userName, setUserName] = useState('');
    const [newStatus, setNewStatus] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [agents, setAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState('');
    const [selectedDropDown, setSelectedDropdown] = useState('');

    const [openAgentDialog, setOpenAgentDialog] = useState(false);

    const [taxpreparer, setTaxpreparer] = useState('');
    const [csr, setCsr] = useState('');
    const [pfiler, setPfiler] = useState('');
    const [efiler, setEfiler] = useState('');
    const [fbarfiler, setFbarFiler] = useState('');



    useEffect(() => {
        const fetchTicketData = async () => {
            const token = await getValidAccessToken();
            try {
                const response = await axios.get(`${apiUrl}/user-ticket/?ticket_id=${ticketId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                if (response.data.success) {
                    setTicketData(response.data.ticket_data[0]);

                } else {
                    setErrorMessage('Unable to fetch ticket data.');
                }
            } catch (error) {
                setErrorMessage('Error fetching ticket data.');
                console.error('Error fetching ticket data:', error);
            }
        };




        const fetchAgents = async () => {
            const token = await getValidAccessToken();
            try {
                const response = await axios.get(`${apiUrl}/active-agents/`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                if (response.data.success) {
                    setAgents(response.data.users);
                } else {
                    setErrorMessage('Unable to fetch agent list.');
                }
            } catch (error) {
                setErrorMessage('Error fetching agent list.');
                console.error('Error fetching agent list:', error);
            }
        };

        const fetchUserType = async () => {
            const type = getUserType();
            setUserType(type);
            setUserName(getUserName());
            if (type !== 'user') {
                await fetchAgents();
            }
        };

        const fetchUserDetails = async () => {
            const token = await getValidAccessToken();
            try {
                if (ticketId != null) {
                    const response = await axios.get(`${apiUrl}/user-details?ticket_id=${ticketId}`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });

                    if (response.data.success) {
                        //console.log(JSON.parse(JSON.stringify(response.data.user_data)))
                        setCustomerData(response.data.user_data)
                    } else {
                        setErrorMessage('Unable to fetch User data.');
                    }
                }
            } catch (error) {
                setErrorMessage('Error fetching User data.');
                console.error('Error fetching User data:', error);
            }
        };

        fetchTicketData();
        fetchUserType();
        fetchUserDetails();
    }, [ticketId]);



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
        setOpenDialog(true);
    };

    const confirmStatusUpdate = async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.get(`${apiUrl}/update-ticket-status/?ticket_id=${ticketId}&ticket_status=${selectedStatus}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            console.log('result', response.data);

            if (response.data.success) {
                setTicketData((prevData) => ({ ...prevData, ticket_status: selectedStatus }));
                setErrorMessage('');
            } else {
                setErrorMessage(response.data.message)
                setErrorMessage('Failed to update ticket status.');
            }
        } catch (error) {
            setErrorMessage('Error updating ticket status.');
            setErrorMessage(error.response.data.error)
            console.error('Error updating ticket status:', error);
        } finally {
            setOpenDialog(false);
        }
    };

    const handleAgentChange1 = (event) => {
        setSelectedAgent(event.target.value);
        setOpenAgentDialog(true);
    };

    const handleAgentChange = (dropdownType) => (event) => {
        const selectedValue = event.target.value;

        // Update the relevant state based on the dropdown type
        switch (dropdownType) {
            case 'tax_preparer':
                setTaxpreparer(selectedValue);
                break;
            case 'csr':
                setCsr(selectedValue);
                break;
            case 'p_filer':
                setPfiler(selectedValue);
                break;
            case 'e_filer':
                setEfiler(selectedValue);
                break;
            case 'fbar_filer':
                setFbarFiler(selectedValue);
                break;
            default:
                break;
        }

        // Store the dropdown type and selected value
        setSelectedDropdown(dropdownType);
        setSelectedAgent(selectedValue);
        setOpenAgentDialog(true);
    };

    const confirmAgentUpdate = async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.post(
                `${apiUrl}/update-ticket-assignee/`,
                {
                    ticket_id: ticketId,
                    status_name: selectedDropDown,
                    status_value: selectedAgent
                },
                {
                    headers: { 'Authorization': `Bearer ${token}` }
                }
            );

            if (response.data.success) {
                setTicketData((prevData) => ({ ...prevData, [selectedDropDown]: selectedAgent }));
                setErrorMessage('');
            } else {
                setErrorMessage('Failed to update ticket assignee.');
            }
        } catch (error) {
            setErrorMessage('Error updating ticket assignee.');
            console.error('Error updating ticket assignee:', error);
        } finally {
            setOpenAgentDialog(false);
        }
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setOpenAgentDialog(false);
    };

    return (
        <Box style={{ width: '100%', padding: '20px' }}>

            <Box display="flex" flexDirection="row" gap={2} alignItems="center" sx={{ marginBottom: '10px' }}>
            <Typography variant="h5" gutterBottom>
                Ticket Details
            </Typography>
            {ticketData && (
            <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                        <Typography variant="body1" sx={{ fontSize: '12px' }}>
                            Ticket ID: #{ticketData.ticket_id}
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: '12px' }}>
                            Customer Name: #{customerData != null ? customerData.first_name + '' + customerData.last_name : ''}
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: '13px' }}>
                            Year: {ticketData.service_year_value}
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: '13px' }}>
                            Service: {ticketData.service_name || 'N/A'}
                        </Typography>
                        <Typography variant="body1" sx={{
                            backgroundColor: '#ff4700',
                            color: 'white',
                            borderRadius: '12px',
                            px: 2,
                            py: 1,
                            fontSize: '12px',
                            fontWeight: 'bold'
                        }}>
                            Status: {ticketData.ticket_status || 'N/A'}
                        </Typography>
                    </Box>
                )}
            </Box>

            {errorMessage && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {errorMessage}
                </Alert>
            )}
           

            {ticketData && (
                <Box display="flex" flexDirection="row" gap={1} alignItems="center" sx={{marginTop: '10px'}}>
                    
                    <Box display="flex" flexDirection="row" gap={1} alignItems="center">

                        {userType !== 'user' && (
                            <>
                                <Box sx={{ ml: 2, minWidth: 150 }}>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            shrink
                                            sx={{
                                                fontSize: '15px',
                                                top: -8,
                                                fontWeight: 'bold',
                                                color: 'black',
                                                left: -10
                                            }}
                                        >
                                            Change Status
                                        </InputLabel>
                                        <Select
                                            value={newStatus || ticketData.ticket_status}
                                            onChange={handleStatusChange}
                                            displayEmpty
                                            sx={{
                                                fontSize: '13px',
                                                height: '2.0rem',
                                                '.MuiSelect-select': {
                                                    padding: '10px',
                                                },
                                            }}
                                        >
                                            {ticketData.ticket_status && STATUS_TRANSITIONS[ticketData.ticket_status] && STATUS_TRANSITIONS[ticketData.ticket_status].map((status, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={status}
                                                    sx={{ fontSize: '13px' }}
                                                >
                                                    {status}
                                                </MenuItem>
                                            ))}

                                            {/* Allow admin to transition to any status */}
                                            {userType === 'admin' && Object.keys(STATUS_TRANSITIONS).map((status, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={status}
                                                    sx={{ fontSize: '13px' }}
                                                >
                                                    {status}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </Box>

                                {/* Assignee Dropdown */}
                                <Box sx={{ ml: 2, minWidth: 150 }}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink sx={{ fontSize: '15px', top: -8, fontWeight: 'bold', color: 'black', left: -10 }}>
                                            Tax Preparer
                                        </InputLabel>
                                        <Select
                                            value={taxpreparer || ticketData.tax_preparer}
                                            onChange={handleAgentChange('tax_preparer')}
                                            displayEmpty
                                            sx={{ fontSize: '13px', height: '2.0rem', '.MuiSelect-select': { padding: '10px' }, fontWeight: 'bold' }}
                                        >
                                            {agents && agents?.tax_preparer?.length > 0 ? (
                                                agents.tax_preparer.map((agent, index) => (
                                                    <MenuItem key={index} value={agent} sx={{ fontSize: '13px' }}>
                                                        {agent}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem disabled>No Tax Preparer available</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>

                                {/* Support Dropdown */}
                                <Box sx={{ ml: 2, minWidth: 150 }}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink sx={{ fontSize: '15px', top: -8, fontWeight: 'bold', color: 'black', left: -10 }}>
                                            CSR
                                        </InputLabel>
                                        <Select
                                            value={csr || ticketData.csr}
                                            onChange={handleAgentChange('csr')}
                                            displayEmpty
                                            sx={{ fontSize: '13px', height: '2.0rem', '.MuiSelect-select': { padding: '10px' }, fontWeight: 'bold' }}
                                        >
                                            {agents && agents?.csr?.length > 0 ? (
                                                agents.csr.map((agent, index) => (
                                                    <MenuItem key={index} value={agent} sx={{ fontSize: '13px' }}>
                                                        {agent}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem disabled>No Csr available</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>
                                {/* E-filer Dropdown */}
                                <Box sx={{ ml: 2, minWidth: 150 }}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink sx={{ fontSize: '15px', top: -8, fontWeight: 'bold', color: 'black', left: -10 }}>
                                            E-Filer
                                        </InputLabel>
                                        <Select
                                            value={efiler || ticketData.e_filer}
                                            onChange={handleAgentChange('e_filer')}
                                            displayEmpty
                                            sx={{ fontSize: '13px', height: '2.0rem', '.MuiSelect-select': { padding: '10px' } }}
                                        >
                                            {agents && agents?.e_filer?.length > 0 ? (
                                                agents.e_filer.map((efiler, index) => (
                                                    <MenuItem key={index} value={efiler} sx={{ fontSize: '13px' }}>
                                                        {efiler}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem disabled>No e-filers available</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>

                                {/* P-filer Dropdown */}
                                <Box sx={{ ml: 2, minWidth: 150 }}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink sx={{ fontSize: '15px', top: -8, fontWeight: 'bold', color: 'black', left: -10 }}>
                                            P-Filer
                                        </InputLabel>
                                        <Select
                                            value={pfiler || ticketData.p_filer}
                                            onChange={handleAgentChange('p_filer')}
                                            displayEmpty
                                            sx={{ fontSize: '13px', height: '2.0rem', '.MuiSelect-select': { padding: '10px' } }}
                                        >
                                            {agents && agents?.p_filer?.length > 0 ? (
                                                agents.p_filer.map((pfiler, index) => (
                                                    <MenuItem key={index} value={pfiler} sx={{ fontSize: '13px' }}>
                                                        {pfiler}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem disabled>No p-filers available</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>

                                {/* FBAR Filer Dropdown */}
                                <Box sx={{ ml: 2, minWidth: 150 }}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink sx={{ fontSize: '15px', top: -8, fontWeight: 'bold', color: 'black', left: -10 }}>
                                            FBAR Filer
                                        </InputLabel>
                                        <Select
                                            value={fbarfiler || ticketData.fbar_filer}
                                            onChange={handleAgentChange('fbar_filer')}
                                            displayEmpty
                                            sx={{ fontSize: '13px', height: '2.0rem', '.MuiSelect-select': { padding: '10px' } }}
                                        >
                                            {agents && agents?.fbar_filer?.length > 0 ? (
                                                agents.fbar_filer.map((fbarfiler, index) => (
                                                    <MenuItem key={index} value={fbarfiler} sx={{ fontSize: '13px' }}>
                                                        {fbarfiler}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem disabled>No fbar filers available</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            )}

            <Tabs value={value} sx={{ textAlign: 'left' }} onChange={handleChange} aria-label="Ticket Details Tabs">
                <Tab label="Conversations" />
                <Tab label="Tax Organizer" />
                <Tab label="Documents" />
                <Tab label="Draft" />
                <Tab label="Invoice" />
               {/* <Tab label="Draft" />
                {['user', 'admin'].includes(userType) && <Tab label="Invoice" />} */}
                <Tab label="Final Copies" />

            </Tabs>

            <Box sx={{ p: 1 }}>
                {value === 0 && <MessagesTab ticketId={ticketId} userType={userType} ticketData={ticketData} customerData={customerData} setTicketData={setTicketData} />}
                {value === 1 && <TaxOrganizerTab ticketId={ticketId} userType={userType} ticketData={ticketData} setTicketData={setTicketData} />}
                {value === 2 && <MyDocumentsTab ticketId={ticketId} userType={userType} ticketData={ticketData} setTicketData={setTicketData} />}
                {value === 3 && <TaxDraftTab ticketId={ticketId} userType={userType} ticketData={ticketData} setTicketData={setTicketData} />}
                {value === 4 &&  <InvoiceTab ticketId={ticketId} userType={userType} ticketData={ticketData} setTicketData={setTicketData} customerData={customerData} />}

                {/*{value === 4 && ['user', 'admin'].includes(userType) && <InvoiceTab ticketId={ticketId} userType={userType} ticketData={ticketData} setTicketData={setTicketData} customerData={customerData} />}*/}
                {value === 5 && <FinalDocumentsTab ticketId={ticketId} userType={userType} ticketData={ticketData} />}
                {/*  {value === 6 && <AssigneeSearch ticketId={ticketId} userType={userType} ticketData={ticketData}  />}*/}

            </Box>

            {/* Status Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Confirm Status Update</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to update the ticket status to "{selectedStatus}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={confirmStatusUpdate} color="primary">Confirm</Button>
                </DialogActions>
            </Dialog>

            {/* Assignee Confirmation Dialog */}
            <Dialog open={openAgentDialog} onClose={handleDialogClose}>
                <DialogTitle>Confirm Assignee Update</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to assign this ticket to "{selectedAgent}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={confirmAgentUpdate} color="primary">Confirm</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default TicketData;
