import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { Box, Button, Grid, TextField, Typography, TextareaAutosize, Radio, RadioGroup, FormControlLabel, IconButton } from '@mui/material';
import { getValidAccessToken } from '../../../utils/tokenUtils';
import jsPDF from 'jspdf';
import RemoveIcon from '@mui/icons-material/Remove';

const apiUrl = process.env.REACT_APP_API_URL;

const BookKeepingForm = ({ userType, ticketData, setTicketData }) => {
    const [bookKeepingForm, setBookKeepingForm] = useState(() => {
        // Initialize all form fields with a default value of 0
        const initialValues = {};
        initialValues['total_a'] = ''; // Initialize as 0
        return initialValues;
    });
    const [loading, setLoading] = useState(true);
    const [vehicleInfo, setVehicleInfo] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [total, setTotal] = useState(0); // Additional total variable
    const [totalD, setTotalD] = useState(0); // Additional total variable




    function toPascalCase(str) {
        return str.replace(/_/g, ' ')
            .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
                index === 0 ? match.toUpperCase() : match.toUpperCase()
            );

    }

    useEffect(() => {
        const fetchbookKeepingForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'bookkeeping_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.bookkeeping_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setBookKeepingForm(parsedDetails);
                    setVehicleInfo(parsedDetails.vehicleInfo || []);
                    setBFormValues(parsedDetails.bFormValues || {});
                    setCFormValues(parsedDetails.cFormValues || {});
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchbookKeepingForm();
    }, [ticketData.tax_organizer_id]);

    const handleSubmit = async () => {
        setSuccessMessage('');

        try {
            const token = await getValidAccessToken();
            const response = await axios.post(
                `${apiUrl}/update-taxorganizer-field/`,
                {
                    tax_organizer_id: ticketData.tax_organizer_id,
                    column_name: 'bookkeeping_details',
                    column_value: JSON.stringify({ ...bookKeepingForm, vehicleInfo,bFormValues,cFormValues }),
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
         //   console.log('Form saved successfully:', response.data);
            setSuccessMessage('Updated successfully!');
        } catch (error) {
            console.error('Error saving form:', error);
            setSuccessMessage('Error updating form. Please try again.');
        }
    };


    const cFormFields = [
        { name: 'accounting', type: 'number' },
        { name: 'interest_loans_cards', type: 'number' },
        { name: 'security', type: 'number' },
        { name: 'advertising', type: 'number' },
        { name: 'internet_cable', type: 'number' },
        { name: 'supplies_job', type: 'number' },
        { name: 'bank_fees', type: 'number' },
        { name: 'janitorial_cleaning', type: 'number' },
        { name: 'supplies_office', type: 'number' },
        { name: 'business_license', type: 'number' },
        { name: 'marketing', type: 'number' },
        { name: 'taxes', type: 'number' },
        { name: 'business_meals', type: 'number' },
        { name: 'materials_job', type: 'number' },
        { name: 'telephone', type: 'number' },
        { name: 'card_processing', type: 'number' },
        { name: 'merchant_services_fees', type: 'number' },
        { name: 'tools', type: 'number' },
        { name: 'cell_phone', type: 'number' },
        { name: 'linen_laundry', type: 'number' },
        { name: 'travel', type: 'number' },
        { name: 'commissions', type: 'number' },
        { name: 'legal_fees', type: 'number' },
        { name: 'uniforms', type: 'number' },
        { name: 'computer', type: 'number' },
        { name: 'hardware', type: 'number' },
        { name: 'payroll', type: 'number' },
        { name: 'waste_removal', type: 'number' },
        { name: 'software', type: 'number' },
        { name: 'payroll_processing_fees', type: 'number' },
        { name: 'guaranteed_payments', type: 'number' },
        { name: 'tech_support', type: 'number' },
        { name: 'parking_tolls', type: 'number' },
        { name: 'auto_expenses_add_link', type: 'number' },
        { name: 'website', type: 'number' },
        { name: 'postage_delivery', type: 'number' },
        { name: 'home_and_office_add_link', type: 'number' },
        { name: 'continuing_ed', type: 'number' },
        { name: 'prof_dues_licenses', type: 'number' },
        { name: 'other_list_description', type: 'number' },
        { name: 'consultants', type: 'number' },
        { name: 'recruiting', type: 'number' },
        { name: 'one', type: 'number' },
        { name: 'contract_labor', type: 'number' },
        { name: 'rent', type: 'number' },
        { name: 'two', type: 'number' },
        { name: 'freight', type: 'number' },
        { name: 'building', type: 'number' },
        { name: 'three', type: 'number' },
        { name: 'gifts', type: 'number' },
        { name: 'equipment', type: 'number' },
        { name: 'indep_contractor', type: 'number' },
        { name: 'repairs_maintenance', type: 'number' },
        { name: 'insurance_not_car', type: 'number' },
        { name: 'royalty_franchise_fees', type: 'number' },

        // Field to store the total sum
        { name: 'total_c', type: 'number', readonly: true }
    ];


    const [cFormValues, setCFormValues] = useState(() => {
        // Initialize all form fields with a default value of 0
        const initialValues = {};
        cFormFields.forEach(field => {
            initialValues[field.name] = ''; // Initialize as 0
        });
        return initialValues;
    });



    // Handle input changes and recalculate total
    const handleCInputChange = (fieldName, value) => {
        value = value;

        // Update the form values
        const updatedFormValues = { ...cFormValues, [fieldName]: value };

        // Recalculate the total excluding 'total_c'
        const newTotal = Object.keys(updatedFormValues)
            .filter(key => key !== 'total_c') // Exclude 'total_c'
            .reduce((acc, key) => acc + (parseFloat(updatedFormValues[key]) || 0), 0);

        // Update total_c with the recalculated total
        updatedFormValues['total_c'] = newTotal;

        // Set the updated form values
        setCFormValues(updatedFormValues);
    };


    const bFormFields = [
        { name: 'beginning_inventory', type: 'number' },
        { name: 'purchased_materials_or_inventory', type: 'number' },
        { name: 'cost_of_direct_labor', type: 'number' },
        { name: 'ending_inventory', type: 'number' },
        { name: 'cost_of_goods_sold_total_b', type: 'number' },

    ];

    const [bFormValues, setBFormValues] = useState(() => {
        // Initialize all form fields with a default value of 0
        const initialValues = {};
        bFormFields.forEach(field => {
            initialValues[field.name] = ''; // Initialize as 0
        });
        return initialValues;
    });


    const handleBInputChange = (fieldName, value) => {
        value = value ;

        // Update the form values
        const updatedFormValues = { ...bFormValues, [fieldName]: value };

        // Update total_c with the recalculated total
        updatedFormValues['cost_of_goods_sold_total_b'] = parseFloat(updatedFormValues['beginning_inventory']||0) + parseFloat(updatedFormValues['purchased_materials_or_inventory']||0) + parseFloat(updatedFormValues['cost_of_direct_labor']||0) - parseFloat(updatedFormValues['ending_inventory']||0);

        // Set the updated form values
        setBFormValues(updatedFormValues);
    };

    const handleDownloadPdf = () => {
        const doc = new jsPDF();
        doc.text('Partner Information Details', 10, 10);
       // console.log(JSON.stringify(bookKeepingForm))
        Object.entries(bookKeepingForm || {}).forEach(([key, value], index) => {
            doc.text(`${key}: ${value}`, 10, 20 + index * 10);
        });
        doc.save('partner_information_details.pdf');
    };

    useEffect(() => {
        const TotalA = parseFloat(bookKeepingForm.total_a || 0)
        const TotalB = parseFloat(bFormValues.cost_of_goods_sold_total_b || 0)
        const TotalC = parseFloat(cFormValues.total_c || 0)
        const newTotal = TotalA - TotalB - TotalC; // Combine totals
        setTotal(newTotal);
        bookKeepingForm['total_abc']=newTotal;
    }, [bFormValues, cFormValues, bookKeepingForm]);


    const vehicleFormFields = [
        { name: 'Property Name', type: 'textbox' },
        { name: 'Property Address', type: 'textbox' },
        { name: 'description', type: 'textbox' },
        { name: 'date_of_purchase', type: 'date' },
        { name: 'amount', type: 'number' },
    ];

    const handleAddVehicle = () => {
        setVehicleInfo([...vehicleInfo,
        {
            description: '',
            date_of_purchase: '',
            amount: '',
        }
        ]);
    };

    const handleRemoveVehicle = (index) => {
        setVehicleInfo(vehicleInfo.filter((_, i) => i !== index));
    };

    const handleVehicleChange = (index, field, value) => {
        const updatedPartners = [...vehicleInfo];
        //console.log("Value is : "+value);
      /*  if (value.startsWith("0") && value.length > 0) {

          //  console.log("Value is After : "+parseFloat(value.toString().padStart(2, '0')));
          // console.log("Value is After 23 : "+field);

            updatedPartners[index]  ={ ...updatedPartners[index], [field]: Number(value)};
        }
        else  if (value.length > 0) {
          /!*  console.log("Value is : "+value);
            console.log("Value is After : "+parseFloat(value.toString().padStart(2, '0')));
            console.log("Value is After 23 : "+Number(value.toString().padStart(2, '0')));
            console.log("Value is After 23 Index: "+field);
            console.log("Value is After 23  Index: "+index);*!/
            if(field === "date_of_purchase") {
                updatedPartners[index]  ={ ...updatedPartners[index], [field]:value};
            }
            else
                updatedPartners[index]  ={ ...updatedPartners[index], [field]: Number(value)};

        }
        else {
         //   console.log("Normal : "+value);
            updatedPartners[index]  ={ ...updatedPartners[index], [field]: ''};
        }*/
        updatedPartners[index] = { ...updatedPartners[index], [field]: value };
        setVehicleInfo(updatedPartners);
    };

 
    useEffect(() => {
        const newTotal = vehicleInfo.reduce((acc, vehicle) => acc + (parseFloat(vehicle.amount) || 0), 0);
        setTotalD(newTotal);
        bookKeepingForm['total_d']=newTotal;
    }, [vehicleInfo]);


    const renderVehicleForm = () => (
        <>
            {vehicleInfo.map((partner, index) => (
                <Box key={index} mb={0}>
                    <Grid container spacing={1} >
                        {vehicleFormFields.map((field) => {
                            const isFullWidthField = field.type === 'radio' || field.type === 'textarea';
                            return (
                                <Grid item xs={12} sm={isFullWidthField ? 12 : 4} key={field.name}>
                                    {field.type === 'textbox' ? (
                                        <TextField
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name]}
                                            onChange={(e) => handleVehicleChange(index, field.name, e.target.value)}
                                            fullWidth
                                            size="small"
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'number' ? (
                                        <TextField
                                            type="number"
                                            label={toPascalCase(field.name)}
                                            InputProps={{ inputProps: { min: 1},style: { fontSize: '16px' }, sx: { height: 40, padding: '5px 5px' }}}
                                            value={partner[field.name]}
                                            onChange={(e) => handleVehicleChange(index, field.name, e.target.value)}
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'date' ? (
                                        <TextField
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name] || ''}
                                            onChange={(e) => handleVehicleChange(index, field.name, e.target.value)}
                                            fullWidth
                                            type="date"
                                            size="small"
                                            disabled={userType !== 'user'}
                                            InputLabelProps={{
                                                shrink: true, // Keep the label above the input
                                            }}
                                        />
                                    ) : null}
                                </Grid>
                            );
                        })}
                        <Grid item xs={12} mb={0} mt={0}>
                            <IconButton onClick={() => handleRemoveVehicle(index)}>
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            ))}
            <Button variant="outlined" color="primary" onClick={handleAddVehicle} disabled={userType !== 'user'} >
                Add Property
            </Button>
        </>
    );


    const renderBForm = () => (
        <>
            <Box mb={2}>
                <Grid container spacing={1} >
                    {bFormFields.map((field) => {
                        const isFullWidthField = field.type === 'radio' || field.type === 'textarea';
                        return (
                            <Grid item xs={12} sm={isFullWidthField ? 12 : 6} key={field.name}>
                                {field.type === 'number' ? (
                                    <TextField
                                        type="number"
                                        label={toPascalCase(field.name)}
                                        InputProps={{ inputProps: { min: 1},style: { fontSize: '16px' }, sx: { height: 40, padding: '5px 5px' }}}
                                        value={bFormValues[field.name]}
                                        onChange={(e) => handleBInputChange(field.name, e.target.value)}
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        disabled={userType !== 'user'}
                                    />
                                ) : null}
                            </Grid>
                        );
                    })}

                </Grid>
            </Box>

        </>
    );

    const renderCForm = () => (
        <>
            <Box mb={0}>
                <Grid container spacing={1} >
                    {cFormFields.map((field) => {
                        const isFullWidthField = field.type === 'radio' || field.type === 'textarea';
                        return (
                            <Grid item xs={12} sm={isFullWidthField ? 12 : 2} key={field.name}>
                                {field.type === 'number' ? (
                                    <TextField
                                        type="number"
                                        label={toPascalCase(field.name)}
                                        InputProps={{ inputProps: { min: 1},style: { fontSize: '16px' }, sx: { height: 40, padding: '5px 5px' }}}
                                        value={cFormValues[field.name]}
                                        onChange={(e) => handleCInputChange(field.name, e.target.value)}
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        disabled={userType !== 'user'}
                                    />
                                ) : null}
                            </Grid>
                        );
                    })}

                </Grid>
            </Box>


        </>
    );

    const renderFormFields = () => (
        <Box mt={0} >

            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
                        Do you use bookkeeping software, bookkeeping service, or have your own yearend Profit & Loss:
                    </Typography>
                </Grid>
                <Grid item>
                    <RadioGroup
                        row
                        value={bookKeepingForm?.is_bookingkeeping_available || ''}
                        onChange={(e) => setBookKeepingForm({ ...bookKeepingForm, is_bookingkeeping_available: e.target.value })}
                    >
                        <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType !== 'user'} />
                        <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType !== 'user'} />

                    </RadioGroup>
                </Grid>
            </Grid>

            {bookKeepingForm && bookKeepingForm.is_bookingkeeping_available === 'No' && (


                <Grid item xs={12}>

                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                                A. Gross Revenue ("Total Sales"; or "Income before Expenses"):
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                label='Total A'
                                type="number"
                                InputProps={{ inputProps: { min: 1},style: { fontSize: '16px' }, sx: { height: 40, padding: '5px 5px' }}}
                                value={bookKeepingForm?.total_a || ''}
                                onChange={(e) => setBookKeepingForm({ ...bookKeepingForm, total_a: e.target.value })}
                                size="small"
                                disabled={userType !== 'user'}
                                sx={{ marginLeft: '8px' }} // Add some spacing between the label and textbox
                            />

                        </Grid>
                        <Typography variant="body1" sx={{ fontSize: '14px', marginRight: '8px' }}>
                            *Please attach any below listed documents under my documents tab.
                            Form(s) 1099,
                            Sales Invoices,
                            Customer Service Agreements and
                            Bank Proceeds Matching with Sales Invoices
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: '14px', marginRight: '8px' }}>
                            * The Gross Revenue listed above must be more than if any cash transactions, or equal to, the total of all Form(s) 1099 and income related documents mention above.
                        </Typography>
                        <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}

                    </Grid>



                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
                        B.	Manufacturers/Sellers of Inventory Goods:
                    </Typography>
                    <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}

                    {renderBForm()}
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
                        C.	Expenses:
                    </Typography>

                    <Typography variant="body1" sx={{ fontSize: '14px', marginRight: '8px' }}>
                        Note: **Do not include expenses related to Primary Residence.
                    </Typography>
                    <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}

                    {renderCForm()}
                    <Typography variant="body1" sx={{ fontSize: '14px', marginRight: '8px' }}>

                        Note: Please maintain the bills or receipts for future reference
                    </Typography>
                    <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}


                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                                Taxable Profit/Loss: ( $A - $B - $C ):
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                label='Total'
                                type="number"
                                inputProps={{ style: { fontSize: '16px' } }}
                                value={total}
                                size="small"
                                disabled
                                sx={{ marginLeft: '8px' }} // Add some spacing between the label and textbox
                            />

                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}

                    </Grid>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                                D.	Equipment, Leasehold Improvements, or other assets purchased*  Total D:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                label='Total'
                                type="number"
                                inputProps={{ style: { fontSize: '16px' } }}
                                value={totalD}
                                size="small"
                                disabled
                                sx={{ marginLeft: '8px' }} // Add some spacing between the label and textbox
                            />

                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}

                    </Grid>
                    
                    {renderVehicleForm()}
                </Grid>
            )}
            {/* Render Partners */}

            {/* Entity Type */}
            <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}

           
            {userType === 'user' && (
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {bookKeepingForm ? 'Update' : 'Submit'}
                </Button>
            )}
            {successMessage && (
                <Typography color="success">{successMessage}</Typography>
            )}
        </Box>
    );

    if (loading) return <Typography>Loading...</Typography>;

    return (
        <Box p={0}>
            {userType !== 'user' ? (
                <>
                    {!bookKeepingForm ? (
                        <Typography>Details are not provided</Typography>
                    ) : (
                        <>

                            {renderFormFields()}
                        </>
                    )}
                </>
            ) : (
                renderFormFields()
            )}
        </Box>
    );
};

export default BookKeepingForm;
