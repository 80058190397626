
import React from 'react';
import { Alert, Box, Button, Container, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { set } from 'firebase/database';
import axios from 'axios';
import { getValidAccessToken } from '../utils/tokenUtils';
import Loader from '../utils/Loader';

const styles = {
    th: {
      backgroundColor: '#07223d',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '0.875rem',
      padding: '8px',
      textAlign: 'left',
    },
    td: {
      padding: '8px',
      borderBottom: '1px solid #ddd',
    },
  };
  const apiUrl = process.env.REACT_APP_API_URL;

const AllUsers = () => {
    const [errorMessage, setErrorMessage] = React.useState('');
    const [users, setUsers] = React.useState([]);
    const [employees, setEmployees] = React.useState([]);
    const [activeTab, setActiveTab] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    

    React.useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            const token = await getValidAccessToken();
            try {
                const response = await axios.get(`${apiUrl}/users/all-users`,{
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log(response.data.data);
                setUsers(response.data.data.users || []);
                setEmployees(response.data.data.non_users || []);
                setLoading(false);
            } catch (error) {
                setErrorMessage('Failed to fetch users.');
                setLoading(false);
            }
        }
        fetchUsers();
    } , []);
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
      };
    if(loading) {
        return (
            <Container style={{ padding: '10px', width: 'auto' }}>
                <Box sx={{ mt: 1, mb: 1 }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        All Users
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <Loader />
                    </Box>
                </Box>
            </Container>
        );
    }
    const renderTable = (data) => (
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={styles.th}>User Id</th>
              <th style={styles.th}>User Name</th>
              <th style={styles.th}>User Email</th>
              <th style={styles.th}>User Phone</th>
              <th style={styles.th}>User Type</th>
              <th style={styles.th}>Total Referral Count</th>
              <th style={styles.th}>Status</th>
              <th style={styles.th}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((user, index) => (
              <tr key={index}>
                <td style={styles.td}>{user.username}</td>
                <td style={styles.td}>{`${user.first_name} ${user.last_name}`}</td>
                <td style={styles.td}>{user.email}</td>
                <td style={styles.td}>{user.phone_number}</td>
                <td style={styles.td}>{user.user_type}</td>
                <td style={styles.td}>{user.referal_count}</td>
                <td style={styles.td}>{user.is_activated ? "Active" : "Inactive"}</td>
                <td style={styles.td}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<RemoveRedEyeIcon />}
                    href={`/user/${user.username}`}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    return (
        <Container style={{ padding: "10px", width: "auto" }}>
        <Box sx={{ mt: 1, mb: 1 }}>
          <Typography variant="h4" align="center" gutterBottom>
            All Users
          </Typography>
          {errorMessage && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
          )}
          <Box sx={{ mt: 2 }}>
            <Tabs value={activeTab} onChange={handleTabChange} centered>
              <Tab label="Users" />
              <Tab label="Employees" />
            </Tabs>
            <Box sx={{ mt: 2 }}>
              {activeTab === 0 && renderTable(users)}
              {activeTab === 1 && renderTable(employees)}
            </Box>
          </Box>
        </Box>
      </Container>
    );
}
export default AllUsers;