
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { TableContainer,Paper,Box,Table,TableHead,TableRow,TableCell,TableBody, Button, Grid, TextField, Typography, TextareaAutosize, Radio, RadioGroup, FormControlLabel, IconButton } from '@mui/material';
import { getValidAccessToken } from '../../../utils/tokenUtils';
import jsPDF from 'jspdf';
import RemoveIcon from '@mui/icons-material/Remove';

const apiUrl = process.env.REACT_APP_API_URL;

function toPascalCase(str) {
  return str.replace(/_/g, ' ')
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
          index === 0 ? match.toUpperCase() : match.toUpperCase()
      );

}


  
const FinancialTable = ({ fields, handleInputChange, title, totalValue1, totalValue2,userType }) => (
  <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
    <Typography variant="h6" sx={{ marginTop: 1, marginBottom: 1 }}>
      {title}
    </Typography>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem' }}>{title}</TableCell>
          <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem' }} align="center">Prior Year As of 12/31</TableCell>
          <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem' }} align="center">Current Year As of 12/31</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {fields.map((field, index) => (
          <TableRow key={field.name}>
            <TableCell>{toPascalCase(field.name)}</TableCell>
            <TableCell align="center">
              <TextField
                type="number"
                disabled={userType !== 'user'}
                value={field.value1}
                InputProps={{ inputProps: { min: 1},style: { fontSize: '16px' }, sx: { height: 40, padding: '5px 5px' }}}
                onChange={(e) => handleInputChange(index, 'value1', e.target.value)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell align="center">
              <TextField
                type="number"
                disabled={userType !== 'user'}
                value={field.value2}
                InputProps={{ inputProps: { min: 1},style: { fontSize: '16px' }, sx: { height: 40, padding: '5px 5px' }}}
                onChange={(e) => handleInputChange(index, 'value2', e.target.value)}
                size="small"
                fullWidth
              />
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell><strong>Total</strong></TableCell>
          <TableCell align="center"><strong>{totalValue1}</strong></TableCell>
          <TableCell align="center"><strong>{totalValue2}</strong></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);


// FinancialTable component defined outside

const FieldTable = ({ userType, ticketData, setTicketData }) => {

  const [balanceSheet, setBalanceSheet] = useState({});
  const [loading, setLoading] = useState(true);

  const [successMessage, setSuccessMessage] = useState('');



useEffect(() => {
  const fetchbalanceSheetForm = async () => {
      try {
          const token = await getValidAccessToken();
          const response = await axios.post(
              `${apiUrl}/tax-organizer-details/`,
              {
                  tax_organizer_id: ticketData.tax_organizer_id,
                  column_names: 'balance_sheet_details'
              },
              { headers: { Authorization: `Bearer ${token}` } }
          );
          const details = response.data.data.balance_sheet_details;

          if (details) {
              const parsedDetails = JSON.parse(details);
              setBalanceSheet(parsedDetails);
              setAssets(parsedDetails.assets || {});
              setLiabilities(parsedDetails.liabilities || {});
              totalValue1Assets=parsedDetails.totalValue1Assets;
              totalValue2Assets=parsedDetails.totalValue2Assets;
              totalValue1Liabilities=parsedDetails.totalValue1Liabilities;
              totalValue2Liabilities=parsedDetails.totalValue2Liabilities;
          }
      } catch (error) {
          console.error('Error fetching business formation details:', error);
      } finally {
          setLoading(false);
      }
  };

  fetchbalanceSheetForm();
}, [ticketData.tax_organizer_id]);

const handleDownloadPdf = () => {
  const doc = new jsPDF();
  doc.text('Partner Information Details', 10, 10);
    //console.log(JSON.stringify(balanceSheet))
  Object.entries(balanceSheet || {}).forEach(([key, value], index) => {
      doc.text(`${key}: ${value}`, 10, 20 + index * 10);
  });
  doc.save('partner_information_details.pdf');
};

const handleSubmit = async () => {
  try {
    setSuccessMessage('');

      const token = await getValidAccessToken();
      const response = await axios.post(
          `${apiUrl}/update-taxorganizer-field/`,
          {
              tax_organizer_id: ticketData.tax_organizer_id,
              column_name: 'balance_sheet_details',
              column_value: JSON.stringify({ ...balanceSheet, assets, totalValue1Assets, totalValue2Assets ,liabilities ,totalValue1Liabilities,totalValue2Liabilities}),
          },
          { headers: { Authorization: `Bearer ${token}` } }
      );
    //  console.log('Form saved successfully:', response.data);
      setSuccessMessage('Updated successfully!');
  } catch (error) {
      console.error('Error saving form:', error);
      setSuccessMessage('Error updating form. Please try again.');
  }
};


  const [assets, setAssets] = useState([
    { name: 'cash_in_banks_or_in_hand', value1: '', value2:'' },
    { name: 'accounts_receivable', value1: '', value2: '' },
    { name: 'inventory', value1: '', value2:'' },
    { name: 'other_current_assets', value1: '', value2:'' },
    { name: 'loans_to_partners', value1: '', value2: '' },
    { name: 'notes_receivable', value1: '', value2:'' },
    { name: 'other_investments', value1: '', value2: '' },
    { name: 'other_assets', value1: '', value2:'' },
  ]);

  const [liabilities, setLiabilities] = useState([
    { name: 'accounts_payable', value1: '', value2:'' },
    { name: 'short_term_notes_payable', value1: '', value2: '' },
    { name: 'other_current_liabilities', value1: '', value2:'' },
    { name: 'loans_from_partners', value1: '', value2:'' },
    { name: 'long_term_notes_payable', value1: '', value2: '' },
    { name: 'long_term_notes_payable', value1: '', value2:'' },
  ]);

  const handleInputChange = (index, key, value, type) => {
    const newData = type === 'assets' ? [...assets] : [...liabilities];
      if (value.startsWith("0") && value.length > 0) {
        /*  console.log("Value is : "+value);
          console.log("Value is After : "+parseFloat(value.toString().padStart(2, '0')));
          console.log("Value is After 23 : "+Number(value.toString().padStart(2, '0')));*/
          newData[index][key] =Number(value);
      }
      else  if (value.length > 0) {
        /*  console.log("Value is : "+value);
          console.log("Value is After : "+parseFloat(value.toString().padStart(2, '0')));
          console.log("Value is After 23 : "+Number(value.toString().padStart(2, '0')));*/
          newData[index][key] =Number(value);
      }
      else {
      //    console.log("Normal : "+value);
          newData[index][key] = '';
      }
    //newData[index][key] = parseFloat(value) || 0;
    type === 'assets' ? setAssets(newData) : setLiabilities(newData);
  };
  let totalValue1Assets = assets.reduce((acc, field) => acc + (field.value1 || 0), 0);
    let  totalValue2Assets = assets.reduce((acc, field) => acc + (field.value2 || 0), 0);
    let totalValue1Liabilities = liabilities.reduce((acc, field) => acc + (field.value1 || 0), 0);
    let  totalValue2Liabilities = liabilities.reduce((acc, field) => acc + (field.value2 || 0), 0);
  

  
  const renderFormFields = () => (
    <Box mt={0} >

        <Grid container alignItems="center" spacing={1}>
            <Grid item>
                <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
                If this business has revenue greater than $250,000:
                </Typography>
            </Grid>
            <Grid item>
                <RadioGroup
                    row
                    value={balanceSheet?.is_gt_250k || ''}
                    onChange={(e) => setBalanceSheet({ ...balanceSheet, is_gt_250k: e.target.value })}
                >
                    <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType !== 'user'} />
                    <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType !== 'user'} />

                </RadioGroup>
            </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
            <Grid item>
                <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
                Business Total Assets is more than $1,000,000  for Partnership, S corporation and 10 million for corporation:
                </Typography>
            </Grid>
            <Grid item>
                <RadioGroup
                    row
                    value={balanceSheet?.is_gt_1000k || ''}
                    onChange={(e) => setBalanceSheet({ ...balanceSheet, is_gt_1000k: e.target.value })}
                >
                    <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType !== 'user'} />
                    <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType !== 'user'} />

                </RadioGroup>
            </Grid>
        </Grid>

            <Grid item xs={12}>


            <FinancialTable
        fields={assets}
        handleInputChange={(index, key, value) => handleInputChange(index, key, value, 'assets')}
        title="Assets"
        totalValue1={totalValue1Assets}
        totalValue2={totalValue2Assets}
        userType={userType}
      />
      <FinancialTable
        fields={liabilities}
        handleInputChange={(index, key, value) => handleInputChange(index, key, value, 'liabilities')}
        title="Liabilities"
        totalValue1={totalValue1Liabilities}
        totalValue2={totalValue2Liabilities}
        userType={userType}
      />


            </Grid>
    
        {/* Render Partners */}

        {/* Entity Type */}

        {userType === 'user' && (
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                {balanceSheet ? 'Update' : 'Submit'}
            </Button>
        )}
        {successMessage && (
            <Typography color="success">{successMessage}</Typography>
        )}
    </Box>
);

if (loading) return <Typography>Loading...</Typography>;

return (
    <Box p={0}>
        {userType !== 'user' ? (
            <>
                {!balanceSheet ? (
                    <Typography>Details are not provided</Typography>
                ) : (
                    <>
                        {renderFormFields()}
                    </>
                )}
            </>
        ) : (
            renderFormFields()
        )}
    </Box>
);

};

export default FieldTable; // Ensure this is a default export
