import React from "react";
import { useParams } from "react-router-dom";
import { getValidAccessToken } from "../utils/tokenUtils";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  CircularProgress,
  Chip,
  LinearProgress,
  Avatar,
  FormControlLabel,
  Switch,
  Pagination,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Table,
  TableHead,
} from "@mui/material";
import ReferAndEarn from "./ReferAndEarn";

const apiUrl = process.env.REACT_APP_API_URL;

const ViewUserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(5); // Number of referrals to show per page
  const [totalPages, setTotalPages] = React.useState(0);
  const [referralDataCount, setReferralDataCount] = React.useState();
  const [referralDetails, setReferralDetails] = React.useState([]);

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = await getValidAccessToken();
        if (!token) {
          console.error("Access token is missing or invalid");
          return;
        }

        const response = await axios.get(`${apiUrl}/users/view-user/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch user details");
        setLoading(false);
      }
    };
    fetchUser();
  }, [id]);

    React.useEffect(() => {
    const fetchReferralData = async () => {
        const token = await getValidAccessToken();
        if (token) {
            try {
                const referralDetailsResponse = await axios.get(`${apiUrl}/users/referal-user-details/${id}`,{
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log(referralDetailsResponse.data );
                setReferralDetails(referralDetailsResponse.data.referal_data || []);
                setReferralDataCount(referralDetailsResponse.data.referal_data.length || 0);
                setTotalPages(Math.ceil(referralDetailsResponse.data.referal_data.length / itemsPerPage));
            } catch (error) {
                console.error("Failed to fetch referral details",error);
            }
        }
    };
    fetchReferralData();
}, [id]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
 
  // Pagination for referrals
  const indexOfLastReferral = currentPage * itemsPerPage;
  const indexOfFirstReferral = indexOfLastReferral - itemsPerPage;

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    return (
      <Typography variant="h6" align="center" color="error">
        Failed to load user details.
      </Typography>
    );
  }

  const { first_name, last_name, username, email, phone_number, user_type, created_at, is_activated, tickets } = user;

  const totalTickets = Object.values(tickets).reduce((total, count) => total + count, 0);

  return (
    <Box sx={{ p: 4 }}>
      {/* Ticket Status Section */}

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 3,
          mb: 3,
        }}
      >
        {Object.entries(tickets).map(([status, count], index) => (
          <Card
            key={index}
            sx={{
              flex: 1,
              textAlign: "center",
              p: 3,
              boxShadow: 3,
              bgcolor: (theme) =>
                theme.palette.mode === "light"
                  ? "#f9f9f9"
                  : theme.palette.background.paper,
            }}
          >
            <Avatar
              sx={{
                width: 64,
                height: 64,
                bgcolor: (theme) =>
                  theme.palette.background.default || "#f0f0f0", // Fallback
                mb: 2,
                fontSize: "1.5rem",
                color: "text.primary",
                border: "1px solid",
                borderColor: "text.secondary",
                margin: "0 auto",
              }}
            >
              {count}
            </Avatar>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {status}
            </Typography>
          </Card>
        ))}
      </Box>
      <Typography variant="h4" align="center" gutterBottom>
        User Details
      </Typography>

      {/* User Information Card */}
      <Card sx={{ maxWidth: "100%", mx: "auto", mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Personal Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>First Name:</strong> {first_name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Last Name:</strong> {last_name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Username:</strong> {username}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Email:</strong> {email}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Phone Number:</strong> {phone_number}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>User Type:</strong> {user_type}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Created At:</strong>{" "}
                {new Date(created_at).toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={is_activated} // State to manage activation status
                    // onChange={handleToggle} // Function to handle the toggle logic
                    color="success"
                  />
                }
                label={is_activated ? "Activated" : "Deactivated"}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box sx={{  justifyContent: "center" }}>
      <Typography
        variant="h5"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        Referral Details
      </Typography>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "secondary.main",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Username
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "secondary.main",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Email
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "secondary.main",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Signup On
              </TableCell>
              {user_type === "admin" && (
              <TableCell
                sx={{
                  backgroundColor: "secondary.main",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Refered By
              </TableCell>
              )  
              }
              <TableCell
                sx={{
                  backgroundColor: "secondary.main",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Total Ticket Count
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "secondary.main",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Paid Ticket Count
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "secondary.main",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Not Paid Ticket Count
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {referralDetails.length > 0 ? (
              referralDetails.map((referral) => (
                <TableRow key={referral.id}>
                  <TableCell>{referral.username}</TableCell>
                  <TableCell>{referral.email}</TableCell>
                  <TableCell>
                    {new Date(referral.created_at).toLocaleDateString()}
                  </TableCell>
                  {user_type === "admin" && (
                  <TableCell>
                    {referral.referrer}
                  </TableCell>
                  )}
                  <TableCell>{referral.total_tickets}</TableCell>
                  <TableCell>{referral.paid_count}</TableCell>
                  <TableCell>{referral.unpaid_count}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No referrals available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      />
        </Box>
    </Box>
  );
};

export default ViewUserDetails;
