import React from 'react';
import { Alert, Box, Button, Container, IconButton, InputAdornment, Snackbar, TextField, Typography } from '@mui/material';
import Loader from '../utils/Loader';
import axios from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

export const ResetPassword = () => {
    const { uid, token } = useParams();
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    
    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };
    
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prev) => !prev);
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setLoader(true);
        
        try {
            // Validate password and confirm password
            if (password !== confirmPassword) {
                setErrorMessage('Password and Confirm Password do not match');
                setLoader(false);
                return;
            }
    
            // Get uid and token from the URL or a stored location
            console.log(uid, token);
    
            if (!uid || !token) {
                setErrorMessage('Invalid or missing reset link. Please request a new one.');
                setLoader(false);
                return;
            }
    
            // Send request to reset password
            const response = await axios.post(`${apiUrl}/reset-password/`, {
                uid: uid,
                token: token,
                new_password: password,
            });
    
            // Handle success or error response
            if (response.data.success) {
                setMessage(response.data.message);
            } else {
                setErrorMessage(response.data.message || 'Failed to reset password.');
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage(error.response?.data?.message || 'An error occurred. Please try again later.');
        } finally {
            setLoader(false);
        }
    };
    

    return (
        <Container maxWidth="xs">
            <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={!!message} autoHideDuration={6000} onClose={() => setMessage('')}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        {loader && <Loader />}
        <Box sx={{ mt: 5, mb: 5 }}>
            <Typography variant="h4" align="center" gutterBottom>
                Forgot Password
            </Typography>
            <Typography variant="caption" align="center" gutterBottom>
                Enter your email address to reset your password, A link will be sent to your email address.
                </Typography>
            <form onSubmit={handleForgotPassword}>
                <TextField
                    label="Enter New Password"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    type={showPassword ? 'text' : 'password'}
                    inputProps={{ style: { fontSize: '16px' } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={togglePasswordVisibility}
                                    edge="end"
                                    aria-label="toggle password visibility"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
            label="Confirm New Password"
            variant="outlined"
            fullWidth
            margin="dense"
            name="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            inputProps={{ style: { fontSize: '16px' } }}
            type={showConfirmPassword ? 'text' : 'password'} // Toggle password visibility
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={toggleConfirmPasswordVisibility}
                            edge="end"
                            aria-label="toggle password visibility"
                        >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
                
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    sx={{ mt: 3 }}>
                    Forgot Password
                </Button>
            </form>
        </Box>
    </Container>
    );
    }