import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Grid, TextField, Radio, RadioGroup, FormControlLabel,Typography } from '@mui/material';
import { getValidAccessToken } from '../../../utils/tokenUtils';
import jsPDF from 'jspdf';
import InputMask from 'react-input-mask';
import autoTable from "jspdf-autotable";


const apiUrl = process.env.REACT_APP_API_URL;


const EntityInformationForm = ({ userType, ticketData, setTicketData }) => {
    const [entityDetails, setEntityDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [isVerified, setIsVerified] = useState(ticketData?.organizer_verified);
    const [successMessage, setSuccessMessage] = useState('');


    const [partnerDetailsForm, setPartnerDetailsForm] = useState(null);
    const [bookKeepingForm, setBookKeepingForm] = useState(() => {
        // Initialize all form fields with a default value of 0
        const initialValues = {};
        initialValues['total_a'] = ''; // Initialize as 0
        return initialValues;
    });
    const [balanceSheet, setBalanceSheet] = useState({});
    const [linkForm, setLinkForm] = useState({});
    const [homeBusinessDetails, setHomeBusinessDetails] = useState({
        usedForBusiness: '',
        expenses: []
    });
    useEffect(() => {
        const fetchEntityDetails = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'entity_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.entity_details;
                if (details) {
                    setEntityDetails(JSON.parse(details));
                }
            } catch (error) {
                console.error('Error fetching entity formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchEntityDetails();
    }, [ticketData.tax_organizer_id]);

    useEffect(() => {
        const fetchPartnerDetailsForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'shareholder_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.shareholder_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setPartnerDetailsForm(parsedDetails);
                   // setPartners(parsedDetails.partners || []);
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPartnerDetailsForm();
    }, [ticketData.tax_organizer_id]);

    useEffect(() => {
        const fetchbookKeepingForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'bookkeeping_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.bookkeeping_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setBookKeepingForm(parsedDetails);
                   // setVehicleInfo(parsedDetails.vehicleInfo || []);
                   // setBFormValues(parsedDetails.bFormValues || {});
                   // setCFormValues(parsedDetails.cFormValues || {});
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchbookKeepingForm();
    }, [ticketData.tax_organizer_id]);

    useEffect(() => {
        const fetchbalanceSheetForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'balance_sheet_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.balance_sheet_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setBalanceSheet(parsedDetails);
                   // setAssets(parsedDetails.assets || {});
                   // setLiabilities(parsedDetails.liabilities || {});
                 /*   totalValue1Assets=parsedDetails.totalValue1Assets;
                    totalValue2Assets=parsedDetails.totalValue2Assets;
                    totalValue1Liabilities=parsedDetails.totalValue1Liabilities;
                    totalValue2Liabilities=parsedDetails.totalValue2Liabilities;*/
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchbalanceSheetForm();
    }, [ticketData.tax_organizer_id]);

    useEffect(() => {
        const fetchbookKeepingForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'link_form_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.link_form_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setLinkForm(parsedDetails);
                   // setVehicleInfo(parsedDetails.vehicleInfo || []);
                    //setAutoExpense(parsedDetails.autoExpense || {});
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchbookKeepingForm();
    }, [ticketData.tax_organizer_id]);

    useEffect(() => {
        const fetchHomeBusinessDetails = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'home_expenses_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.home_expenses_details;
                if (details) {
                    setHomeBusinessDetails(JSON.parse(details));
                }
            } catch (error) {
                console.error('Error fetching home business details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchHomeBusinessDetails();
    }, [ticketData.tax_organizer_id]);

    const handleSubmit = async () => {
        try {
            setSuccessMessage('');

            const token = await getValidAccessToken();
            await axios.post(
                `${apiUrl}/update-taxorganizer-field/`,
                {
                    tax_organizer_id: ticketData.tax_organizer_id,
                    column_name: 'entity_details',
                    column_value: JSON.stringify(entityDetails),
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            //console.log('Form saved successfully');
            setSuccessMessage('updated successfully!');

        } catch (error) {
            console.error('Error saving form:', error);
            setSuccessMessage('Error updating form. Please try again.');
        }
    };

    const handleVerify = async () => {
        try {
            const token = await getValidAccessToken();
            const response = await axios.get(
                `${apiUrl}/verify-document/?ticket_id=${ticketData?.ticket_id}&column=organizer`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.data.success) {
                if (response.data.change_ticket_status) {
                    setTicketData((prevData) => ({ ...prevData, ticket_status: 'Under Tax Preparation' }));}
                setIsVerified(true);
                setTicketData({
                    ...ticketData,
                    organizer_verified: true,
                    organizer_verified_by: 'Agent Name',
                });
            }
        } catch (error) {
            console.error('Error verifying document:', error);
        }
    };

    const handleDownloadPdf = () => {

        const maxHeight = 280;
        const doc = new jsPDF('p', 'mm', 'a4');
        let y = 10;
        let headValue = 'SAVE TAX BUSINESS TAX ORGANIZER';
        let xOffset = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(headValue) / 2);
        doc.text(headValue, xOffset, y);

        var img = new Image();
        img.src = "favicon.png";

        doc.addImage(img, 10, 2, 25, 25);

        const pageWidth = doc.internal.pageSize.width - 30;
        const halfWidth = pageWidth * 0.5;
        const thiryWidth = pageWidth * 0.3;
        let rowdatabase = [];

        const ticketNumber = ticketData?.ticket_id || 'unknown';
        const filename = `Entity_Details_${ticketNumber}.pdf`;
        y = 30;

        doc.setFontSize(12);
        let rows = [];
        let pos = 0;
        // Loop through the entity details and add to PDF
        Object.keys(entityDetails).forEach((key) => {
            const label = key.replace(/_/g, ' ').toUpperCase();
            const value = entityDetails[key] || '';
           // doc.text(`${label}: ${value}`, 10, y);
            rows[pos] = [label,value];
            pos++;
            //y += 10;
        });

        autoTable(doc, {
            startY: y + 10, // Starting position for the first table
            head: [
                [{content: 'Entity Details', colSpan: 2}],
                ['Fields', 'Value']],
            body:rows,
            headStyles: {  halign: "center",
                fontStyle: "bold",
                textColor: 0,
                fillColor: [255, 255, 255],
                lineWidth: 0.2,
                lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:halfWidth
                    },
                1: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:halfWidth
                },
            }
        });

        y = doc.lastAutoTable.finalY + 5;
        let i = 0;
        //console.log("partnerDetailsForm : "+JSON.stringify(partnerDetailsForm));
        let partnersValues = JSON.parse(JSON.stringify(partnerDetailsForm));
        if (partnersValues.partners != null){
            partnersValues.partners.forEach(depentant => {
                let dependentdatabase = [];
                dependentdatabase.push(['Type of Shareholder or member or Partner', depentant.type_of_shareholder_or_member_or_partner]);
                dependentdatabase.push(['First Name', depentant.first_name]);
                dependentdatabase.push(['Middle Name', depentant.middle_name]);
                dependentdatabase.push(['Last Name', depentant.last_name]);

                dependentdatabase.push(['SSN', depentant.ssn_or_ein]);
                dependentdatabase.push(['Date Of Birth', depentant.date_of_birth]);

                dependentdatabase.push(['Address 1 ', depentant.address_line1]);
                dependentdatabase.push(['Address 2', depentant.address_line2]);
                dependentdatabase.push(['City', depentant.city]);
                dependentdatabase.push(['State', depentant.state]);
                dependentdatabase.push(['Phone No', depentant.phone_no]);
                dependentdatabase.push(['Email', depentant.email]);

                dependentdatabase.push(['Percentage of Ownership at start of Year', depentant.percentage_of_ownership_at_start_of_year]);
                dependentdatabase.push(['Percentage of Ownership at Transferred', depentant.percentage_of_ownership_at_transferred]);
                dependentdatabase.push(['Percentage of Ownership at End of Year', depentant.percentage_of_ownership_at_end_of_year]);
                dependentdatabase.push(['Guaranteed payments for Year', depentant.guaranteed_payments_for_year]);
                dependentdatabase.push(['Signing officer or partner or Member', depentant.signing_officer_or_partner_or_member]);


                autoTable(doc, {
                    startY: y + 20, // Starting position for the first table
                    head: [
                        [{content: 'Partner / Member / Shareholder Information', colSpan: 2}],
                        ['','']
                    ],
                    body: dependentdatabase,
                    headStyles: {
                        halign: "center",
                        fontStyle: "bold",
                        textColor: 0,
                        fillColor: [255, 255, 255],
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0]
                    },
                    columnStyles: {
                        0:
                            {
                                halign: "left",
                                valign: 'middle',
                                fontStyle: "bold",
                                fontSize: 11,
                                textColor: "#000",
                                cellPadding: 1,
                                lineWidth: 0.2,
                                lineColor: [0, 0, 0],
                                cellWidth:halfWidth
                            },
                        1: {
                            halign: "center",
                            valign: 'middle',
                            fontStyle: "bold",
                            fontSize: 9,
                            textColor: "#000",
                            cellPadding: 1,
                            lineWidth: 0.2,
                            lineColor: [0, 0, 0],
                            cellWidth:halfWidth
                        },

                    }
                });
                y = doc.lastAutoTable.finalY + 10;


                if (i >= 2) {
                    doc.addPage();
                    i = 0;
                    y = 0;
                }
                i++;
            });
        }

        y = doc.lastAutoTable.finalY + 5;

        let rowPageValues  = [
            ['Percentage Sharing Equal',partnerDetailsForm.percentage_sharing_equal],
            ['Non Contributing Partner Details',partnerDetailsForm.non_contributing_partner_details],

        ]

        autoTable(doc, {
            startY: y + 10, // Starting position for the first table
            head: [
            ],
            body:rowPageValues,
            headStyles: {  halign: "center",
                fontStyle: "bold",
                textColor: 0,
                fillColor: [255, 255, 255],
                lineWidth: 0.2,
                lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:thiryWidth
                    },
                1: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"70%"

                },

            }
        });

        y = doc.lastAutoTable.finalY + 5;

        rowPageValues  = [
            ["Do you use bookkeeping software, bookkeeping service, or have your own yearend Profit & Loss:",bookKeepingForm.is_bookingkeeping_available],

        ]

        autoTable(doc, {
            startY: y + 10, // Starting position for the first table
            head: [
            ],
            body:rowPageValues,
            headStyles: {  halign: "center",
                fontStyle: "bold",
                textColor: 0,
                fillColor: [255, 255, 255],
                lineWidth: 0.2,
                lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],

                    },
                1: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],

                },

            }
        });

        y = doc.lastAutoTable.finalY + 5;

        let rowsFormA = [];
        pos = 0;
        // Loop through the entity details and add to PDF


            const value = bookKeepingForm['total_a'] || '';
            // doc.text(`${label}: ${value}`, 10, y);
        rowsFormA[pos] = ['Total',value];

        autoTable(doc, {
            startY: y + 10, // Starting position for the first table
            head: [
                [{content: 'A. Gross Revenue ("Total Sales"; or "Income before Expenses")', colSpan: 2}],
             ],
            body:rowsFormA,
            headStyles: {  halign: "center",
                fontStyle: "bold",
                textColor: 0,
                fillColor: [255, 255, 255],
                lineWidth: 0.2,
                lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:halfWidth
                    },
                1: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:halfWidth
                },
            }
        });


        y = doc.lastAutoTable.finalY + 5;

        let rowsFormB = [];
        pos = 0;
        // Loop through the entity details and add to PDF

        Object.keys(bookKeepingForm.bFormValues).forEach((key) => {
            const label = key.replace(/_/g, ' ').toUpperCase();
            const value = bookKeepingForm.bFormValues[key] || '';
            rowsFormB[pos] = [label,value];
            pos++;
        });

        autoTable(doc, {
            startY: y + 10, // Starting position for the first table
            head: [
                [{content: 'B. Manufacturers/Sellers of Inventory Goods:', colSpan: 2}],
                ],
            body:rowsFormB,
            headStyles: {  halign: "center",
                fontStyle: "bold",
                textColor: 0,
                fillColor: [255, 255, 255],
                lineWidth: 0.2,
                lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:halfWidth
                    },
                1: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:halfWidth
                },
            }
        });

        doc.addPage('landscape');
        y = 0;
        i = 0;




        let rowsFormC = [];
        pos = 0;
        // Loop through the entity details and add to PDF
        let col = 0;
        let rowLabel = []
        let rowValue = []
        Object.keys(bookKeepingForm.cFormValues).forEach((key) => {
            const label = key.replace(/_/g, ' ').toUpperCase();
            const value = bookKeepingForm.cFormValues[key] || '';
            rowLabel.push(label);
            rowValue.push(value);
            if(col >=6) {
                rowsFormC.push( rowLabel);
                rowsFormC.push( rowValue);
                col = 0;
                rowLabel = [];
                rowValue = [];
            }
            col++;
        });

        autoTable(doc, {
            startY: y + 10, // Starting position for the first table
            head: [
                [{content: 'C. Business Expenses:', colSpan: 6}],
                [{content: 'Note: **Do not include expenses related to Primary Residence.', colSpan: 6}],
                ],
            body:rowsFormC,
            headStyles: {  halign: "center",
                fontStyle: "bold",
                textColor: 0,
                fillColor: [255, 255, 255],
                lineWidth: 0.2,
                lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],

                    },
                1: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],

                },
                2: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],

                },
                3: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],

                },
                4: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],

                },
                5: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],

                },

            }
        });

        y = doc.lastAutoTable.finalY + 5;

        rowPageValues  = [
            ['Taxable Profit/Loss: ( $A - $B - $C ):',bookKeepingForm.total_abc],
            ['D. Equipment, Leasehold Improvements, or other assets purchased* Total D:',bookKeepingForm.total_d],

        ]

        autoTable(doc, {
            startY: y + 10, // Starting position for the first table
            head: [
            ],
            body:rowPageValues,
            headStyles: {  halign: "center",
                fontStyle: "bold",
                textColor: 0,
                fillColor: [255, 255, 255],
                lineWidth: 0.2,
                lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],

                    },
                1: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],

                },

            }
        });

        y = doc.lastAutoTable.finalY + 5;

        let rowsFormD = [];
        pos = 0;
        // Loop through the entity details and add to PDF
        i = 0;
        let dFormValues = JSON.parse(JSON.stringify(bookKeepingForm));
        if (dFormValues.vehicleInfo != null) {
            dFormValues.vehicleInfo.forEach(partners => {
                pos = 0;
                Object.keys(partners).forEach((key) => {
                    const label = key.replace(/_/g, ' ').toUpperCase();
                    const value = partners[key] || '';
                    rowsFormD[pos] = [label, value];
                    pos++;
                });

                autoTable(doc, {
                    startY: y + 10, // Starting position for the first table
                    head: [

                       ],
                    body: rowsFormD,
                    headStyles: {
                        halign: "center",
                        fontStyle: "bold",
                        textColor: 0,
                        fillColor: [255, 255, 255],
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0]
                    },
                    columnStyles: {
                        0:
                            {
                                halign: "left",
                                valign: 'middle',
                                fontStyle: "bold",
                                fontSize: 9,
                                textColor: "#000",
                                cellPadding: 1,
                                lineWidth: 0.2,
                                lineColor: [0, 0, 0],
                                cellWidth: halfWidth
                            },
                        1: {
                            halign: "left",
                            valign: 'middle',
                            fontStyle: "bold",
                            fontSize: 9,
                            textColor: "#000",
                            cellPadding: 1,
                            lineWidth: 0.2,
                            lineColor: [0, 0, 0],
                            cellWidth: halfWidth
                        },
                    }
                });
                y = doc.lastAutoTable.finalY + 5;
                if (i >= 2) {
                    doc.addPage();
                    i = 0;
                    y = 0;
                }
                i++;
            })
        }

        doc.addPage();
        y =10;

        headValue = "If this business has revenue greater than $250,000:"+balanceSheet.is_gt_250k;
        xOffset =20;
      //  doc.text(headValue, xOffset, y);

        headValue ="Business Total Assets is more than $1,000,000 for Partnership,\n" +
            "S corporation and 10 million for corporation:"+balanceSheet.is_gt_1000k;
       // y = 20;
      //  doc.text(headValue, xOffset, y)
        let rowValues  = [
            ["If this business has revenue greater than $250,000:",balanceSheet.is_gt_250k],
            ["Business Total Assets is more than $1,000,000 for Partnership,\n" +
            "S corporation and 10 million for corporation:",balanceSheet.is_gt_1000k]
        ]

        autoTable(doc, {
            startY: y + 10, // Starting position for the first table
            head: [
            ],
            body: rowValues,
            headStyles: {  halign: "center",
                fontStyle: "bold",
                textColor: 0,
                fillColor: [255, 255, 255],
                lineWidth: 0.2,
                lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],

                    },
                1: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],

                },

            }
        });

        let rowsBalanceSheet = [];
        pos = 0;
        // Loop through the entity details and add to PDF
       // console.log(balanceSheet.assets)
       // console.log(balanceSheet.liabilities)
        balanceSheet.assets.forEach((key) => {
            //const label = key.replace(/_/g, ' ').toUpperCase();
            const name =key.name || '';
            const value1 =key.value1 || '';
            const value2 =key.value2 || '';
            rowsBalanceSheet[pos] = [name.replace(/_/g, ' ').toUpperCase(),value1,value2];
           // console.log( [name,value1,value2])
            pos++;
        });
        rowsBalanceSheet[pos] = ['Total',balanceSheet.totalValue1Assets || 0,balanceSheet.totalValue2Assets || 0]

        y = doc.lastAutoTable.finalY + 5;
        autoTable(doc, {
            startY: y + 10, // Starting position for the first table
            head: [
                [{content: 'Assets:', colSpan: 3}],
                ['Assets', 'Prior Year As of 12/31','Current Year As of 12/31']],
            body: rowsBalanceSheet,
            headStyles: {  halign: "center",
                fontStyle: "bold",
                textColor: 0,
                fillColor: [255, 255, 255],
                lineWidth: 0.2,
                lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],

                    },
                1: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],

                },
                2: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                },
            }
        });
        y = doc.lastAutoTable.finalY + 5;
        rowsBalanceSheet = [];
        pos = 0;
        // Loop through the entity details and add to PDF

        balanceSheet.liabilities.forEach((key) => {
            //const label = key.replace(/_/g, ' ').toUpperCase();
            const name =key.name || '';
            const value1 =key.value1 || '';
            const value2 =key.value2 || '';
            rowsBalanceSheet[pos] = [name.replace(/_/g, ' ').toUpperCase(),value1,value2];
           // console.log( [name,value1,value2])
            pos++;
        });
        rowsBalanceSheet[pos] = ['Total',balanceSheet.totalValue1Liabilities || 0,balanceSheet.totalValue2Liabilities || 0]


        autoTable(doc, {
            startY: y + 10, // Starting position for the first table
            head: [
                [{content: 'Liabilities:', colSpan: 3}],
                ['Liabilities', 'Prior Year As of 12/31','Current Year As of 12/31']],
            body: rowsBalanceSheet,
            headStyles: {  halign: "center",
                fontStyle: "bold",
                textColor: 0,
                fillColor: [255, 255, 255],
                lineWidth: 0.2,
                lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],

                    },
                1: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],

                },
                2: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],

                },
            }
        });

        doc.addPage();

        y = 0;

        let rowsLinks = [];
        pos = 0;
        // Loop through the entity details and add to PDF
        i = 0;
        let linkFormValues = JSON.parse(JSON.stringify(linkForm));
        if (linkFormValues.vehicleInfo != null) {
            linkFormValues.vehicleInfo.forEach(partners => {
                pos = 0;
                Object.keys(partners).forEach((key) => {
                    const label = key.replace(/_/g, ' ').toUpperCase();
                    const value = partners[key] || '';
                    rowsLinks[pos] = [label, value];
                    pos++;
                });

                autoTable(doc, {
                    startY: y + 10, // Starting position for the first table
                    head: [
                        [{
                            content: 'Vehicle Information:',
                            colSpan: 2
                        }],
                        ],
                    body:rowsLinks,
                    headStyles: {
                        halign: "center",
                        fontStyle: "bold",
                        textColor: 0,
                        fillColor: [255, 255, 255],
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0]
                    },
                    columnStyles: {
                        0:
                            {
                                halign: "left",
                                valign: 'middle',
                                fontStyle: "bold",
                                fontSize: 9,
                                textColor: "#000",
                                cellPadding: 1,
                                lineWidth: 0.2,
                                lineColor: [0, 0, 0],

                            },
                        1: {
                            halign: "left",
                            valign: 'middle',
                            fontStyle: "bold",
                            fontSize: 9,
                            textColor: "#000",
                            cellPadding: 1,
                            lineWidth: 0.2,
                            lineColor: [0, 0, 0],

                        },
                    }
                });
                y = doc.lastAutoTable.finalY + 5;
                if (i >= 2) {
                    doc.addPage();
                    i = 0;
                    y = 0;
                }
                i++;
            })
        }


        y = doc.lastAutoTable.finalY + 5;

        let rowsExpences = [];
        pos = 0;
        // Loop through the entity details and add to PDF
        i = 0;
        let expencesFormValues = JSON.parse(JSON.stringify(homeBusinessDetails));
        if (expencesFormValues.expenses != null) {
            expencesFormValues.expenses.forEach(partners => {
                pos = 0;
                Object.keys(partners).forEach((key) => {
                    const label = key.replace(/_/g, ' ').toUpperCase();
                    const value = partners[key] || '';
                    rowsExpences[pos] = [label, value];
                    pos++;
                });

                autoTable(doc, {
                    startY: y + 10, // Starting position for the first table
                    head: [
                        [{
                            content: 'Home and Office Expenses',
                            colSpan: 2
                        }],
                        ],
                    body:rowsExpences,
                    headStyles: {
                        halign: "center",
                        fontStyle: "bold",
                        textColor: 0,
                        fillColor: [255, 255, 255],
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0]
                    },
                    columnStyles: {
                        0:
                            {
                                halign: "left",
                                valign: 'middle',
                                fontStyle: "bold",
                                fontSize: 9,
                                textColor: "#000",
                                cellPadding: 1,
                                lineWidth: 0.2,
                                lineColor: [0, 0, 0],

                            },
                        1: {
                            halign: "left",
                            valign: 'middle',
                            fontStyle: "bold",
                            fontSize: 9,
                            textColor: "#000",
                            cellPadding: 1,
                            lineWidth: 0.2,
                            lineColor: [0, 0, 0],

                        },
                    }
                });
                y = doc.lastAutoTable.finalY + 5;
                if (i >= 2) {
                    doc.addPage();
                    i = 0;
                    y = 0;
                }
                i++;
            })
        }


        doc.save(filename);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEntityDetails({ ...entityDetails, [name]: value });
    };

    const renderFormFields = () => (
        <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={1}>
                {/* Line 1: Entity Type, Entity Name */}
                <Grid item xs={6}>
                    <RadioGroup
                        row
                        value={entityDetails.entity_type || ''}
                        onChange={(e) => setEntityDetails({ ...entityDetails, entity_type: e.target.value })}
                        disabled={userType !== 'user' || isVerified}
                    >
                        <FormControlLabel size="small" value="Multi Member LLC or or Partnership " control={<Radio />} label="Multi Member LLC / Partnership" />
                        <FormControlLabel size="small" value="S - Corporation" control={<Radio />} label="S - Corporation" />
                        <FormControlLabel size="small" value="Corporation" control={<Radio />} label="Corporation" />
                    </RadioGroup>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Entity Name"
                        inputProps={{ style: { fontSize: '16px' } }}
                        name="entity_name"
                        value={entityDetails.entity_name || ''}
                        onChange={handleInputChange}
                        disabled={userType !== 'user' || isVerified}
                        fullWidth
                        size="small"
                    />
                </Grid>

                {/* Line 2: DBA, Business Code, EIN */}
                <Grid item xs={4}>
                    <TextField
                        label="DBA"
                        inputProps={{ style: { fontSize: '16px' } }}
                        name="dba"
                        value={entityDetails.dba || ''}
                        onChange={handleInputChange}
                        disabled={userType !== 'user' || isVerified}
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Business Code"
                        inputProps={{ style: { fontSize: '16px' } }}
                        name="business_code"
                        value={entityDetails.business_code || ''}
                        onChange={handleInputChange}
                        disabled={userType !== 'user' || isVerified}
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={4}>
                  
                    <InputMask
                                mask="99-9999999"
                                value={entityDetails.ein || ''}
                                onChange={handleInputChange}
                                disabled={userType !== 'user'}
                            >
                                {(inputProps) => (
                                    <TextField
                                        {...inputProps}
                                        label="EIN"
                                        name="ein"
                                        inputProps={{ style: { fontSize: '16px' } }}
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            </InputMask>
                </Grid>

                {/* Line 3: State Register Number, Business Profession, Product, or Service */}
                <Grid item xs={6}>
                    <TextField
                        label="State Register Number"
                        inputProps={{ style: { fontSize: '16px' } }}
                        name="state_register_number"
                        value={entityDetails.state_register_number || ''}
                        onChange={handleInputChange}
                        disabled={userType !== 'user' || isVerified}
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Business Profession, Product, or Service"
                        inputProps={{ style: { fontSize: '16px' } }}
                        name="profession_service"
                        value={entityDetails.profession_service || ''}
                        onChange={handleInputChange}
                        disabled={userType !== 'user' || isVerified}
                        fullWidth
                        size="small"
                    />
                </Grid>

                {/* Line 4: Address */}
                <Grid item xs={3}>
                    <TextField
                        label="Street Address"
                        inputProps={{ style: { fontSize: '16px' } }}
                        name="street_address"
                        value={entityDetails.street_address || ''}
                        onChange={handleInputChange}
                        disabled={userType !== 'user' || isVerified}
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="City"
                        inputProps={{ style: { fontSize: '16px' } }}
                        name="city"
                        value={entityDetails.city || ''}
                        onChange={handleInputChange}
                        disabled={userType !== 'user' || isVerified}
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="State"
                        inputProps={{ style: { fontSize: '16px' } }}
                        name="state"
                        value={entityDetails.state || ''}
                        onChange={handleInputChange}
                        disabled={userType !== 'user' || isVerified}
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Zip Code"
                        inputProps={{ style: { fontSize: '16px' } }}
                        name="zip_code"
                        value={entityDetails.zip_code || ''}
                        onChange={handleInputChange}
                        disabled={userType !== 'user' || isVerified}
                        fullWidth
                        size="small"
                    />
                </Grid>

                {/* Line 5: Phone Number, Email */}
                <Grid item xs={6}>
                    <TextField
                        label="Business Phone Number"
                        inputProps={{ style: { fontSize: '16px' } }}
                        name="phone_number"
                        value={entityDetails.phone_number || ''}
                        onChange={handleInputChange}
                        disabled={userType !== 'user' || isVerified}
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Business Email Address"
                        inputProps={{ style: { fontSize: '16px' } }}
                        name="email_address"
                        value={entityDetails.email_address || ''}
                        onChange={handleInputChange}
                        disabled={userType !== 'user' || isVerified}
                        fullWidth
                        size="small"
                    />
                </Grid>
            </Grid>

            <Box mt={2}>
                {userType === 'user' && (
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        {entityDetails ? 'Update' : 'Submit'}
                    </Button>
                )}
                {successMessage && (
                <Typography variant="body1" color={successMessage.includes('Error') ? 'error' : 'success'}>
                    {successMessage}
                </Typography>
            )}
            </Box>
        </Box>
    );

    if (loading) return <Typography>Loading...</Typography>;

    return (
        <Box>
            <Box display="flex" justifyContent="flex-end" mb={2}>
                {userType !== 'user' && isVerified && (
                    <Button variant="contained" color="secondary" disabled>
                        Verified
                    </Button>
                )}
                {userType !== 'user' && !isVerified && (
                    <Button variant="contained" color="primary" onClick={handleVerify}>
                        Verify
                    </Button>
                )}
                {userType !== 'user'   && (
                    <Button variant="contained" color="primary" onClick={handleDownloadPdf} style={{ marginLeft: 10 }}>
                        Download PDF
                    </Button>
                )}
            </Box>
            {renderFormFields()}
        </Box>
    );
};

export default EntityInformationForm;
