import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import {MenuItem,InputLabel,Select,FormControl, Box, Button, Grid, TextField, Typography, TextareaAutosize, Radio, RadioGroup, FormControlLabel, IconButton } from '@mui/material';
import { getValidAccessToken } from '../../../utils/tokenUtils';
import jsPDF from 'jspdf';
import RemoveIcon from '@mui/icons-material/Remove';
import autoTable from "jspdf-autotable";

const apiUrl = process.env.REACT_APP_API_URL;

const RentalForm = ({ userType, ticketData, setTicketData }) => {
    const [rentalForm, setRentalForm] = useState({});
    const [loading, setLoading] = useState(true);
    const [rentalInfo, setRentalInfo] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');

    function toPascalCase(str) {
        return str.replace(/_/g, ' ')
            .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
                index === 0 ? match.toUpperCase() : match.toUpperCase()
            );
    }
    useEffect(() => {
        const fetchbookKeepingForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'rental_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.rental_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setRentalForm(parsedDetails);
                    setRentalInfo(parsedDetails.rentalInfo || []);
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchbookKeepingForm();
    }, [ticketData.tax_organizer_id]);

    const handleSubmit = async () => {
        try {
            setSuccessMessage('');
            const token = await getValidAccessToken();
            const response = await axios.post(
                `${apiUrl}/update-taxorganizer-field/`,
                {
                    tax_organizer_id: ticketData.tax_organizer_id,
                    column_name: 'rental_details',
                    column_value: JSON.stringify({ ...rentalForm, rentalInfo }),
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
           // console.log('Form saved successfully:', response.data);
            setSuccessMessage('Updated successfully!');
        } catch (error) {
            console.error('Error saving form:', error);
            setSuccessMessage('Error updating form. Please try again.');
        }
    };

    
    const handleDownloadPdf1= () => {
        const doc = new jsPDF();
        doc.text('Partner Information Details', 10, 10);
        Object.entries(rentalForm || {}).forEach(([key, value], index) => {

            doc.text(`${key}: ${value}`, 10, 20 + index * 10);
        });
        doc.save('partner_information_details.pdf');
    };

    const handleDownloadPdf = () => {
        const doc = new jsPDF();
        let y  = 10;
        let headValue ='Partner Information Details';
        let xOffset = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(headValue) / 2);
        doc.text(headValue, xOffset, y);

        var img = new Image();
        img.src = "favicon.png";

        doc.addImage(img, 10, 2, 25, 25);

        const pageWidth = doc.internal.pageSize.width - 30;
        const halfWidth = pageWidth * 0.5;


        let database = [];


        Object.entries(rentalForm || {}).forEach(([key, value], index) => {
           // console.log("Key"+JSON.stringify(key))
          //  console.log("Value"+JSON.stringify(value))
            let row = [
                key,
                value
            ]
            database.push(row);
        });
        y  = 20;
        let data = [];
        database.forEach(data => {
          //  console.log("Data Array Rows : "+data);
            let headValue =  data[0].toUpperCase();
            let xOffset = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(headValue) / 2);
            doc.text(headValue, xOffset, y);

            let rows = [];
            let pos = 0;
            let rowData =JSON.parse(JSON.stringify(data[1]));
            const dataArray = Array.isArray(rowData) ? rowData : Object.entries(rowData);

            dataArray.forEach(item => {

                const rowArray = Array.isArray(rowData) ? rowData : Object.entries(item);

              rowArray.forEach(values => {
                 // console.log("Data Array Rows : "+item[0] + " : "+item[1]);
                  rows[pos] = [item[0],item[1]];
                    pos++;
                });

            });

            // console.log("Data Array : "+rows);
            autoTable(doc, {
                startY: y + 10, // Starting position for the first table
                head: [['Fields', 'Value']],
                body:rows,
                headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
                columnStyles: {
                    0:
                        {
                            halign: "left",
                            valign: 'middle',
                            fontStyle: "bold",
                            fontSize: 11,
                            textColor: "#000",
                            cellPadding: 1,
                            lineWidth: 0.2,
                            lineColor: [0, 0, 0],
                            cellWidth:halfWidth
                        },
                    1: {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:halfWidth
                    },
                }
            });
            y = doc.lastAutoTable.finalY + 10;
        });
        doc.save('partner_information_details.pdf');
    };

    const rentalFields = [
        { name: 'property_type', type: 'radio', options: ['Residental', 'Commercial'] },
        { name: 'no_of_months_rented', type: 'dropdown', options: ['01', '02','03', '04','05', '06','07', '08','09', '10','11', '12'] },
        { name: 'no_of_months_used_for_personal_user', type: 'dropdown', options: ['01', '02','03', '04','05', '06','07', '08','09', '10','11', '12'] },
        { name: 'property_owned_by', type: 'radio', options: ['Taxpayer', 'Spouse', 'Joint'] },

        { name: 'cost_price_of_property_at_the_time_of_purchase', type: 'textbox' },
        { name: 'date_of_purchase', type: 'date' },
        { name: `rental_income_received_during_${ticketData.service_year_value}`, type: 'textbox' },
        { name: 'address_of_the_property', type: 'textarea' },
        { name: `expenses_incurred_on_the_property_during_${ticketData.service_year_value}`, type: 'textarea', typography:["Please maintain the bills for future refrence","Note :You can Claim the following expenses incurred on the property Advertising, Auto and travel, Cleaning and maintenance Commissions, Insurance, Legal and other professional fees, Management fees, Other interest, Repairs, Supplies, Utilitie"] },
    ];

    const handleAddRental = () => {
        setRentalInfo([...rentalInfo,
        {
            property_type: '',
            no_of_months_rented: '',
            no_of_months_used_for_personal_user: '',
            property_owned_by: '',
            cost_price_of_property_at_the_time_of_purchase: '',
            date_of_purchase: '',
            [`rental_income_received_during_${ticketData.service_year_value}`]: '',
            address_of_the_property: '',
            [`expenses_incurred_on_the_property_during_${ticketData.service_year_value}`]: '',
        }
        ]);
    };

    const handleRemoveRental = (index) => {
        setRentalInfo(rentalInfo.filter((_, i) => i !== index));
    };

    const handleRentalChange = (index, field, value) => {
        const updatedPartners = [...rentalInfo];
        updatedPartners[index] = { ...updatedPartners[index], [field]: value };
        setRentalInfo(updatedPartners);
    };




    const renderVehicleForm = () => (
        <>
            {rentalInfo.map((partner, index) => (
                <Box key={index} mb={0}>
                    <Grid container spacing={1} >
                        {rentalFields.map((field) => {
                            const isFullWidthField = field.type === 'radio' || field.type === 'textarea' || field.type === 'dropdown';
                            return (
                                <Grid item xs={15} sm={isFullWidthField ? 12 : 4} key={field.name}>
                                    {field.type === 'textbox' ? (
                                        <TextField
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name]}
                                            onChange={(e) => handleRentalChange(index, field.name, e.target.value)}
                                            fullWidth
                                            size="small"
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'number' ? (
                                        <TextField
                                            type="number"
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name]}
                                            onChange={(e) => handleRentalChange(index, field.name, e.target.value)}
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'date' ? (
                                        <TextField
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name] || ''}
                                            onChange={(e) => handleRentalChange(index, field.name, e.target.value)}
                                            fullWidth
                                            type="date"
                                            size="small"
                                            disabled={userType !== 'user'}
                                            InputLabelProps={{
                                                shrink: true, // Keep the label above the input
                                            }}
                                        />
                                    ) : field.type === 'email' ? (
                                        <TextField
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name]}
                                            onChange={(e) => handleRentalChange(index,field.name, e.target.value)}
                                            fullWidth
                                            type="email"
                                            size="small"
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'radio' ? (
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Typography variant="body1" sx={{ fontSize: '14px', display: 'inline' }}>
                                                    {toPascalCase(field.name)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <RadioGroup
                                                    row
                                                    value={partner[field.name]}
                                                    onChange={(e) => handleRentalChange(index,field.name, e.target.value)}
                                                >
                                                    {field.options.map((option) => (
                                                        <FormControlLabel
                                                            key={option}
                                                            value={option}
                                                            control={<Radio sx={{ transform: 'scale(0.8)' }} />} // Scale down the radio button
                                                            label={option}
                                                            disabled={userType !== 'user'}
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {
                                                                    fontSize: '0.8rem', // Adjust the label font size
                                                                },
                                                            }}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    ) : field.type === 'textarea' ? (
                                        <TextareaAutosize
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            minRows={3}
                                            placeholder={toPascalCase(field.name)}
                                            value={partner[field.name]}
                                            onChange={(e) => handleRentalChange(index,field.name, e.target.value)}
                                            style={{ width: '100%', fontSize: '16px', padding: '8px' }}
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'dropdown' ? (
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Typography variant="body1" sx={{ fontSize: '14px', display: 'inline' }}>
                                                    {toPascalCase(field.name)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <FormControl fullWidth variant="outlined" size="small" disabled={userType !== 'user'}>
                                                    <InputLabel>{toPascalCase(field.name)}</InputLabel>
                                                    <Select
                                                        value={partner[field.name]}
                                                        onChange={(e) => handleRentalChange(index, field.name, e.target.value)}
                                                        label={toPascalCase(field.name)}
                                                    >
                                                        {field.options.map((option) => (
                                                            <MenuItem key={option} value={option} sx={{ fontSize: '0.8rem' }}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    ) :null}
                                </Grid>
                            );
                        })}
                        <Grid item xs={12} mb={0} mt={0}>
                            <IconButton onClick={() => handleRemoveRental(index)}>
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            ))}
            <Button variant="outlined" color="primary" onClick={handleAddRental} disabled={userType !== 'user'} >
                Add Rental
            </Button>
        </>
    );




    const renderFormFields = () => (
        <Box mt={0} >
            <Grid item xs={12}>
                {renderVehicleForm()}
                <Grid item xs={1} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}
            </Grid>

            {/* Render Partners */}

            {/* Entity Type */}

            {(userType === 'user' || userType === 'admin') && (
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {rentalForm ? 'Update' : 'Submit'}
                </Button>
            )}
            {successMessage && (
                <Typography color="success">{successMessage}</Typography>
            )}
        </Box>
    );

    if (loading) return <Typography>Loading...</Typography>;

    return (
        <Box p={0}>
            {userType !== 'user' ? (
                <>
                    {!rentalForm ? (
                        <Typography>Details are not provided</Typography>
                    ) : (
                        <>
                            {renderFormFields()}
                        </>
                    )}
                </>
            ) : (
                renderFormFields()
            )}
        </Box>
    );
};

export default RentalForm;
