// src/components/Login.js
import React, {useEffect, useState} from 'react';
import { Container, TextField, Button, Typography, Box, Alert, InputAdornment, IconButton, Snackbar } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {getUserType, getValidAccessToken, handleLogout, storeTokens} from '../utils/tokenUtils';
import useAuth from '../hooks/useAuth'; // Import the custom hook
import { useLocation } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Loader from '../utils/Loader';
const apiUrl = process.env.REACT_APP_API_URL;


const Login = () => {
    useAuth();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();


    const [permissionData, setPermissionData] = useState(0);
    const userType = getUserType(); // Get user type from token or authentication context

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

        const fetchData = async () => {

            const token = await getValidAccessToken();
            if (token) {
                try {
                    const ticketsResponse = await axios.get(`${apiUrl}/user-menus?user_type=agent}`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    let permissionData = [];
                    ticketsResponse.data.map(item => {
                            permissionData.push(item.menus);
                        }
                    );
                    localStorage.setItem("permission",permissionData);
                    setPermissionData(permissionData)

                } catch (error) {
                    console.log('Error fetching data:', error);
                }
            } else {
                handleLogout();
            }
        };



    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/login/`, {
                username: email,
                password,
            });
            if (response.data.success) {
                const { access_token, refresh_token, username,user_type } = response.data;
                storeTokens(access_token, refresh_token,username,user_type);
                //console.log("User Type : "+user_type)
                setMessage(response.data.message);
                setErrorMessage('');
                // Add a slight delay to ensure tokens are set before navigation
                setTimeout(() => {
                     navigate('/home');
                }, 500);

            } else {
                setErrorMessage(response.data.message || 'Invalid email or password');
                setMessage('');
            }
        } catch (error) {
            console.error('Login error:', error);
            setErrorMessage(error.response.data.message || 'An error occurred. Please try again.');
            setMessage('');
        }
    };

    return (
        <Container maxWidth="xs">
            <Box sx={{ mt: 5, mb: 5 }}>
                <Typography variant="h4" align="center" gutterBottom>
                    Login
                </Typography>
                
                <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
                <Snackbar open={!!message} autoHideDuration={6000} onClose={() => setMessage('')}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
                {loader && <Loader />}
                <form onSubmit={handleLogin}>
                    <TextField
                        label="Email/Username/Phone"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        inputProps={{ style: { fontSize: '16px' } }}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        type={showPassword ? 'text' : 'password'}
                        inputProps={{ style: { fontSize: '16px' } }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={togglePasswordVisibility}
                                        edge="end"
                                        aria-label="toggle password visibility"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        <a href="/forgot-password">Forgot password?</a>
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        sx={{ mt: 3 }}>
                        Login
                    </Button>
                </form>
            </Box>
        </Container>
    );
};

export default Login;
