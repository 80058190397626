// src/components/Home.js
import React, {useEffect, useState} from 'react';
import { Container } from '@mui/material';
import useAuth from '../hooks/useAuth';
import {getUserType, getValidAccessToken, handleLogout} from '../utils/tokenUtils';
import UserView from './UserHomeView';
import AgentView from './AgentHomeView';
import {AdminHomeView} from "./AdminHomeView";
import {SupportHomeView} from "./SupportHomeView";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const Home = () => {
    useAuth(); // Ensure authentication is handled

    const userType = getUserType(); 

 
    return (
        <Container  style={{  margin: '0' }}>
           {/*{userType !== 'admin' ? <AdminHomeView /> : userType === 'support' ? <SupportHomeView  /> : userType !== 'user' ? <AgentView  /> : <UserView  />} */} 
           {userType !== 'user' ? <AdminHomeView userType={userType} />  : <UserView  />}
        </Container>
    );
};

export default Home;
