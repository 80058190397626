import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, get, child, update, remove } from "firebase/database";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
};


const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

// Function to write user data to the database
export const writeUserData = (userId, name, email, imageUrl) => {
    set(ref(database, 'users/' + userId), {
      username: name,
      email: email,
      profile_picture: imageUrl
    })
    .then(() => {
      console.log("Data written successfully!");
    })
    .catch((error) => {
      console.error("Error writing data: ", error);
    });
  };
  
  // Function to read user data from the database
  export const readUserData = async (userId) => {
    const dbRef = ref(database);
    try {
      const snapshot = await get(child(dbRef, `users/${userId}`));
      if (snapshot.exists()) {
        console.log(snapshot.val()); // Data found
        return snapshot.val();
      } else {
        console.log("No data available for this user.");
        return null;
      }
    } catch (error) {
      console.error("Error reading data: ", error);
      return null;
    }
  };

