import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, Button, Grid, Paper, Dialog, DialogTitle, DialogContent } from '@mui/material';
import axios from 'axios';
import { getValidAccessToken } from '../../utils/tokenUtils';
import { writeUserData, readUserData } from '../../firebase'; // Assuming firebase.js is in the correct path
import { getDatabase, ref, set, push, Database, onValue } from 'firebase/database';


const apiUrl = process.env.REACT_APP_API_URL;

const MessagesTab = ({ ticketId, userType, ticketData, customerData }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const messagesEndRef = useRef(null);

    const [searchResults, setSearchResults] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [notifications, setNotifications] = useState([]);
    const [isNotificationDialogOpen, setIsNotificationDialogOpen] = useState(false);
    const database = getDatabase();  // Ensure you're using the correct database instance

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleSendMessage = async (messageType = "Public") => {
        if (!newMessage.trim()) return; // Prevent sending empty messages

        const token = await getValidAccessToken();
        try {
            const response = await axios.post(`${apiUrl}/tickets/messages/`, {
                ticket_id: ticketId,
                message_content: newMessage,
                sender_type: userType,
                message_type: messageType
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data.success) {
                const messagesRef = ref(database, 'messages/' + ticketId);
                await push(messagesRef, {
                    ticket_id: ticketId,
                    message_content: newMessage,
                    sender_type: userType,
                    message_type: messageType,
                    timestamp: new Date().toISOString(),
                });
                setMessages([...messages, response.data.data]);
                setNewMessage('');
                scrollToBottom();
            } else {
                setErrorMessage('Unable to send message.');
            }
        } catch (error) {
            setErrorMessage('Error sending message.');
            console.error('Error sending message:', error);
        }
    };

    useEffect(() => {
        const fetchMessages = async () => {
            const token = await getValidAccessToken();
            try {
                const response = await axios.get(`${apiUrl}/tickets/messages/?ticket_id=${ticketId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                if (response.data.success) {
                    // Real-time updates using Firebase Realtime Database
                    const messagesRef = ref(database, 'messages/' + ticketId);
                    const unsubscribe = onValue(messagesRef, (snapshot) => {
                        const data = snapshot.val();
                        const fetchedMessages = data ? Object.keys(data).map(key => ({
                            id: key,
                            ...data[key]
                        })) : [];
                        setMessages(fetchedMessages);
                        scrollToBottom();
                    });
            
                
                    setMessages(response.data.data);
                    scrollToBottom();
                    return () => unsubscribe(); 
                } else {
                    setErrorMessage('Unable to fetch messages.');
                }
            } catch (error) {
                setErrorMessage('Error fetching messages.');
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();
    }, [ticketId]);

    useEffect(() => {
        if (userType !== 'user') {
            const fetchUserInfo = async () => {
                try {
                    const token = await getValidAccessToken();
                    const response = await axios.get(`${apiUrl}/users/search/`, {
                        params: {
                            username: ticketData?.username
                        },
                        headers: { 'Authorization': `Bearer ${token}` },
                    });

                    if (response.data.length === 0) {
                        setErrorMessage('No users found matching the criteria.');
                    } else {
                        setSearchResults(response.data);
                        setErrorMessage(null);
                    }
                } catch (error) {
                    console.error('Error fetching user details:', error);
                }
            };

            fetchUserInfo();
        }
    }, [ticketId]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage("Public");
        }
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString(); // Formats the timestamp into a readable date and time
    };

    const handleFetchTickets = async () => {
        try {
            const token = await getValidAccessToken();
            const response = await axios.get(`${apiUrl}/user-tickets/`, {
                params: { username: customerData.username },
                headers: { 'Authorization': `Bearer ${token}` },
            });

            setTickets(response.data);
            setIsDialogOpen(true); // Open the dialog to display tickets
        } catch (error) {
            console.error('Error fetching tickets:', error);
            setErrorMessage('Error fetching tickets.');
        }
    };

    const handleFetchNotifications = async () => {
        try {
            const token = await getValidAccessToken();
            const response = await axios.get(`${apiUrl}/ticket-notification-messages/?ticket_id=${ticketId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            if (response.data.success) {
                setNotifications(response.data.data);
                setIsNotificationDialogOpen(true); // Open the dialog to display notifications
            } else {
                setErrorMessage('Unable to fetch notifications.');
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
            setErrorMessage('Error fetching notifications.');
        }
    };

    const handleCloseDialog = () => { 
        setIsDialogOpen(false); 
    };

    const handleCloseNotificationDialog = () => { 
        setIsNotificationDialogOpen(false); 
    };
    return (
        <Grid container spacing={2}>
            {/* Left side: Chat box */}
            <Grid item xs={(userType !== 'user') ? 8 : 12}>
                <Box>
                    {errorMessage && <Typography variant="body2" color="error">{errorMessage}</Typography>}
                    
                    <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: 1, mb: 2, maxHeight: '400px', overflowY: 'auto' }}>
                        {messages.length > 0 ? (
                            messages.map((message) => (
                                <Box key={message.ticket_message_id} sx={{ mb: 1 }}>
                                    {userType !== 'user' && (
                                        <Box sx={{ display: 'flex', justifyContent: (message.sender_type === 'user' ? 'flex-start' : 'flex-end') }}>
                                            <Typography variant="caption" color="textSecondary" sx={{ fontWeight: 'bold' }}>
                                                {message.sender_type === 'user' ? message.username : message.agentname}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box sx={{ display: 'flex', justifyContent: (userType === 'user' ? (message.sender_type === 'user' ? 'flex-end' : 'flex-start') : (message.sender_type === 'user' ? 'flex-start' : 'flex-end')) }}>
                                        <Typography variant="body2" sx={{ maxWidth: '70%', wordWrap: 'break-word', p: 1, backgroundColor: message.sender_type === 'user' ? '#d1ecf1' : '#f8d7da', borderRadius: 1 }}>
                                            {message.message_content}
                                            <Typography variant="caption" sx={{ display: 'block', fontSize: '0.75rem', color: '#888' }}>
                                                {formatTimestamp(message.timestamp)}
                                            </Typography>
                                            {message.message_type === "Private" && (
                                                <Typography variant="caption" sx={{ display: 'block', fontStyle: 'italic', fontSize: '0.75rem', color: '#ff5722', fontWeight: 'bold' }}>
                                                    (Sent Internally)
                                                </Typography>
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))
                        ) : <Typography variant="body2">No messages found.</Typography>}
                        <div ref={messagesEndRef} />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyPress={handleKeyPress}
                            placeholder="Type your message..."
                            inputProps={{ style: { fontSize: '16px' } }}
                        />
                        <Button variant="contained" onClick={() => handleSendMessage("Public")}>Send</Button>
                        {userType !== 'user' && <Button variant="contained" onClick={() => handleSendMessage("Private")}>Send Internally</Button>}
                    </Box>
                </Box>
            </Grid>

            {/* Right side */}
            {(userType !== 'user') && (
                <Grid item xs={4}>
                    {/* Fetch Tickets Button */}
                    <Button variant="contained" onClick={handleFetchTickets} sx={{ mb: 2 }}>Fetch User Tickets</Button>

                    {/* Ticket Notifications Button */}
                    <Button variant="contained" onClick={handleFetchNotifications} sx={{ mb: 2 }}>Ticket Notification</Button>

                    {/* User Details */}
                    <Paper elevation={3} sx={{ p: 2, mb: 2, border: '1px solid #ccc', borderRadius: 1, backgroundColor: '#f8d7da' }}>
                        <Typography variant="h6" color="#333333"><strong>User Details:</strong></Typography>
                        {customerData ? (
                            <Box>
                                <Typography color="#333333"><strong>Name:</strong> {customerData.first_name} {customerData.last_name}</Typography>
                                <Typography color="#333333"><strong>Username:</strong> {customerData.username}</Typography>
                                <Typography color="#333333"><strong>Email:</strong> {customerData.email}</Typography>
                                <Typography color="#333333"><strong>Phone:</strong> {customerData.phone_name}</Typography>
                            </Box>
                        ) : (
                            <Typography>No user details available.</Typography>
                        )}
                    </Paper>

                    {/* Reason */}
                    {ticketData && (
                        <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: 1, backgroundColor: '#f8d7da' }}>
                            <Typography variant="body2" color="#333333">
                                <strong>Reason:</strong> {ticketData.reason}
                            </Typography>
                        </Box>
                    )}
                </Grid>
            )}

            {/* Tickets Dialog */}
            <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>User Tickets</DialogTitle>
                <DialogContent>
                    {tickets.length > 0 ? (
                        tickets.map((ticket) => (
                            <Box key={ticket.ticket_id} sx={{ mb: 2, p: 1, border: '1px solid #ccc', borderRadius: 1 }} onClick={() => window.open(`/tickets/${ticket.ticket_id}`, '_blank')}>
                                <Typography><strong>Ticket ID:</strong> {ticket.ticket_id}</Typography>
                                <Typography><strong>Service:</strong> {ticket.service_name}</Typography>
                                <Typography><strong>Status:</strong> {ticket.ticket_status}</Typography>
                                <Typography><strong>Reason:</strong> {ticket.reason}</Typography>
                                <Typography><strong>Tax Year:</strong> {ticket.service_year_value}</Typography>
                                <Typography><strong>View Ticket:</strong> {ticket.ticket_id}</Typography>
                            </Box>
                        ))
                    ) : (
                        <Typography>No tickets available.</Typography>
                    )}
                </DialogContent>
            </Dialog>

            {/* Notifications Dialog */}
            <Dialog open={isNotificationDialogOpen} onClose={handleCloseNotificationDialog}>
                <DialogTitle>Ticket Notifications</DialogTitle>
                <DialogContent>
                    {notifications.length > 0 ? (
                        notifications.map((notification) => (
                            <Box key={notification.notification_id} sx={{ mb: 2 }}>
                                <Typography><strong>Notification:</strong> {notification.message_content}</Typography>
                                <Typography><strong>Sender:</strong> {notification.sender_type}</Typography>
                              {/*  <Typography><strong>Status:</strong> {notification.message_status}</Typography> */}
                                <Typography><strong>Timestamp:</strong> {formatTimestamp(notification.timestamp)}</Typography>
                            </Box>
                        ))
                    ) : (
                        <Typography>No notifications available.</Typography>
                    )}
                </DialogContent>
            </Dialog>

        </Grid>
    );
};

export default MessagesTab;
