import React, { useEffect, useState, useCallback } from 'react';
import {
    Typography,
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    IconButton
} from '@mui/material';
import axios from 'axios';
import { getValidAccessToken } from '../../utils/tokenUtils';
import TicketData from '../TicketData';
import DeleteIcon from '@mui/icons-material/Delete';
import { Download, FileOpen } from "@mui/icons-material";


const apiUrl = process.env.REACT_APP_API_URL;


const MyDocumentsTab = ({ ticketId, userType, ticketData, setTicketData }) => {
    const [fileUrl, setFileUrl] = useState(null);
    const [files, setFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [uploading, setUploading] = useState(false);
    const [isDocumentVerified, setIsDocumentVerified] = useState(ticketData.document_verified);

    const fetchFiles = useCallback(async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.get(`${apiUrl}/ticket-files/list/?ticket_id=${ticketId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.success) {
                setFiles(response.data.data);
            } else {
                setErrorMessage('Unable to fetch files.');
            }
        } catch (error) {
            setErrorMessage('Error fetching files.');
            console.error('Error fetching files:', error);
        }
    }, [ticketId]);

    useEffect(() => {
        fetchFiles();
    }, [fetchFiles]);

    const handleFileUpload = async (event) => {
        const token = await getValidAccessToken();
        const files = event.target.files; // Get the selected files
        if (!files || files.length === 0) return;
    
        setUploading(true);
    
        try {
            // Iterate over each selected file and upload one by one
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
    
                const formData = new FormData();
                formData.append('file', file);
                formData.append('ticket_id', ticketId);
                formData.append('file_name', file.name);
    
                const saveResponse = await axios.post(`${apiUrl}/ticket-files/`, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });
    
                if (!saveResponse.data.success) {
                    setErrorMessage('Unable to save file details.');
                    break; // Exit loop if any file upload fails
                }
            }
        } catch (error) {
            setErrorMessage('Error uploading file.');
            console.error('Error uploading file:', error);
        } finally {
            setUploading(false);
            await fetchFiles(); // Re-fetch files after a successful upload

        }
    };
    
    const handleView = async (fileId, fileName) => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.get(`${apiUrl}/ticket-file-download/${fileId}/?ticket_id=${ticketId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
                responseType: 'blob',  // Make
            });
            const contentDisposition = response.headers.get('Content-Disposition');
            //  console.log("Response : "+response.headers)
            const blob = response.data;
            const blobUrl = URL.createObjectURL(blob);

            window.open(blobUrl, '_blank');

        } catch (error) {
            setErrorMessage('Error verifying document.');
            console.error('Error verifying document:', error);
        }
    };
    const handleDownload = async (fileId, fileName) => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.get(`${apiUrl}/ticket-file-download/${fileId}/?ticket_id=${ticketId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
                responseType: 'blob',  // Make
            });
            const contentDisposition = response.headers.get('Content-Disposition');
            // console.log("Response : "+response.headers)
            //let fileName = 'defaultFileName.pdf';
            if (contentDisposition && contentDisposition.includes('filename=')) {
                fileName = contentDisposition
                    .split('filename=')[1]
                    .replace(/"/g, ''); // Remove quotes around filename if present
            }

            const blob = response.data;
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Set file name
            document.body.appendChild(link);
            link.click();  // Trigger the download
            link.parentNode.removeChild(link); // Clean up
            window.URL.revokeObjectURL(url); // Release URL object
        } catch (error) {
            setErrorMessage('Error verifying document.');
            console.error('Error verifying document:', error);
        }
    };

    const handleDelete = async (fileId) => {
        const token = await getValidAccessToken();
        try {

            const response = await axios.get(`${apiUrl}/ticket-file-delete/${fileId}/?ticket_id=${ticketId}`, {
                headers: { 'Authorization': `Bearer ${token}` },

            });
            //  console.log("Response : "+JSON.stringify(response))
            if (response.data.success) {
                await fetchFiles();
            } else {
                setErrorMessage('Unable to Delete file details.');
            }
            // const updatedRows = files.filter(row => row.file_id !== fileId);
            //setFiles(updatedRows);  // Update the state
            // await fetchFiles();

        } catch (error) {
            setErrorMessage('Error verifying document.');
            console.error('Error verifying document:', error);
        }
    };
    const handleVerify = async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.get(`${apiUrl}/verify-document/?ticket_id=${ticketId}&column=document`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.success) {
                if (response.data.change_ticket_status) {

                setTicketData((prevData) => ({ ...prevData, ticket_status: 'Under Tax Preparation' }));}

                // console.log('Ticket document verified successfully');
                setIsDocumentVerified(true); // Update the document verification status
            } else {
                setErrorMessage('Unable to verify document.');
            }
        } catch (error) {
            setErrorMessage('Error verifying document.');
            console.error('Error verifying document:', error);
        }
    };

    if (!ticketData) {
        return <div>Loading...</div>; // Or any loading indicator
    }

    return (
        <Box>
            {errorMessage && (
                <Typography variant="body2" color="error">{errorMessage}</Typography>
            )}

            <Paper
                sx={{
                    p: 2,
                    mt: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                }}>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0 }}>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
                        <Typography style={{ paddingRight: '10px' }} variant="body2">Document Status:</Typography>
                        {isDocumentVerified ? (
                            <Button sx={{ fontWeight: 'bold' }} variant="contained" color="secondary" disabled>
                                Verified
                            </Button>
                        ) : (userType !== 'user' ? (
                            <Button variant="contained" color="primary" onClick={handleVerify}>
                                Verify
                            </Button>
                        ) : (
                            <Typography variant="body2" color="red" >Not Verified</Typography>
                        ))}
                    </Box>

                    {userType === 'user' && (
                        <Box sx={{ display: 'flex', justifyContent: 'right', mb: 0 }}>
                            <input
                                type="file"
                                onChange={handleFileUpload}
                                style={{ display: 'none' }}
                                id="upload-file"
                                multiple
                            />
                            <label htmlFor="upload-file" style={{ cursor: 'pointer' }}>
                                <Box
                                    sx={{
                                        border: '2px dashed #1976d2',
                                        borderRadius: '8px',
                                        padding: '30px',
                                        width: '400px',
                                        height: '100px',
                                        textAlign: 'center',
                                        backgroundColor: '#f9f9f9',
                                        color: '#1976d2',
                                        '&:hover': {
                                            backgroundColor: '#e3f2fd',
                                            borderColor: '#1565c0',
                                        }
                                    }}
                                >
                                    {uploading ? 'Uploading...' : 'Upload/Drop files here'}
                                </Box>
                            </label>
                        </Box>

                    )}
                </Box>

                {files.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table size="small" sx={{ minWidth: 650 }} aria-label="files table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem' }}>File ID</TableCell>
                                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem' }}>Filename</TableCell>
                                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem' }}>Uploaded On</TableCell>
                                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {files.map((file, index) => (
                                    <TableRow key={file.file_id}>
                                        <TableCell sx={{ fontSize: '0.875rem' }}>{index + 1}</TableCell>
                                        <TableCell sx={{ fontSize: '0.875rem' }}>
                                            <Typography >

                                                {file.file_name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ fontSize: '0.875rem' }}>
                                            {new Date(file.upload_date).toLocaleString()}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: '0.875rem' }}>
                                            <IconButton onClick={() => handleView(file.file_id, file.file_name)} >
                                                <FileOpen color="success" />
                                            </IconButton>
                                            <IconButton onClick={() => handleDownload(file.file_id, file.file_name)} >
                                                <Download color="success" />
                                            </IconButton>
                                            {userType === 'user' && (
                                                <IconButton onClick={() => handleDelete(file.file_id)} >
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {fileUrl && (
                            <iframe
                                src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                                style={{ width: "100%", height: "600px" }}
                                title="Google Docs Viewer"
                            ></iframe>
                        )}
                    </TableContainer>
                ) : (
                    <Typography variant="body2">No files found for this ticket.</Typography>
                )}
            </Paper>
        </Box>
    );
};

export default MyDocumentsTab;
