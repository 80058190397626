import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Alert,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { getValidAccessToken } from "../utils/tokenUtils"; // You can reuse this to get the user's access token
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { CopyAll } from "@mui/icons-material";
import { styled } from "@mui/system"; // Import from @mui/system
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Add this line to include the styles

const apiUrl = process.env.REACT_APP_API_URL;

const ReferAndEarn = () => {
  const [referralDataCount, setReferralDataCount] = useState();
  const [username, setUsername] = useState();
  const [referralDetails, setReferralDetails] = useState([]); // Data for referrals (username, email, signup date)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Number of referrals to show per page
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const user_type = localStorage.getItem("user_type");

  // Styled components using @mui/system
  const ReferralSection = styled(Box)(({ theme }) => ({
    backgroundColor: "#f0f4f8",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    marginTop: "20px",
  }));

  const Heading = styled(Typography)(({ theme }) => ({
    fontSize: "1.8rem",
    fontWeight: "bold",
    color: "#ff4700",
    textAlign: "center",
    marginBottom: "10px",
  }));

  const Message = styled(Typography)(({ theme }) => ({
    fontSize: "1.2rem",
    color: "#333",
    textAlign: "center",
    marginBottom: "20px",
    lineHeight: "1.6",
  }));

  const CopyButton = styled(IconButton)(({ theme }) => ({
    marginLeft: "10px",
    color: "#ff4700",
    "&:hover": {
      backgroundColor: "#f8f8f8",
    },
  }));

  useEffect(() => {
    const fetchReferralData = async () => {
      const token = await getValidAccessToken();
      if (token) {
        try {
          setUsername(jwtDecode(token).username); // Get username from token
          const referralDetailsResponse = await axios.get(
            `${apiUrl}/referral-details/`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setReferralDataCount(
            referralDetailsResponse.data.referal_data.length || 0
          ); // Contains referral link and successful referrals count
          setReferralDetails(referralDetailsResponse.data.referal_data);
          setTotalPages(
            Math.ceil(
              referralDetailsResponse.referal_data.length / itemsPerPage
            )
          );
        } catch (error) {
          console.error("Error fetching referral data:", error);
        }
      } else {
        console.error("No valid access token found");
        navigate("/login"); // Optionally redirect if no valid token
      }
    };

    fetchReferralData();
  }, []);
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  console.log(referralDetails);
  // Pagination for referrals
  const indexOfLastReferral = currentPage * itemsPerPage;
  const indexOfFirstReferral = indexOfLastReferral - itemsPerPage;
  // Ensure referralDetails and referralDetails.data are defined before accessing 'referal_data'
  
  return (
    <Box style={{ width: "100%", padding: "20px" }}>
      {/* Refer and Earn Section */}
      <ReferralSection>
        <Heading variant="h4">Refer and Earn</Heading>

        <Message variant="body1">
          Want to earn rewards while helping your friends get started? Share
          your referral link now and start earning with every successful
          sign-up!
        </Message>

        {username ? (
          <Box sx={{ mb: 4 }}>
            <Typography variant="body1" align="left" gutterBottom>
              <strong>Your Referral Link:</strong>{" "}
              <a
                href={`${window.location.origin}/signup?referralCode=${username}`}
                style={{ textDecoration: "none", color: "#ff4700" }}
              >
                {`${window.location.origin}/signup?referralCode=${username}`}
              </a>
              <CopyButton
                onClick={() => {
                  const referralLink = `${window.location.origin}/signup?referralCode=${username}`;
                  navigator.clipboard.writeText(referralLink);
                  toast.success("Referral link copied to clipboard!");
                }}
              >
                <CopyAll />
              </CopyButton>
            </Typography>

            <Typography variant="body1" align="left" gutterBottom>
              <strong>Number of Successful Referrals:</strong>{" "}
              {referralDataCount}
            </Typography>
          </Box>
        ) : (
          <Alert severity="info">No referral data available.</Alert>
        )}
      </ReferralSection>

      {/* Referral Details Section */}
      <Typography
        variant="h5"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        Referral Details
      </Typography>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "secondary.main",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Username
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "secondary.main",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Email
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "secondary.main",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Signup On
              </TableCell>
              {user_type === "admin" && (
              <TableCell
                sx={{
                  backgroundColor: "secondary.main",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Refered By
              </TableCell>
              )  
              }
              <TableCell
                sx={{
                  backgroundColor: "secondary.main",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Payment Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {referralDetails.length > 0 ? (
              referralDetails.map((referral) => (
                <TableRow key={referral.id}>
                  <TableCell>{referral.username}</TableCell>
                  <TableCell>{referral.email}</TableCell>
                  <TableCell>
                    {new Date(referral.created_at).toLocaleDateString()}
                  </TableCell>
                  {user_type === "admin" && (
                  <TableCell>
                    {referral.referrer}
                  </TableCell>
                  )}
                  <TableCell>{referral.payment_status}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No referrals available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      />
    </Box>
  );
};

export default ReferAndEarn;
