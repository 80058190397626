import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { Box, Button, Grid, TextField, Typography, TextareaAutosize, Radio, RadioGroup, FormControlLabel, IconButton } from '@mui/material';
import { getValidAccessToken } from '../../../utils/tokenUtils';
import jsPDF from 'jspdf';
import RemoveIcon from '@mui/icons-material/Remove';

const apiUrl = process.env.REACT_APP_API_URL;

const LinkForm = ({ userType, ticketData, setTicketData }) => {
    const [linkForm, setLinkForm] = useState({});
    const [loading, setLoading] = useState(true);
    const [vehicleInfo, setVehicleInfo] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');




    function toPascalCase(str) {
        return str.replace(/_/g, ' ')
            .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
                index === 0 ? match.toUpperCase() : match.toUpperCase()
            );

    }

    useEffect(() => {
        const fetchbookKeepingForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'link_form_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.link_form_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setLinkForm(parsedDetails);
                    setVehicleInfo(parsedDetails.vehicleInfo || []);
                    //setAutoExpense(parsedDetails.autoExpense || {});
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchbookKeepingForm();
    }, [ticketData.tax_organizer_id]);

    const handleSubmit = async () => {
        try {
            setSuccessMessage('');

            const token = await getValidAccessToken();
            const response = await axios.post(
                `${apiUrl}/update-taxorganizer-field/`,
                {
                    tax_organizer_id: ticketData.tax_organizer_id,
                    column_name: 'link_form_details',
                    column_value: JSON.stringify({ ...linkForm, vehicleInfo }),
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
         //   console.log('Form saved successfully:', response.data);
            setSuccessMessage('Updated successfully!');
        } catch (error) {
            console.error('Error saving form:', error);
            setSuccessMessage('Error updating form. Please try again.');
        }
    };

    const autoExpenseFields = [
        { name: 'garage_rent', type: 'number' },
        { name: 'gas', type: 'number' },
        { name: 'oil', type: 'number' },
        { name: 'parking_fees', type: 'number' },
        { name: 'rental_fees', type: 'number' },
        { name: 'property_tax', type: 'number' },
        { name: 'repairs', type: 'number' },
        { name: 'tires', type: 'number' },
        { name: 'tolls', type: 'number' },
        { name: 'other_expenses', type: 'number' },
    ];

    const [autoExpense, setAutoExpense] = useState(() => {
        // Initialize all form fields with a default value of 0
        const initialValues = {};
        autoExpenseFields.forEach(field => {
            initialValues[field.name] = ''; // Initialize as 0
        });
        return initialValues;
    });


    const handleBInputChange = (fieldName, value) => {
        value = value || 0;

        // Update the form values
        const updatedFormValues = { ...autoExpense, [fieldName]: value };

        // Set the updated form values
        setAutoExpense(updatedFormValues);
    };

    const handleDownloadPdf = () => {
        const doc = new jsPDF();
        doc.text('Partner Information Details', 10, 10);
        //console.log(linkForm)
        Object.entries(linkForm || {}).forEach(([key, value], index) => {
            doc.text(`${key}: ${value}`, 10, 20 + index * 10);
        });
        doc.save('partner_information_details.pdf');
    };



    const vehicleFormFields = [
        { name: 'car_name', type: 'textbox' },
        { name: 'business_miles', type: 'number' },
        { name: 'total_miles', type: 'number' },
        { name: 'place_in_service_date', type: 'date' },
        { name: 'garage_rent', type: 'number' },
        { name: 'gas', type: 'number' },
        { name: 'oil', type: 'number' },
        { name: 'parking_fees', type: 'number' },
        { name: 'rental_fees', type: 'number' },
        { name: 'property_tax', type: 'number' },
        { name: 'repairs', type: 'number' },
        { name: 'tires', type: 'number' },
        { name: 'tolls', type: 'number' },
        { name: 'other_expenses', type: 'number' },

    ];

    const handleAddVehicle = () => {
        setVehicleInfo([...vehicleInfo,
        {
            car_name: '',
            business_miles: '',
            total_miles: '',
            place_in_service_date: '',
        }
        ]);
    };

    const handleRemoveVehicle = (index) => {
        setVehicleInfo(vehicleInfo.filter((_, i) => i !== index));
    };

    const handleVehicleChange = (index, field, value) => {
        const updatedPartners = [...vehicleInfo];
        updatedPartners[index] = { ...updatedPartners[index], [field]: value };
        setVehicleInfo(updatedPartners);
    };




    const renderVehicleForm = () => (
        <>
            {vehicleInfo.map((partner, index) => (
                <Box key={index} mb={0}>
                    <Grid container spacing={1} >
                        {vehicleFormFields.map((field) => {
                            const isFullWidthField = field.type === 'radio' || field.type === 'textarea';
                            return (
                                <Grid item xs={12} sm={isFullWidthField ? 12 : 3} key={field.name}>
                                    {field.type === 'textbox' ? (
                                        <TextField
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name]}
                                            onChange={(e) => handleVehicleChange(index, field.name, e.target.value)}
                                            fullWidth
                                            size="small"
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'number' ? (
                                        <TextField
                                            type="number"
                                            label={toPascalCase(field.name)}
                                            InputProps={{ inputProps: { min: 1},style: { fontSize: '16px' }, sx: { height: 40, padding: '5px 5px' }}}
                                            value={partner[field.name]}
                                            onChange={(e) => handleVehicleChange(index, field.name, e.target.value)}
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'date' ? (
                                        <TextField
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name] || ''}
                                            onChange={(e) => handleVehicleChange(index, field.name, e.target.value)}
                                            fullWidth
                                            type="date"
                                            size="small"
                                            disabled={userType !== 'user'}
                                            InputLabelProps={{
                                                shrink: true, // Keep the label above the input
                                            }}
                                        />
                                    ) : null}
                                </Grid>
                            );
                        })}
                        <Grid item xs={12} mb={0} mt={0}>
                            <IconButton onClick={() => handleRemoveVehicle(index)}>
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            ))}
            <Button variant="outlined" color="primary" onClick={handleAddVehicle} disabled={userType !== 'user'} >
                Add Vehicle
            </Button>
        </>
    );


    const renderAutoExpense = () => (
        <>
            <Box mb={2}>
                <Grid container spacing={1} >
                    {autoExpenseFields.map((field) => {
                        const isFullWidthField = field.type === 'radio' || field.type === 'textarea';
                        return (
                            <Grid item xs={12} sm={isFullWidthField ? 12 : 6} key={field.name}>
                                {field.type === 'number' ? (
                                    <TextField
                                        type="number"
                                        label={toPascalCase(field.name)}
                                        InputProps={{ inputProps: { min: 1},style: { fontSize: '16px' }, sx: { height: 40, padding: '5px 5px' }}}
                                        value={autoExpense[field.name]}
                                        onChange={(e) => handleBInputChange(field.name, e.target.value)}
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        disabled={userType !== 'user'}
                                    />
                                ) : null}
                            </Grid>
                        );
                    })}

                </Grid>
            </Box>

        </>
    );



    const renderFormFields = () => (
        <Box mt={0} >

            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
                        Did the Members/ shareholder or partners have the vehicle for business use in 2024:
                    </Typography>
                </Grid>
                <Grid item>
                    <RadioGroup
                        row
                        value={linkForm?.is_link_available || ''}
                        onChange={(e) => setLinkForm({ ...linkForm, is_link_available: e.target.value })}
                    >
                        <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType !== 'user'} />
                        <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType !== 'user'} />

                    </RadioGroup>
                </Grid>
            </Grid>

            {linkForm && linkForm.is_link_available === 'Yes' && (


                <Grid item xs={12}>

                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
                        Vehicle Information:
                    </Typography>
                    <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}

                    {renderVehicleForm()}
                    <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}


                </Grid>
            )}
            {/* Render Partners */}

            {/* Entity Type */}

            {userType === 'user' && (
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {linkForm ? 'Update' : 'Submit'}
                </Button>
            )}
            {successMessage && (
                <Typography color="success">{successMessage}</Typography>
            )}
        </Box>
    );

    if (loading) return <Typography>Loading...</Typography>;

    return (
        <Box p={0}>
            {userType !== 'user' ? (
                <>
                    {!linkForm ? (
                        <Typography>Details are not provided</Typography>
                    ) : (
                        <>

                            {renderFormFields()}
                        </>
                    )}
                </>
            ) : (
                renderFormFields()
            )}
        </Box>
    );
};

export default LinkForm;
