// src/components/ClientSearch.js
import React, { useState } from 'react';
import { Box, Button, TextField, Pagination,Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axios from 'axios';
import { getValidAccessToken, handleLogout } from '../utils/tokenUtils'; // Assuming this function is available
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const TicketSearch = () => {
    const [tickets, setTickets] = useState([]);

    const [username, setUsername] = useState('');
    const [serviceName, setServiceName] = useState('');
    const [serviceYearValue, setServiceYearValue] = useState('');
    const [ticketStatus, setTicketStatus] = useState('');
    const [assigneeAgentName, setAssigneeAgentName] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [ticketId, setTicketId] = useState('');

    const [searchResults, setSearchResults] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const navigate = useNavigate();

    const handleTicketClick = (ticketId) => {
        navigate(`/ticket-data?ticket_id=${ticketId}`);
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };
    const handleSearch = async () => {
        const token = await getValidAccessToken();
        if (!token) {
            handleLogout();
            return;
        }

        try {
            const response = await axios.get(`${apiUrl}/tickets/search/`, {
                params: {
                    username: username,
                    service_name: serviceName,
                    service_year_value: serviceYearValue,
                    ticket_status: ticketStatus,
                    assignee_agentname: assigneeAgentName,
                    created_at: createdAt,
                    ticket_id: ticketId,
                },
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (response.data.length === 0) {
                setErrorMessage('No users found matching the criteria.');
            } else {
                setTickets(response.data.data)
                setSearchResults(response.data);
                setErrorMessage(null);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
            setErrorMessage(error.response.data.detail);
        }
    };

    const indexOfLastTicket = currentPage * itemsPerPage;
    const indexOfFirstTicket = indexOfLastTicket - itemsPerPage;
    const currentTickets = Array.isArray(tickets) ? tickets.slice(indexOfFirstTicket, indexOfLastTicket) : [];


    return (
        <Box style={{ padding: '10px', width: '130%' }}>
            <Typography sx={{fontWeight: 'bold' }} variant="h5" align="left" gutterBottom>
                Ticket Search
            </Typography>

            {/* First Line - Username, First Name, Last Name */}
            <Box display="flex" justifyContent="space-between" marginBottom={0} flexWrap="wrap" gap={2}>
                <TextField
                    label="Username"
                    variant="outlined"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    style={{ flex: 1, fontSize: '1.1rem' }}
                    inputProps={{ style: { fontSize: '18px' } }}
                    fullWidth
                    margin="dense"
                    size="small" // Decrease textbox size
                />
                <TextField
                    label="Service Name"
                    variant="outlined"
                    value={serviceName}
                    onChange={(e) => setServiceName(e.target.value)}
                    style={{ flex: 1, fontSize: '1.1rem' }}
                    inputProps={{ style: { fontSize: '18px' } }}
                    fullWidth
                    margin="dense"
                    size="small" // Decrease textbox size
                />
                <TextField
                    label="Year"
                    variant="outlined"
                    value={serviceYearValue}
                    onChange={(e) => setServiceYearValue(e.target.value)}
                    style={{ flex: 1, fontSize: '1.1rem' }}
                    inputProps={{ style: { fontSize: '18px' } }}
                    fullWidth
                    margin="dense"
                    size="small" // Decrease textbox size
                />
            </Box>

            {/* Second Line - Phone Number, Email, Search Button */}
            <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={2}>
                <TextField
                    label="Ticket Status"
                    variant="outlined"
                    value={ticketStatus}
                    onChange={(e) => setTicketStatus(e.target.value)}
                    style={{ flex: 1, fontSize: '1.1rem' }}
                    inputProps={{ style: { fontSize: '18px' } }}
                    fullWidth
                    margin="dense"
                    size="small" // Decrease textbox size
                />
                <TextField
                    label="Assignee"
                    variant="outlined"
                    value={assigneeAgentName}
                    onChange={(e) => setAssigneeAgentName(e.target.value)}
                    style={{ flex: 1, fontSize: '1.1rem' }}
                    inputProps={{ style: { fontSize: '18px' } }}
                    fullWidth
                    margin="dense"
                    size="small" // Decrease textbox size
                />
                 <TextField
                    label="Created At"
                    variant="outlined"
                    value={createdAt}
                    onChange={(e) => setCreatedAt(e.target.value)}
                    style={{ flex: 1, fontSize: '1.1rem' }}
                    inputProps={{ style: { fontSize: '18px' } }}
                    fullWidth
                    margin="dense"
                    size="small" // Decrease textbox size
                />
                 <TextField
                    label="Ticket Id"
                    variant="outlined"
                    value={ticketId}
                    onChange={(e) => setTicketId(e.target.value)}
                    style={{ flex: 1, fontSize: '1.1rem' }}
                    inputProps={{ style: { fontSize: '18px' } }}
                    fullWidth
                    margin="dense"
                    size="small" // Decrease textbox size
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    style={{ height: '40px', alignSelf: 'center' }}
                >
                    Search
                </Button>
            </Box>

            {errorMessage && (
                <Typography variant="body1" color="error" align="center" marginTop={2}>
                    {errorMessage}
                </Typography>
            )}


            <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                <Table size="small">
                    <TableHead sx={{ '& .MuiTableCell-head': { height: '20px' } }}>
                        <TableRow>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Ticket ID</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Taxpayer Name</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Tax Year</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Service Name</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Status</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Assigned Agent</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Support</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ '& .MuiTableCell-head': { height: '20px' } }}>
                        {searchResults.length > 0 ? (
                            searchResults.map(ticket => (
                                <TableRow
                                    key={ticket.ticket_id}
                                    onClick={() => handleTicketClick(ticket.ticket_id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell>
                                        <Button
                                            onClick={() => handleTicketClick(ticket.ticket_id)}
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                        >
                                            {ticket.ticket_id}
                                        </Button>
                                    </TableCell>
                                    <TableCell>{ticket.username}</TableCell>
                                    <TableCell>{ticket.service_year_value}</TableCell>
                                    <TableCell>{ticket.service_name}</TableCell>
                                    <TableCell>
                                        <Typography variant="body3" sx={{
                                            backgroundColor: '#ff4700',
                                            color: 'white',
                                            borderRadius: '16px',
                                            px: 1,
                                            py: 0.5,
                                            fontSize: '14px',
                                            top: '1px',
                                            fontWeight: 'bold'
                                        }}>
                                            {ticket.ticket_status || 'N/A'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{ticket.assignee_agent_name || 'N/A'}</TableCell>
                                    <TableCell>{ticket.csr || 'N/A'}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    No tickets available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
            />
        

            
        </Box>
    );
};

export default TicketSearch;
