import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { Checkbox, TableContainer, Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, Button, Grid, TextField, Typography, TextareaAutosize, Radio, RadioGroup, FormControlLabel, IconButton } from '@mui/material';

import { getValidAccessToken } from '../../../utils/tokenUtils';
import jsPDF from 'jspdf';
import RemoveIcon from '@mui/icons-material/Remove';
import autoTable from "jspdf-autotable";

const apiUrl = process.env.REACT_APP_API_URL;



const FinancialTable = ({ fields, handleInputChange ,userType}) => (


    <TableContainer component={Paper} sx={{ marginBottom: 4 }}>

        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem' }}>Details</TableCell>
                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem' }} align="center">Foreign Currency</TableCell>
                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem' }} align="center">Income Earned</TableCell>
                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem' }} align="center">Tax Paid / Withheld</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {fields.map((field, index) => (
                    
                    <TableRow key={field.name}>
                        <TableCell>{toPascalCase(field.name)}</TableCell>
                        <TableCell align="center">
                            <TextField
                                disabled={userType !== 'user'}
                                value={field.value1}
                                InputProps={{style: { fontSize: '16px' }, sx: { height: 40, padding: '5px 5px' }}}
                                onChange={(e) => handleInputChange(index, 'value1', e.target.value, 'text')}
                                size="small"
                                fullWidth
                                placeholder="Currency Type (ex: dollar, euro, pound, etc)"
                                type="text"
                            />
                        </TableCell>
                        <TableCell align="center">
                            <TextField
                                type="number"
                                disabled={userType !== 'user'}
                                value={field.value2}
                                InputProps={{ inputProps: { min: 1},style: { fontSize: '16px' }, sx: { height: 40, padding: '5px 5px' }}}
                                onChange={(e) => handleInputChange(index, 'value2', e.target.value, 'number')}
                                placeholder="Enter numbers"
                                size="small"
                                fullWidth
                            />
                        </TableCell>
                        <TableCell align="center">
                            <TextField
                                type="number"
                                disabled={userType !== 'user'}
                                value={field.value3}
                                InputProps={{ inputProps: { min: 1},style: { fontSize: '16px' }, sx: { height: 40, padding: '5px 5px' }}}
                                onChange={(e) => handleInputChange(index, 'value3', e.target.value, 'number')}
                                placeholder="Enter numbers"
                                size="small"
                                fullWidth
                            />
                        </TableCell>
                    </TableRow>
                ))}

            </TableBody>
        </Table>
    </TableContainer>


);




function handleChange(event)  {
    let inputValue = event.target.value;
    // Remove leading zeros but allow a single zero
    if (inputValue.startsWith("0") && inputValue.length > 1) {
        inputValue = inputValue.replace(/^0+/, "");
    }
        event.target.value = inputValue;

}
function toPascalCase(str) {
    return str.replace(/_/g, ' ')
        .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
            index === 0 ? match.toUpperCase() : match.toUpperCase()
        );
}

const IncomesForm = ({ userType, ticketData, setTicketData }) => {
    const [incomesForm, setIncomesForm] = useState({});
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');

    const [personalDetails, setPersonalDetails] = useState({});


    useEffect(() => {
        const fetchPersonalDetails = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'basic_details_form'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.basic_details_form;

                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setPersonalDetails(parsedDetails.personalDetailsValues || {});
                }

            } catch (error) {
                console.error('Error fetching personal details:', error);
            }
        };
        const fetchbookKeepingForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'income_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.income_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    
                    setIncomesForm(parsedDetails);
                    setIncomeValues(parsedDetails.incomeValues || []);
                    if (Array.isArray(parsedDetails.foreignIncome) && parsedDetails.foreignIncome.length > 0) {
                        setForeignIncome(parsedDetails.foreignIncome);
                    }

                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPersonalDetails();
        fetchbookKeepingForm();
    }, [ticketData.tax_organizer_id]);
    const handleSubmit = async () => {
        try {
            setSuccessMessage('');

            const token = await getValidAccessToken();
            const response = await axios.post(
                `${apiUrl}/update-taxorganizer-field/`,
                {
                    tax_organizer_id: ticketData.tax_organizer_id,
                    column_name: 'income_details',
                    column_value: JSON.stringify({ ...incomesForm, incomeValues, foreignIncome }),
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
         //   console.log('Form saved successfully:', response.data);
            setSuccessMessage('Updated successfully!');
        } catch (error) {
            console.error('Error saving form:', error);
            setSuccessMessage('Error updating form. Please try again.');
        }
    };


    const handleDownloadPdf2 = () => {
        const doc = new jsPDF();
        doc.text('Partner Information Details', 10, 10);
        Object.entries(incomesForm || {}).forEach(([key, value], index) => {
            doc.text(`${key}: ${value}`, 10, 20 + index * 10);
        });
        doc.save('partner_information_details.pdf');
    };

    const removeArrays = (obj) => {
        // Use Object.entries to iterate over key-value pairs
        return Object.entries(obj).reduce((acc, [key, value]) => {
            if (!Array.isArray(value)) {
                // Only keep properties that are not arrays
                acc[key] = value;
            }
            return acc;
        }, {});
    };
    const toSentenceCase = (input) => {
        return input
            .split(/([.!?])\s*/) // Split on punctuation (., !, ?) with whitespace after
            .map(sentence => sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase()) // Capitalize each
            .join(''); // Rejoin sentences
    };
    const handleDownloadPdf = () => {
        const doc = new jsPDF();
        let y  = 10;
        let headValue ='Income Details';
        let xOffset = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(headValue) / 2);
        doc.text(headValue, xOffset, y);

        var img = new Image();
        img.src = "favicon.png";

        doc.addImage(img, 10, 2, 25, 25);

        const pageWidth = doc.internal.pageSize.width - 30;
        const twentyWidth = pageWidth * 0.2;
        const eigthWidth = pageWidth * 0.8;
        const sevenWidth = pageWidth * 0.75;
        const halfWidth = pageWidth * 0.5;


        let database = [];
      /*  console.log("State Values")
        console.log("------------------------------------------------------------------------------")
        console.log(JSON.stringify(removeArrays(stateDetailsForm)));
        console.log("------------------------------------------------------------------------------")
        console.log("------------------------------------------------------------------------------")
        console.log(JSON.stringify(stateDetailsForm.taxPayerResidency));
        console.log("------------------------------------------------------------------------------")
        console.log("------------------------------------------------------------------------------")
        console.log(JSON.stringify(stateDetailsForm.spouseResidency));
        console.log("------------------------------------------------------------------------------")
        console.log("State Values")*/

        Object.entries(incomesForm || {}).forEach(([key, value], index) => {
       //     console.log("Key"+JSON.stringify(key))
         //   console.log("Value"+JSON.stringify(value))
            let row = [
                key,
                value
            ]
            database.push(row);
        });

        database = [
            [
                "",
                removeArrays(incomesForm)
            ],

        ];
        y  = 20;

        let rows = [];
        headValue =  "Income Details".toUpperCase();
        doc.text(headValue, 20, y+20);
        y = y + 20;
        autoTable(doc, {
            startY: y + 10, // Starting position for the first table
            head: [['Fields', 'Value']],
            body:rows,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:eigthWidth
                    },
                1: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:twentyWidth
                },
            }
        });
        y = doc.lastAutoTable.finalY + 10;
        headValue =  "Foreign Income".toUpperCase();
        doc.text(headValue, 20, y);
        // y = y + 10;

        rows = [];
        let pos = 0;

        let rowData =JSON.parse(JSON.stringify(incomeValues));
        const dataArray = Array.isArray(rowData) ? rowData : Object.entries(rowData);

        dataArray.forEach(item => {
            //  console.log("Data Array Rows : "+item);
            rows[pos] = [item[0],item[1]];
            pos++;
        });

        autoTable(doc, {
            startY: y + 10, // Starting position for the first table
            head: [['Fields', 'Value']],
            body:rows,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:eigthWidth
                    },
                1: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:twentyWidth
                },
            }
        });
        y = doc.lastAutoTable.finalY + 10;

        let data = [];
        let statt = 0;


        database.forEach(data => {
            let headValue =  data[0].toUpperCase();
            let xOffset = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(headValue) / 2);
            doc.text(headValue, xOffset, y);

            let rows = [];
            let pos = 0;
            let rowData =JSON.parse(JSON.stringify(data[1]));
            const dataArray = Array.isArray(rowData) ? rowData : Object.entries(rowData);

            dataArray.forEach(item => {
              //  console.log("Data Array Rows : "+item[0] + " "+item[1]);
                if(statt == 0)
                    rows[pos] = [toSentenceCase(item[0].replace(/_/g, ' ')),item[1].toUpperCase()];
                pos++;
            });

            // console.log("Data Array : "+rows);
            autoTable(doc, {
                startY: y + 10, // Starting position for the first table
                head: [['Fields', 'Value']],
                body:rows,
                headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
                columnStyles: {
                    0:
                        {
                            halign: "left",
                            valign: 'middle',
                            fontStyle: "bold",
                            fontSize: 9,
                            textColor: "#000",
                            cellPadding: 1,
                            lineWidth: 0.2,
                            lineColor: [0, 0, 0],
                            cellWidth:eigthWidth
                        },
                    1: {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:twentyWidth
                    },
                }
            });
            y = doc.lastAutoTable.finalY + 10;
            //y = y + 10;
            statt++;
        });
        doc.save('partner_information_details.pdf');
    };


    const incomeFields = [
        { name: 'income_type', type: 'textbox' },
        { name: 'amount', type: 'number' },
        { name: 'tax_paid_or_withheld', type: 'number' },
    ];


    const [incomeValues, setIncomeValues] = useState([]);

    const handleAddIncome = () => {
        setIncomeValues([...incomeValues,
        {
            income_type: '',
            amount: '',
            tax_paid_or_withheld: '',
        }
        ]);
    };


    const handleRemoveIncome = (index) => {
        setIncomeValues(incomeValues.filter((_, i) => i !== index));
    };

    const handleChangeIncome = (index, field, value) => {
        const updatedPartners = [...incomeValues];
        updatedPartners[index] = { ...updatedPartners[index], [field]: value };
        setIncomeValues(updatedPartners);
    };









    const populateDynamicForm = ({ data, fields, handleChange, handleAdd, handleRemove }) => (
        <>
            {data.map((partner, index) => (
                <Box key={index} mb={0}>


                    <Grid container spacing={1} >
                        {fields.map((field) => {
                            const isFullWidthField = field.type === 'radio' || field.type === 'textarea';
                            return (
                                <Grid item xs={12} sm={isFullWidthField ? 12 : 2.4} key={field.name}>
                                    {field.type === 'textbox' ? (
                                        <TextField
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name]}
                                            onChange={(e) => handleChange(index, field.name, e.target.value,)}
                                            fullWidth
                                            size="small"
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'number' ? (
                                        <TextField
                                            type="number"
                                            label={toPascalCase(field.name)}

                                            InputProps={{ inputProps: { min: 1},style: { fontSize: '16px' }, sx: { height: 40, padding: '5px 5px' }}}
                                            value={partner[field.name]}
                                            onChange={(e) => handleChange(index, field.name, e.target.value)}
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'date' ? (
                                        <TextField
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name] || ''}
                                            onChange={(e) => handleChange(index, field.name, e.target.value)}
                                            fullWidth
                                            type="date"
                                            size="small"
                                            disabled={userType !== 'user'}
                                            InputLabelProps={{
                                                shrink: true, // Keep the label above the input
                                            }}
                                        />
                                    ) : null}
                                </Grid>

                            );
                        })}
                        <Grid item xs={12} mb={0} mt={0}>
                            <IconButton onClick={() => handleRemove(index)}>
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            ))}
            <Button variant="outlined" color="primary" onClick={handleAdd} disabled={userType !== 'user'} >
                Add
            </Button>
        </>
    );



    const checkboxes = [
        {
            name: 'i_am_an_employee_during_2023',
            label: `I'm an employee during ${ticketData.service_year_value}`,
            upload: 'W2 / W2c',
        },
        {
            name: 'i_am_an_independent_contractor_during_2023',
            label: `I'm an Independent Contractor during ${ticketData.service_year_value}`,
            upload: '1099-MISC/1099NEC',
        },
        {
            name: 'i_have_done_stock_transactions_during_2023',
            label: `I have done Stock Transactions during ${ticketData.service_year_value}`,
            upload: '1099-B',
        },
        {
            name: 'i_have_done_crypto_currency_transactions_during_2023',
            label: `I have done Crypto Currency Transactions during ${ticketData.service_year_value}`,
            upload: 'Gain / Loss Statement',
        },
        {
            name: 'i_have_received_social_security_or_retirement_benefits_during_2023',
            label: `I have received Social Security or Retirement Benefits during ${ticketData.service_year_value}`,
            upload: '1099-R',
        },
        {
            name: 'i_have_received_hsa_distribution_during_2023',
            label: `I have received HSA Distribution during ${ticketData.service_year_value}`,
            upload: '1099-SA',
        },
        {
            name: 'i_have_received_interest_income_during_2023',
            label: `I have received Interest Income during ${ticketData.service_year_value}`,
            upload: '1099-INT',
        },
        {
            name: 'i_have_received_dividend_income_during_2023',
            label: `I have received Dividend Income during ${ticketData.service_year_value}`,
            upload: '1099-DIV',
        },
        {
            name: 'i_have_received_non_employee_compensation_during_2023',
            label: `I have received Non Employee Compensation during ${ticketData.service_year_value}`,
            upload: '1099-NEC',
        },
    ];



    const handleChangeCheckBoxes = (e) => {
        setIncomesForm({
            ...incomesForm,
            [e.target.name]: e.target.checked,
        });
    };


    const [foreignIncome, setForeignIncome] = useState([
        { name: `foreign_interest_received_during_${ticketData.service_year_value}`, value1: '', value2: '', value3:'' },
        { name: `foreign_dividend_received_during_${ticketData.service_year_value}`, value1: '', value2: '',value3: '' },
        { name: `foreign_salary_income_received_during_${ticketData.service_year_value}`, value1: '', value2: '', value3: '' },
        { name: `foreign_other_income_received_during_${ticketData.service_year_value}`, value1: '', value2: '', value3: '' },
    ]);

    const handleTableInputChange = (index, key, value, type) => {
        const newData = [...foreignIncome];
    
        if (type === "number") {
            if (value.startsWith("0") && value.length > 0) {
                newData[index][key] = Number(value); // Convert to number if it starts with 0 but has more characters
            } else if (value.length > 0) {
                newData[index][key] = Number(value); // Convert to number for non-zero starting values
            } else {
                newData[index][key] = ''; // Default to 0 for empty values
            }
        } else if (type === "text") {
            newData[index][key] = value; // Directly store text as it is
        }
    
        setForeignIncome(newData);
    };

    const renderFormFields = () => (
        <Box mt={0} >

            <Typography variant="body1" sx={{ fontSize: '20px', fontWeight: 'bold', marginRight: '8px' }}>
                Income Details:
            </Typography>

            <Grid item xs={1} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}

            <Typography variant="body1" sx={{ fontSize: '20px', fontWeight: 'bold', marginRight: '8px' }}>
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox

                                checked='true'
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Check all the applicable boxes"
                    />
                </div>

            </Typography>


            <div>
                {checkboxes.map((checkbox) => (
                    <div key={checkbox.name}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    disabled={userType !== 'user'}
                                    checked={incomesForm[checkbox.name] || false}
                                    onChange={handleChangeCheckBoxes}
                                    name={checkbox.name}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={checkbox.label}
                        />
                        <Typography variant="body1" sx={{ fontSize: '14px', marginRight: '8px' }}>
                            Upload: {checkbox.upload}
                        </Typography>
                    </div>
                ))}
            </div>



            <Grid container alignItems="center">
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                        Did you Made Any Changes in Ownership of the Business?
                    </Typography>
                </Grid>
                <Grid item xs={6}>

                    <RadioGroup
                        row
                        value={incomesForm?.did_you_made_any_changes_in_ownership_of_business || ''}
                        onChange={(e) => setIncomesForm({ ...incomesForm, did_you_made_any_changes_in_ownership_of_business: e.target.value })}
                    >
                        <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType !== 'user'} />
                        <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType !== 'user'} />

                    </RadioGroup>
                </Grid>

            </Grid>
            <Grid item xs={1} sx={{ mb: 1 }} /> {/* Adjust 'mb' value for more or less space */}

            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
                        Did you open any Foreign Mutual Fund Account During {ticketData.service_year_value}?
                    </Typography>
                </Grid>
                <Grid item>
                    <RadioGroup
                        row
                        value={incomesForm?.did_you_open_any_foreign_mutual_fund_account_during_2023 || ''}
                        onChange={(e) => setIncomesForm({ ...incomesForm, did_you_open_any_foreign_mutual_fund_account_during_2023: e.target.value })}
                    >
                        <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType !== 'user'} />
                        <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType !== 'user'} />

                    </RadioGroup>

                </Grid>



            </Grid>

            <Grid item><Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '10px' }}>
                Any other Income you have received during {ticketData.service_year_value}
            </Typography></Grid>

            {populateDynamicForm({
                data: incomeValues,           // form data
                fields: incomeFields,         // array of field definitions
                handleChange: handleChangeIncome,
                handleAdd: handleAddIncome,
                handleRemove: handleRemoveIncome // handle field change
            })}

<Grid item xs={1} sx={{ mb: 1 }} /> {/* Adjust 'mb' value for more or less space */}

            <Typography variant="body1" sx={{ fontSize: '20px', fontWeight: 'bold', marginRight: '8px' }}>
                Foreign Income:
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '12px', marginRight: '8px' }}>
                Note:Please enter the amounts with the Currency Type (ex: INR 77,777/-)
            </Typography>

            <Grid item xs={12}>


            <FinancialTable
                fields={foreignIncome}
                handleInputChange={(index, key, value,type) => handleTableInputChange(index, key, value,type)}
                userType={userType}
            />
            
            </Grid>


<Grid item xs={1} sx={{ mb: 1 }} /> {/* Adjust 'mb' value for more or less space */}

            <Typography variant="body1" sx={{ fontSize: '20px', fontWeight: 'bold', marginRight: '8px' }}>
                FBAR & FATCA:
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: 'bold', marginRight: '8px' }}>
                Do you have at-least $10,000/- collectively in all financial accounts outside the United States During {ticketData.service_year_value}?
            </Typography>


            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
                        Tax Payer
                    </Typography>
                </Grid>
                <Grid item>
                    <RadioGroup
                        row
                        value={incomesForm?.have_10k_in_all_financial_accounts_outside_us_during_2023_tax_year_tax_payer || ''}
                        onChange={(e) => setIncomesForm({ ...incomesForm, have_10k_in_all_financial_accounts_outside_us_during_2023_tax_year_tax_payer: e.target.value })}
                    >
                        <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType !== 'user'} />
                        <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType !== 'user'} />

                    </RadioGroup>
                </Grid>
                {personalDetails?.marital_status === 'Married'&&
                <>
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
                        Spouse
                    </Typography>
                </Grid>
                
                <Grid item>
                    <RadioGroup
                        row
                        value={incomesForm?.have_10k_in_all_financial_accounts_outside_us_during_2023_tax_year_spouse || ''}
                        onChange={(e) => setIncomesForm({ ...incomesForm, have_10k_in_all_financial_accounts_outside_us_during_2023_tax_year_spouse: e.target.value })}
                    >
                        <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType !== 'user'} />
                        <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType !== 'user'} />

                    </RadioGroup>
                </Grid>
                </>
            }
            
            </Grid>
            {(incomesForm?.have_10k_in_all_financial_accounts_outside_us_during_2023_tax_year_tax_payer === 'Yes'|| incomesForm.have_10k_in_all_financial_accounts_outside_us_during_2023_tax_year_spouse==='Yes') &&
            <>
            <Typography variant='caption' >Please Click on the downlaod option and fill up the form,Upload that again for a smooth filing</Typography><br></br>
                <Typography variant='caption' sx={{color:'blue',textDecoration:'underline'}} component="a" href={'/downloadable_files/FBAR_&_FATCA_organizer.docx'} download>Download FBAR Questionnair</Typography>
                </>
                }
          

            {personalDetails?.marital_status === 'Married'?
            <>           
            <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: 'bold', marginRight: '8px' }}>
                Do you and your spouse have at-least $100,000/- collectively in all financial accounts outside the United States During {ticketData.service_year_value}?
            </Typography>



            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
                        Spouse
                    </Typography>
                </Grid>
                <Grid item>
                    <RadioGroup
                        row
                        value={incomesForm?.have_10k_in_all_financial_accounts_outside_us_during_2023_spouse || ''}
                        onChange={(e) => setIncomesForm({ ...incomesForm, have_10k_in_all_financial_accounts_outside_us_during_2023_spouse: e.target.value })}
                    >
                        <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType !== 'user'} />
                        <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType !== 'user'} />

                    </RadioGroup>
                </Grid>

                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
                        Tax Payer
                    </Typography>
                </Grid>
                <Grid item>
                    <RadioGroup
                        row
                        value={incomesForm?.have_10k_in_all_financial_accounts_outside_us_during_2023_tax_payer || ''}
                        onChange={(e) => setIncomesForm({ ...incomesForm, have_10k_in_all_financial_accounts_outside_us_during_2023_tax_payer: e.target.value })}
                    >
                        <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType !== 'user'} />
                        <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType !== 'user'} />

                    </RadioGroup>
                </Grid>
            </Grid>
            </>
            :
            <>
            <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: 'bold', marginRight: '8px' }}>
                Do you  have at-least $50000 /- collectively in all financial accounts outside the United States During {ticketData.service_year_value}?
            </Typography>



            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
                        Tax Payer
                    </Typography>
                </Grid>
                <Grid item>
                    <RadioGroup
                        row
                        value={incomesForm?.have_50k_in_all_financial_accounts_outside_us_during_2023_tax_payer || ''}
                        onChange={(e) => setIncomesForm({ ...incomesForm, have_50k_in_all_financial_accounts_outside_us_during_2023_tax_payer: e.target.value })}
                    >
                        <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType !== 'user'} />
                        <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType !== 'user'} />

                    </RadioGroup>
                </Grid>
            </Grid>
            </>
            }
            <Typography variant='caption' sx={{marginRight:'8px'}}>hello please fill this return as 1040</Typography>
            <TextareaAutosize
                minRows={3}
                placeholder="Comments"
                disabled={userType !== 'user'}
                value={incomesForm?.comments || ''}
                onChange={(e) => setIncomesForm({ ...incomesForm, comments: e.target.value })}
                style={{ width: '100%', fontSize: '16px', padding: '8px' }}
            />


            {(userType === 'user' || userType === 'admin') && (
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {incomesForm ? 'Update' : 'Submit'}
                </Button>
            )}
            {successMessage && (
                <Typography color="success">{successMessage}</Typography>
            )}
        </Box>
    );

    if (loading) return <Typography>Loading...</Typography>;

    return (
        <Box p={0}>
            {userType !== 'user' ? (
                <>
                    {!incomesForm ? (
                        <Typography>Details are not provided</Typography>
                    ) : (
                        <>

                            {renderFormFields()}
                        </>
                    )}
                </>
            ) : (
                renderFormFields()
            )}
        </Box>
    );
};

export default IncomesForm;
