import { useState, useCallback } from "react";

export const useDynamicFields = (initialFields, dynamicFieldConditions) => {
    const [fields, setFields] = useState(initialFields);

    const updateFields = useCallback((triggerFieldName, triggerValue, data) => {
        const condition = dynamicFieldConditions[triggerFieldName];

        if (!condition) return;

        const { dynamicFields, conditionChecker } = condition;

        const alreadyIncludesDynamicFields = fields.some((field) =>
            dynamicFields.some((dynamicField) => dynamicField.name === field.name)
        );

        const isConditionMet = conditionChecker(triggerValue, data);

        if (isConditionMet && !alreadyIncludesDynamicFields) {
            const targetIndex = fields.findIndex((field) => field.name === triggerFieldName);
            if (targetIndex !== -1) {
                const updatedFields = [...fields];
                updatedFields.splice(targetIndex + 1, 0, ...dynamicFields);
                setFields(updatedFields);
            }
        } else if (!isConditionMet && alreadyIncludesDynamicFields) {
            setFields((prevFields) =>
                prevFields.filter(
                    (field) => !dynamicFields.some((dynamicField) => dynamicField.name === field.name)
                )
            );
        }
    }, [fields, dynamicFieldConditions]);

    return [fields, updateFields, setFields];
};
