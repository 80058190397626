import React from "react";
import { Alert, Box, Button, Container, Snackbar, TextField, Typography } from "@mui/material";
import axios from "axios";
import Loader from "../utils/Loader";
const apiUrl = process.env.REACT_APP_API_URL;


const ForgotPassword = () => {
    const[emailid, setEmailid] = React.useState('');
    const[message, setMessage] = React.useState('');
    const[errorMessage, setErrorMessage] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setLoader(true);
        try {
            const response = await axios.post(`${apiUrl}/forgot-password/`, {
                email: emailid,
            });
            if (response.data.success) {
                setMessage(response.data.message);
                setLoader(false);
            } else {
                setErrorMessage(response.data.message);
                setLoader(false);
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('An error occurred. Please try again later.');
        }
    };
    return (
        <Container maxWidth="xs">
            <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={!!message} autoHideDuration={6000} onClose={() => setMessage('')}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        {loader && <Loader />}
        <Box sx={{ mt: 5, mb: 5 }}>
            <Typography variant="h4" align="center" gutterBottom>
                Forgot Password
            </Typography>
            <Typography variant="caption" align="center" gutterBottom>
                Enter your email address to reset your password, A link will be sent to your email address.
                </Typography>
            <form onSubmit={handleForgotPassword}>
                <TextField
                    label="Enter Email Address"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    name="email"
                    value={emailid}
                    onChange={(e) => setEmailid(e.target.value)}
                    required
                    inputProps={{ style: { fontSize: '16px' } }}
                />
                
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    sx={{ mt: 3 }}>
                    Forgot Password
                </Button>
            </form>
        </Box>
    </Container>
    );
}
export default ForgotPassword;