export function adjustYear(currentYear, step) {
    // Ensure the inputs are valid numbers
    if (typeof currentYear !== 'number' || typeof step !== 'number') {
      throw new Error('Both currentYear and step must be numbers.');
    }
  
    // Calculate the new year
    const newYear = currentYear + step;
  
    return newYear;
  }
  
  export function useAdjustDateFormat(dateStr) {
    const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  
  return `${day}-${month}-${year}`;
  }