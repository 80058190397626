import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { Box, Button, Grid, TextField, Typography, TextareaAutosize, Radio, RadioGroup, FormControlLabel, IconButton } from '@mui/material';
import { getValidAccessToken } from '../../../utils/tokenUtils';
import jsPDF from 'jspdf';
import RemoveIcon from '@mui/icons-material/Remove';
import { adjustYear } from '../../../hooks/adjustYear';

const apiUrl = process.env.REACT_APP_API_URL;

const DependentsForm = ({ userType, ticketData, setTicketData }) => {
    const [dependentsForm, setDependentsForm] = useState({});
    const [loading, setLoading] = useState(true);
    const [vehicleInfo, setVehicleInfo] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');

    function toPascalCase(str) {
        return str.replace(/_/g, ' ')
            .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
                index === 0 ? match.toUpperCase() : match.toUpperCase()
            );

    }

    useEffect(() => {
        const fetchbookKeepingForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'dependents_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.dependents_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setDependentsForm(parsedDetails);
                    setVehicleInfo(parsedDetails.vehicleInfo || []);
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchbookKeepingForm();
    }, [ticketData.tax_organizer_id]);

    const handleSubmit = async () => {
        try {
            setSuccessMessage('');

            const token = await getValidAccessToken();
            const response = await axios.post(
                `${apiUrl}/update-taxorganizer-field/`,
                {
                    tax_organizer_id: ticketData.tax_organizer_id,
                    column_name: 'dependents_details',
                    column_value: JSON.stringify({ ...dependentsForm, vehicleInfo }),
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
           // console.log('Form saved successfully:', response.data);
            setSuccessMessage('Updated successfully!');
        } catch (error) {
            console.error('Error saving form:', error);
            setSuccessMessage('Error updating form. Please try again.');
        }
    };

    
    const handleDownloadPdf = () => {
        const doc = new jsPDF();
        doc.text('Partner Information Details', 10, 10);
        Object.entries(dependentsForm || {}).forEach(([key, value], index) => {
            doc.text(`${key}: ${value}`, 10, 20 + index * 10);
        });
        doc.save('partner_information_details.pdf');
    };

    const vehicleFormFields = [
        { name: 'first_name', type: 'textbox' },
        { name: 'middle_name', type: 'textbox' },
        { name: 'last_name', type: 'textbox' },
        { name: 'date_of_birth', type: 'date' },

        { name: 'phone_no', type: 'textbox' },
        { name: 'email', type: 'email' },
        { name: 'ssn', type: 'ssn' },
        { name: 'occupation', type: 'textbox' },
        { name: 'first_port_of_entry_into_united_states', type: 'date' },
        { name: 'she_or_he_is_my', type: 'radio', options: ['Son', 'Daughter', 'Brother', 'Sister', 'Mother', 'Dad', 'In-Laws', 'Aunt', 'Uncle', 'Grand Parents', 'Others'] },
        { name: 'current_visa_type', type: 'radio', options: ['CITIZEN', 'GREEN_CARD', 'H1B', 'H4', 'L1', 'L2', 'F1 CPT', 'F1 OPT', 'F2', 'J1', 'J2', 'B1', 'B2', 'OTHERS'] },
        { name: `was_there_any_change_in_dependent_visa_during_${ticketData.service_year_value}`, type: 'radio', options: ['Yes', 'No'] },
        { name: `did_you_reside_at_least_6_months_in_US_during_${ticketData.service_year_value}`, type: 'radio', options: ['Yes', 'No'] },
        { name: `will_you_reside_at_least_6_months_in_US_in_${adjustYear(ticketData.service_year_value,1)}`, type: 'radio', options: ['Yes', 'No'] },
        { name: 'comments', type: 'textarea',helpertext:"hello please fill this return as 1040" },
    ];

    const handleAddVehicle = () => {
        setVehicleInfo([...vehicleInfo,
        {
            first_name: '',
            middle_name: '',
            last_name: '',
            date_of_birth: '',

            phone_no: '',
            email: '',
            ssn: '',
            occupation: '',
            first_port_of_entry_into_united_states: '',
            she_or_he_is_my: '',
            current_visa_type: '',
            was_there_any_change_in_dependent_visa_during_2023: '',
            did_you_reside_at_least_6_months_in_US_during_2023: '',
            will_you_reside_at_least_6_months_in_US_in_2024: '',
            comments: '',
        }
        ]);
    };

    const handleRemoveVehicle = (index) => {
        setVehicleInfo(vehicleInfo.filter((_, i) => i !== index));
    };

    const handleVehicleChange = (index, field, value) => {
        console.log("Welcome : "+value);
        console.log("Welcome Field : "+field);

        const updatedPartners = [...vehicleInfo];
        updatedPartners[index] = { ...updatedPartners[index], [field]: value };
        setVehicleInfo(updatedPartners);
    };




    const renderVehicleForm = () => (
        <>
            {vehicleInfo.map((partner, index) => (
                <Box key={index} mb={0}>
                    <Grid container spacing={1} >
                        {vehicleFormFields.map((field) => {
                            const isFullWidthField = field.type === 'radio' || field.type === 'textarea';
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={isFullWidthField ? 12 : 3}
                                key={field.name}
                              >
                                {field.type === "ssn" ? (
                                  <InputMask
                                    mask="999-99-9999"
                                    inputProps={{
                                      style: {
                                        fontSize: "16px",
                                        color: "black",
                                      },
                                    }}
                                    style={{
                                      color: "blue", // Text color
                                      borderColor: "gray", // Optional: border color
                                    }}
                                    value={partner[field.name]}
                                    onChange={(e) =>
                                      handleVehicleChange(
                                        index,
                                        field.name,
                                        e.target.value
                                      )
                                    }
                                    disabled={userType !== "user"}
                                  >
                                    {(inputProps) => (
                                      <TextField
                                        {...inputProps}
                                        label={toPascalCase(field.name)}
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                      />
                                    )}
                                  </InputMask>
                                ) : field.type === "textbox" ? (
                                  <TextField
                                    label={toPascalCase(field.name)}
                                    inputProps={{ style: { fontSize: "16px" } }}
                                    value={partner[field.name]}
                                    onChange={(e) =>
                                      handleVehicleChange(
                                        index,
                                        field.name,
                                        e.target.value
                                      )
                                    }
                                    fullWidth
                                    size="small"
                                    disabled={userType !== "user"}
                                  />
                                ) : field.type === "number" ? (
                                  <TextField
                                    type="number"
                                    label={toPascalCase(field.name)}
                                    inputProps={{ style: { fontSize: "16px" } }}
                                    value={partner[field.name]}
                                    onChange={(e) =>
                                      handleVehicleChange(
                                        index,
                                        field.name,
                                        e.target.value
                                      )
                                    }
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    disabled={userType !== "user"}
                                  />
                                ) : field.type === "date" ? (
                                  <TextField
                                    label={toPascalCase(field.name)}
                                    inputProps={{ style: { fontSize: "16px" } }}
                                    value={partner[field.name] || ""}
                                    onChange={(e) =>
                                      handleVehicleChange(
                                        index,
                                        field.name,
                                        e.target.value
                                      )
                                    }
                                    fullWidth
                                    type="date"
                                    size="small"
                                    disabled={userType !== "user"}
                                    InputLabelProps={{
                                      shrink: true, // Keep the label above the input
                                    }}
                                  />
                                ) : field.type === "email" ? (
                                  <TextField
                                    label={toPascalCase(field.name)}
                                    inputProps={{ style: { fontSize: "16px" } }}
                                    value={partner[field.name]}
                                    onChange={(e) =>
                                      handleVehicleChange(
                                        index,
                                        field.name,
                                        e.target.value
                                      )
                                    }
                                    fullWidth
                                    type="email"
                                    size="small"
                                    disabled={userType !== "user"}
                                  />
                                ) : field.type === "radio" ? (
                                  <Grid
                                    container
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <Grid item>
                                      <Typography
                                        variant="body1"
                                        sx={{
                                          fontSize: "14px",
                                          display: "inline",
                                        }}
                                      >
                                        {toPascalCase(field.name)}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs>
                                      <RadioGroup
                                        row
                                        value={partner[field.name]}
                                        onChange={(e) =>
                                          handleVehicleChange(
                                            index,
                                            field.name,
                                            e.target.value
                                          )
                                        }
                                      >
                                        {field.options.map((option) => (
                                          <FormControlLabel
                                            key={option}
                                            value={option}
                                            control={
                                              <Radio
                                                sx={{ transform: "scale(0.8)" }}
                                              />
                                            } // Scale down the radio button
                                            label={option}
                                            disabled={userType !== "user"}
                                            sx={{
                                              "& .MuiFormControlLabel-label": {
                                                fontSize: "0.8rem", // Adjust the label font size
                                              },
                                            }}
                                          />
                                        ))}
                                      </RadioGroup>
                                    </Grid>
                                  </Grid>
                                ) : field.type === "textarea" ? (
                                  <>
                                    {field?.helpertext && (
                                      <Typography
                                        variant="caption"
                                        style={{
                                          marginTop: "4px",
                                          color: "gray",
                                        }}
                                      >
                                        {field?.helpertext}
                                      </Typography>
                                    )}
                                    <TextareaAutosize
                                      inputProps={{
                                        style: { fontSize: "16px" },
                                      }}
                                      minRows={3}
                                      placeholder={toPascalCase(field.name)}
                                      value={partner[field.name]}
                                      onChange={(e) =>
                                        handleVehicleChange(
                                          index,
                                          field.name,
                                          e.target.value
                                        )
                                      }
                                      style={{
                                        width: "100%",
                                        fontSize: "16px",
                                        padding: "8px",
                                      }}
                                      disabled={userType !== "user"}
                                    />
                                  </>
                                ) : null}
                              </Grid>
                            );
                        })}
                        <Grid item xs={12} mb={0} mt={0}>
                            <IconButton onClick={() => handleRemoveVehicle(index)}>
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            ))}
            <Button variant="outlined" color="primary" onClick={handleAddVehicle} disabled={userType !== 'user'} >
                Add Dependent
            </Button>
        </>
    );




    const renderFormFields = () => (
        <Box mt={0} >
            <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontSize: '14px', marginRight: '8px' }}>
                    **If your Dependent does not have an SSN/ITIN, please contact your Tax Account Manager to Apply for an ITIN.
                </Typography>
                <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}

                {renderVehicleForm()}
                <Grid item xs={12} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}
            </Grid>
            {/* Render Partners */}
            {/* Entity Type */}
            {(userType === 'user' || userType === 'admin') && (
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {dependentsForm ? 'Update' : 'Submit'}
                </Button>
            )}
            {successMessage && (
                <Typography color="success">{successMessage}</Typography>
            )}
        </Box>
    );

    if (loading) return <Typography>Loading...</Typography>;

    return (
        <Box p={0}>
            {userType !== 'user' ? (
                <>
                    {!dependentsForm ? (
                        <Typography>Details are not provided</Typography>
                    ) : (
                        <>
                            {renderFormFields()}
                        </>
                    )}
                </>
            ) : (
                renderFormFields()
            )}
        </Box>
    );
};

export default DependentsForm;
