import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { MenuItem, InputLabel, Select, FormControl, Box, Button, Grid, TextField, Typography, TextareaAutosize, Radio, RadioGroup, FormControlLabel, IconButton } from '@mui/material';
import { getValidAccessToken } from '../../../utils/tokenUtils';
import jsPDF from 'jspdf';
import RemoveIcon from '@mui/icons-material/Remove';
import autoTable from "jspdf-autotable";
    
const apiUrl = process.env.REACT_APP_API_URL;

const StateDetailsForm = ({ userType, ticketData, setTicketData }) => {
    const [stateDetailsForm, setStateDetailsForm] = useState({});
    const [loading, setLoading] = useState(true);
    const [taxPayerResidency, setTaxPayerResidency] = useState([]);
    const [spouseResidency, setSpouseResidency] = useState([]);

    const [successMessage, setSuccessMessage] = useState('');




    function toPascalCase(str) {
        return str.replace(/_/g, ' ')
            .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
                index === 0 ? match.toUpperCase() : match.toUpperCase()
            );

    }
    const [personalDetails, setPersonalDetails] = useState({});


    useEffect(() => {
        const fetchPersonalDetails = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'basic_details_form'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.basic_details_form;

                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setPersonalDetails(parsedDetails.personalDetailsValues || {});
                }

            } catch (error) {
                console.error('Error fetching personal details:', error);
            }
        };
        const fetchbookKeepingForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'state_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.state_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setStateDetailsForm(parsedDetails);
                    setTaxPayerResidency(parsedDetails.taxPayerResidency || []);
                    setSpouseResidency(parsedDetails.spouseResidency || []);
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchbookKeepingForm();
        fetchPersonalDetails();
    }, [ticketData.tax_organizer_id]);

    const handleSubmit = async () => {
        try {
            setSuccessMessage('');

            const token = await getValidAccessToken();
            const response = await axios.post(
                `${apiUrl}/update-taxorganizer-field/`,
                {
                    tax_organizer_id: ticketData.tax_organizer_id,
                    column_name: 'state_details',
                    column_value: JSON.stringify({ ...stateDetailsForm, taxPayerResidency, spouseResidency }),
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
           // console.log('Form saved successfully:', response.data);
            setSuccessMessage('Updated successfully!');
        } catch (error) {
            console.error('Error saving form:', error);
            setSuccessMessage('Error updating form. Please try again.');
        }
    };

    const removeArrays = (obj) => {
        // Use Object.entries to iterate over key-value pairs
        return Object.entries(obj).reduce((acc, [key, value]) => {
            if (!Array.isArray(value)) {
                // Only keep properties that are not arrays
                acc[key] = value;
            }
            return acc;
        }, {});
    };
    const toSentenceCase1 = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };
    const toSentenceCase = (input) => {
        return input
            .split(/([.!?])\s*/) // Split on punctuation (., !, ?) with whitespace after
            .map(sentence => sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase()) // Capitalize each
            .join(''); // Rejoin sentences
    };
    const handleDownloadPdf = () => {
        const doc = new jsPDF();
        let y  = 10;
        let headValue ='State';
        let xOffset = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(headValue) / 2);
        doc.text(headValue, xOffset, y);

        var img = new Image();
        img.src = "favicon.png";

        doc.addImage(img, 10, 2, 25, 25);

        const pageWidth = doc.internal.pageSize.width - 30;
        const twentyWidth = pageWidth * 0.2;
        const eigthWidth = pageWidth * 0.8;
        const sevenWidth = pageWidth * 0.75;
        const halfWidth = pageWidth * 0.5;


        let database = [];

        Object.entries(stateDetailsForm || {}).forEach(([key, value], index) => {
            let row = [
                key,
                value
            ]
            database.push(row);
        });

       database = [
            [
               "",
                removeArrays(stateDetailsForm)
           ],

       ];
        y  = 20;

        let rows = [];
        headValue =  "Taxpayer Residency".toUpperCase();
        doc.text(headValue, 20, y+20);
        y = y + 20;
        autoTable(doc, {
            startY: y + 10, // Starting position for the first table
            head: [['Fields', 'Value']],
            body:rows,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:eigthWidth
                    },
                1: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:twentyWidth
                },
            }
        });
        y = doc.lastAutoTable.finalY + 10;
        headValue =  "Spouse Residency".toUpperCase();
        doc.text(headValue, 20, y);
       // y = y + 10;

        rows = [];
        let pos = 0;
        let rowData =JSON.parse(JSON.stringify(stateDetailsForm.taxPayerResidency));
        const dataArray = Array.isArray(rowData) ? rowData : Object.entries(rowData);

        dataArray.forEach(item => {
            //  console.log("Data Array Rows : "+item);
            rows[pos] = [item[0],item[1]];
            pos++;
        });

        autoTable(doc, {
            startY: y + 10, // Starting position for the first table
            head: [['Fields', 'Value']],
            body:rows,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:eigthWidth
                    },
                1: {
                    halign: "left",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:twentyWidth
                },
            }
        });
        y = doc.lastAutoTable.finalY + 10;

        let data = [];
        let statt = 0;


        database.forEach(data => {
            let headValue =  data[0].toUpperCase();
            let xOffset = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(headValue) / 2);
            doc.text(headValue, xOffset, y);

            let rows = [];
            let pos = 0;
            let rowData =JSON.parse(JSON.stringify(data[1]));
            const dataArray = Array.isArray(rowData) ? rowData : Object.entries(rowData);

            dataArray.forEach(item => {
             //  console.log("Data Array Rows : "+item[0] + " "+item[1]);
                if(statt == 0)
                rows[pos] = [toSentenceCase(item[0].replace(/_/g, ' ')),item[1].toUpperCase()];
                pos++;
            });

            // console.log("Data Array : "+rows);
           autoTable(doc, {
                startY: y + 10, // Starting position for the first table
                head: [['Fields', 'Value']],
                body:rows,
                headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
                columnStyles: {
                    0:
                        {
                            halign: "left",
                            valign: 'middle',
                            fontStyle: "bold",
                            fontSize: 9,
                            textColor: "#000",
                            cellPadding: 1,
                            lineWidth: 0.2,
                            lineColor: [0, 0, 0],
                            cellWidth:eigthWidth
                        },
                    1: {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:twentyWidth
                    },
                }
            });
            y = doc.lastAutoTable.finalY + 10;
            //y = y + 10;
            statt++;
        });
        doc.save('partner_information_details.pdf');
    };

    const stateFields = [
        {
            name: 'state_resided', type: 'dropdown', options: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',]
        },
        { name: 'zip_code', type: 'textbox' },
        { name: 'resided_from', type: 'date' },
        { name: 'resided_till', type: 'date' },
    ];

    const handleAddTaxPayerResidency = () => {
        setTaxPayerResidency([...taxPayerResidency,
        {
            state_resided: '',
            zip_code: '',
            resided_from: '',
            resided_till: '',
        }
        ]);
    };

    const handleRemoveTaxPayerResidency = (index) => {
        setTaxPayerResidency(taxPayerResidency.filter((_, i) => i !== index));
    };

    const handleChangeTaxPayerResidency = (index, field, value) => {
        const updatedPartners = [...taxPayerResidency];
        updatedPartners[index] = { ...updatedPartners[index], [field]: value };
        setTaxPayerResidency(updatedPartners);
    };

    const handleAddSpouseResidency = () => {
        setSpouseResidency([...spouseResidency,
        {
            state_resided: '',
            zip_code: '',
            resided_from: '',
            resided_till: '',
        }
        ]);
    };

    const handleRemoveSpouseResidency = (index) => {
        setSpouseResidency(spouseResidency.filter((_, i) => i !== index));
    };

    const handleChangeSpouseResidency = (index, field, value) => {
        const updatedPartners = [...spouseResidency];
        updatedPartners[index] = { ...updatedPartners[index], [field]: value };
        setSpouseResidency(updatedPartners);
    };




    const renderTaxPayerForm = () => (
        <>
            {taxPayerResidency.map((partner, index) => (
                <Box key={index} mb={0}>
                    <Grid container spacing={1} >
                        {stateFields.map((field) => {
                            const isFullWidthField = field.type === 'radio' || field.type === 'textarea';
                            return (
                                <Grid item xs={15} sm={isFullWidthField ? 12 : 3} key={field.name}>
                                    {field.type === 'textbox' ? (
                                        <TextField
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name]}
                                            onChange={(e) => handleChangeTaxPayerResidency(index, field.name, e.target.value)}
                                            fullWidth
                                            size="small"
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'number' ? (
                                        <TextField
                                            type="number"
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name]}
                                            onChange={(e) => handleChangeTaxPayerResidency(index, field.name, e.target.value)}
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'date' ? (
                                        <TextField
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name] || ''}
                                            onChange={(e) => handleChangeTaxPayerResidency(index, field.name, e.target.value)}
                                            fullWidth
                                            type="date"
                                            size="small"
                                            disabled={userType !== 'user'}
                                            InputLabelProps={{
                                                shrink: true, // Keep the label above the input
                                            }}
                                        />
                                    ) : field.type === 'email' ? (
                                        <TextField
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name]}
                                            onChange={(e) => handleChangeTaxPayerResidency(index, field.name, e.target.value)}
                                            fullWidth
                                            type="email"
                                            size="small"
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'radio' ? (
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Typography variant="body1" sx={{ fontSize: '14px', display: 'inline' }}>
                                                    {toPascalCase(field.name)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <RadioGroup
                                                    row
                                                    value={partner[field.name]}
                                                    onChange={(e) => handleChangeTaxPayerResidency(index, field.name, e.target.value)}
                                                >
                                                    {field.options.map((option) => (
                                                        <FormControlLabel
                                                            key={option}
                                                            value={option}
                                                            control={<Radio sx={{ transform: 'scale(0.8)' }} />} // Scale down the radio button
                                                            label={option}
                                                            disabled={userType !== 'user'}
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {
                                                                    fontSize: '0.8rem', // Adjust the label font size
                                                                },
                                                            }}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    ) : field.type === 'textarea' ? (
                                        <TextareaAutosize
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            minRows={3}
                                            placeholder={toPascalCase(field.name)}
                                            value={partner[field.name]}
                                            onChange={(e) => handleChangeTaxPayerResidency(index, field.name, e.target.value)}
                                            style={{ width: '100%', fontSize: '16px', padding: '8px' }}
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'dropdown' ? (
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Typography variant="body1" sx={{ fontSize: '14px', display: 'inline' }}>
                                                    {toPascalCase(field.name)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <FormControl fullWidth variant="outlined" size="small" disabled={userType !== 'user'}>
                                                    <InputLabel>{toPascalCase(field.name)}</InputLabel>
                                                    <Select
                                                    sx={{
                                                        '& .MuiSelect-select': {
                                                            fontSize: '16px', // Increase font size for the selected value
                                                        },
                                                    }}
                                                        value={partner[field.name]}
                                                        onChange={(e) => handleChangeTaxPayerResidency(index, field.name, e.target.value)}
                                                        label={toPascalCase(field.name)}
                                                    >
                                                        {field.options.map((option) => (
                                                            <MenuItem key={option} value={option} sx={{ fontSize: '1.0 rem' }}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            );
                        })}
                        <Grid item xs={12} mb={0} mt={0}>
                            <IconButton onClick={() => handleRemoveTaxPayerResidency(index)}>
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            ))}
            <Button variant="outlined" color="primary" onClick={handleAddTaxPayerResidency} disabled={userType !== 'user'} >
                Add Tax Payer Residency
            </Button>
        </>
    );





    const renderSpouseForm = () => (
        <>
            {spouseResidency.map((partner, index) => (
                <Box key={index} mb={0}>
                    <Grid container spacing={1} >
                        {stateFields.map((field) => {
                            const isFullWidthField = field.type === 'radio' || field.type === 'textarea';
                            return (
                                <Grid item xs={15} sm={isFullWidthField ? 12 : 3} key={field.name}>
                                    {field.type === 'textbox' ? (
                                        <TextField
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name]}
                                            onChange={(e) => handleChangeSpouseResidency(index, field.name, e.target.value)}
                                            fullWidth
                                            size="small"
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'number' ? (
                                        <TextField
                                            type="number"
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name]}
                                            onChange={(e) => handleChangeSpouseResidency(index, field.name, e.target.value)}
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'date' ? (
                                        <TextField
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name] || ''}
                                            onChange={(e) => handleChangeSpouseResidency(index, field.name, e.target.value)}
                                            fullWidth
                                            type="date"
                                            size="small"
                                            disabled={userType !== 'user'}
                                            InputLabelProps={{
                                                shrink: true, // Keep the label above the input
                                            }}
                                        />
                                    ) : field.type === 'email' ? (
                                        <TextField
                                            label={toPascalCase(field.name)}
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            value={partner[field.name]}
                                            onChange={(e) => handleChangeSpouseResidency(index, field.name, e.target.value)}
                                            fullWidth
                                            type="email"
                                            size="small"
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'radio' ? (
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Typography variant="body1" sx={{ fontSize: '14px', display: 'inline' }}>
                                                    {toPascalCase(field.name)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <RadioGroup
                                                    row
                                                    value={partner[field.name]}
                                                    onChange={(e) => handleChangeSpouseResidency(index, field.name, e.target.value)}
                                                >
                                                    {field.options.map((option) => (
                                                        <FormControlLabel
                                                            key={option}
                                                            value={option}
                                                            control={<Radio sx={{ transform: 'scale(0.8)' }} />} // Scale down the radio button
                                                            label={option}
                                                            disabled={userType !== 'user'}
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {
                                                                    fontSize: '1.0rem', // Adjust the label font size
                                                                },
                                                            }}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    ) : field.type === 'textarea' ? (
                                        <TextareaAutosize
                                            inputProps={{ style: { fontSize: '16px' } }}
                                            minRows={3}
                                            placeholder={toPascalCase(field.name)}
                                            value={partner[field.name]}
                                            onChange={(e) => handleChangeSpouseResidency(index, field.name, e.target.value)}
                                            style={{ width: '100%', fontSize: '16px', padding: '8px' }}
                                            disabled={userType !== 'user'}
                                        />
                                    ) : field.type === 'dropdown' ? (
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Typography variant="body1" sx={{ fontSize: '14px', display: 'inline' }}>
                                                    {toPascalCase(field.name)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <FormControl fullWidth variant="outlined" size="small" disabled={userType !== 'user'}>
                                                    <InputLabel>{toPascalCase(field.name)}</InputLabel>
                                                    <Select
                                                     sx={{
                                                        '& .MuiSelect-select': {
                                                            fontSize: '16px', // Increase font size for the selected value
                                                        },
                                                    }}
                                                        value={partner[field.name]}
                                                        onChange={(e) => handleChangeSpouseResidency(index, field.name, e.target.value)}
                                                        label={toPascalCase(field.name)}
                                                    >
                                                        {field.options.map((option) => (
                                                            <MenuItem key={option} value={option} sx={{ fontSize: '1.0rem' }}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            );
                        })}
                        <Grid item xs={12} mb={0} mt={0}>
                            <IconButton onClick={() => handleRemoveSpouseResidency(index)}>
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            ))}
            <Button variant="outlined" color="primary" onClick={handleAddSpouseResidency} disabled={userType !== 'user'} >
                Add Spouse Residency
            </Button>
        </>
    );




    const renderFormFields = () => (
        <Box mt={0} >





            <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontSize: '20px', fontWeight: 'bold', marginRight: '8px' }}>
                    Taxpayer Residency:
                </Typography>
                <Grid item xs={1} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}


                {renderTaxPayerForm()}
                <Grid item xs={1} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}
                {personalDetails?.marital_status === 'Married'&&
                <>
                <Typography variant="body1" sx={{ fontSize: '20px', fontWeight: 'bold', marginRight: '8px' }}>
                    Spouse Residency:
                </Typography>
                <Grid item xs={1} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}


                {renderSpouseForm()}
                </>
                }
            </Grid>


            {personalDetails?.marital_status === 'Married' ?
            <Grid container alignItems="center">
                <Grid item xs={8}>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                        Did you or your Spouse reside in Massachusetts during {ticketData.service_year_value}?
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <RadioGroup
                        row
                        value={stateDetailsForm.do_you_or_your_spouse_reside_in_massachusetts_during_2024 || ''}
                        onChange={(e) => setStateDetailsForm({ ...stateDetailsForm, do_you_or_your_spouse_reside_in_massachusetts_during_2024: e.target.value })}
                        disabled={userType !== 'user' }
                    >
                        <FormControlLabel size="small" disabled={userType !== 'user'} value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel size="small" disabled={userType !== 'user'} value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>
               
            </Grid>
            :
            <Grid container alignItems="center">
            <Grid item xs={8}>
                <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                    Did you reside in Massachusetts during {ticketData.service_year_value}?
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <RadioGroup
                    row
                    value={stateDetailsForm.do_you_reside_in_massachusetts_during_2024 || ''}
                    onChange={(e) => setStateDetailsForm({ ...stateDetailsForm, do_you_reside_in_massachusetts_during_2024: e.target.value })}
                    disabled={userType !== 'user' }
                >
                    <FormControlLabel size="small" disabled={userType !== 'user'} value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel size="small" disabled={userType !== 'user'} value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </Grid>
           
        </Grid>

            }

            <Grid container alignItems="center">
                <Grid item xs={8}>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                    Are you covered by Massachusetts Health Insurance during {ticketData.service_year_value}? 
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <RadioGroup
                        row
                        value={stateDetailsForm.are_you_covered_by_massachusetts_health_insurance_during_2024 || ''}
                        onChange={(e) => setStateDetailsForm({ ...stateDetailsForm, are_you_covered_by_massachusetts_health_insurance_during_2024: e.target.value })}
                        disabled={userType !== 'user'}
                    >
                        <FormControlLabel size="small" disabled={userType !== 'user'} value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel size="small" disabled={userType !== 'user'} value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>
                <Grid item xs={12} sx={{ mb: 0 }} /> {/* Adjust 'mb' value for more or less space */}

                <Typography variant="body1" sx={{ fontSize: '14px', marginRight: '8px' }}>
                Note: If Yes, please provide the document 1099 HC.
                </Typography>
    

               
            </Grid>
            {personalDetails?.marital_status === 'Married' ?
            <Grid container alignItems="center">
                <Grid item xs={8}>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                    Did you or your Spouse reside in New Jersey or California during {ticketData.service_year_value}? 
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <RadioGroup
                        row
                        value={stateDetailsForm.did_you_or_your_spouse_reside_in_new_jersey_or_california_during_2024 || ''}
                        onChange={(e) => setStateDetailsForm({ ...stateDetailsForm, did_you_or_your_spouse_reside_in_new_jersey_or_california_during_2024: e.target.value })}
                        disabled={userType !== 'user' }
                    >
                        <FormControlLabel size="small" disabled={userType !== 'user'} value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel size="small" disabled={userType !== 'user'} value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>

                <Typography variant="body1" sx={{ fontSize: '14px', marginRight: '8px' }}>
                Note: If Yes, please upload your Insurance Documents 1095-A/1095-B/1095-C along with your documents.
                </Typography>
            </Grid>
            :
            <Grid container alignItems="center">
                <Grid item xs={8}>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                    Did you reside in New Jersey or California during {ticketData.service_year_value}? 
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <RadioGroup
                        row
                        value={stateDetailsForm.did_you_reside_in_new_jersey_or_california_during_2024 || ''}
                        onChange={(e) => setStateDetailsForm({ ...stateDetailsForm, did_you_reside_in_new_jersey_or_california_during_2024: e.target.value })}
                        disabled={userType !== 'user' }
                    >
                        <FormControlLabel size="small" disabled={userType !== 'user'} value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel size="small" disabled={userType !== 'user'} value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>

                <Typography variant="body1" sx={{ fontSize: '14px', marginRight: '8px' }}>
                Note: If Yes, please upload your Insurance Documents 1095-A/1095-B/1095-C along with your documents.
                </Typography>
            </Grid>
            }
            <Grid container alignItems="center">
                <Grid item xs={8}>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                    Did you reside in California, Massachusetts, New Jersey, Minnesota, Wisconsin or Indiana during {ticketData.service_year_value}? 
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <RadioGroup
                        row
                        value={stateDetailsForm.did_you_reside_in_california_or_massachusetts_or_new_jersey_or_minnesota_or_wisconsin_or_indiana_during_2024 || ''}
                        onChange={(e) => setStateDetailsForm({ ...stateDetailsForm, did_you_reside_in_california_or_massachusetts_or_new_jersey_or_minnesota_or_wisconsin_or_indiana_during_2024: e.target.value })}
                        disabled={userType !== 'user' }
                    >
                        <FormControlLabel size="small" disabled={userType !== 'user'} value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel size="small" disabled={userType !== 'user'} value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>
    

               
            </Grid>

            <Grid container alignItems="center">
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                    Enter the monthly rent paid in that state during {ticketData.service_year_value} :  
                    </Typography>
                </Grid>
                <Grid item xs={6}>

                <TextField
                                label='Rent'
                                type="number"


                                InputProps={{ inputProps: { min: 1},style: { fontSize: '18px' } }}
                                value={stateDetailsForm?.enter_the_rent_paid_in_that_state_during_2024 || ''}
                                onChange={(e) => setStateDetailsForm({ ...stateDetailsForm, enter_the_rent_paid_in_that_state_during_2024: e.target.value })}
                                size="small"
                                disabled={userType !== 'user'}
                                sx={{ marginLeft: '8px' }} // Add some spacing between the label and textbox
                            />
                  
                </Grid>
    

               
            </Grid>


         
            
            <Grid container alignItems="center">
                <Grid item xs={8}>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                    Did you reside in Alabama during {ticketData.service_year_value}? 
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <RadioGroup
                        row
                        value={stateDetailsForm.did_you_reside_in_albama_during_2024 || ''}
                        onChange={(e) => setStateDetailsForm({ ...stateDetailsForm, did_you_reside_in_albama_during_2024: e.target.value })}
                        disabled={userType !== 'user'}
                    >
                        <FormControlLabel size="small" value="Yes" disabled={userType !== 'user'} control={<Radio />} label="Yes" />
                        <FormControlLabel size="small" value="No" disabled={userType !== 'user'} control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>
                <Grid item xs={12} sx={{ mb: 0 }} /> {/* Adjust 'mb' value for more or less space */}

                <Typography variant="body1" sx={{ fontSize: '14px', marginRight: '8px' }}>
                Note: If Yes, please upload your Driving License or State ID along with your documents.
                </Typography>
    

               
            </Grid>
            <Typography variant='caption' sx={{marginRight:'8px'}}>
            hello please fill this return as 1040
            </Typography>
            <TextareaAutosize
        minRows={3}
        placeholder="Comments"
        disabled={userType !== 'user'}
        value={stateDetailsForm?.comments || ''}
        onChange={(e) => setStateDetailsForm({ ...stateDetailsForm, comments: e.target.value })}
        style={{ width: '100%', fontSize: '16px', padding: '8px' }}
      />


            {(userType === 'user' || userType === 'admin') && (
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {stateDetailsForm ? 'Update' : 'Submit'}
                </Button>
            )}
            {successMessage && (
                <Typography color="success">{successMessage}</Typography>
            )}
        </Box>
    );

    if (loading) return <Typography>Loading...</Typography>;

    return (
        <Box p={0}>
            {userType !== 'user' ? (
                <>
                    {!stateDetailsForm ? (
                        <Typography>Details are not provided</Typography>
                    ) : (
                        <>

                            {renderFormFields()}
                        </>
                    )}
                </>
            ) : (
                renderFormFields()
            )}
        </Box>
    );
};

export default StateDetailsForm;
