import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal, ModalContent, Divider, Snackbar, Alert, CircularProgress } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import { getValidAccessToken } from '../../utils/tokenUtils';

import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { CheckCircle } from '@mui/icons-material';
import { set } from 'firebase/database';


const apiUrl = process.env.REACT_APP_API_URL;

const initializeInvoice = () => ({
    date: new Date().toLocaleDateString(),
    items: [{ description: '', rate: 0, quantity: 1 }], // Default rate 0, quantity 1
    notes: '',
    discount: 0
});


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const Invoice = ({ ticketId,userType, ticketData, setTicketData,customerData }) => {

    const isInvoiceEmpty = !ticketData.invoice || Object.keys(ticketData.invoice).length === 0 || ticketData.invoice === null; // Check if invoice is null or empty
    const [invoiceData, setInvoiceData] = useState(isInvoiceEmpty ? initializeInvoice() : ticketData.invoice);
    const [isCreating, setIsCreating] = useState(isInvoiceEmpty); // Set to true if invoice is null or empty
    const [discount, setDiscount] = useState(ticketData.invoice?.discount || 0);
    const [openZelleModal, setOpenZelleModal] = useState(false);
    const [openTransactionModal, setOpenTransactionModal] = useState(false);
    const [openNameSearchModal, setOpenNameSearchModal] = useState(false);
    const [accountHolderName, setAccountHolderName] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);



    const handleInputChange = (field, value) => {
        setInvoiceData({ ...invoiceData, [field]: value });
    };

    const handleItemChange = (index, field, value) => {
        const newItems = [...invoiceData.items];
        if (field === 'rate' && value < 0) value = 0; // Ensure rate is not negative
        if (field === 'quantity' && value < 1) value = 1; // Ensure quantity is at least 1
        newItems[index][field] = value;
        setInvoiceData({ ...invoiceData, items: newItems });
    };

    const addItem = () => {
        setInvoiceData({ ...invoiceData, items: [...invoiceData.items, { description: '', rate: 0, quantity: 1 }] });
    };

    const calculateSubtotal = () => {
        if (!invoiceData || !invoiceData.items) return 0;
        return invoiceData.items.reduce((acc, item) => acc + item.rate * item.quantity, 0);
    };

    const calculateTotal = (subtotal) => {
        return subtotal - discount;
    };



    const handleCreateInvoice = async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.post(`${apiUrl}/ticket/create-invoice/`, {
                ticket_id: ticketData.ticket_id
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.success) {
                setTicketData(response.data.data);
                setInvoiceData({ ...initializeInvoice(), discount: 0 });
                setIsCreating(false);
            } else {
                console.error('Error creating invoice');
            }
        } catch (error) {
            console.error('Error creating invoice:', error);
        }
    };

    const handleSubmit = async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.post(`${apiUrl}/ticket/update-invoice/`, {
                ticket_id: ticketData.ticket_id,
                invoice: { ...invoiceData, discount }
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.success) {
                setTicketData(response.data.data);
            } else {
                console.error('Error updating invoice');
            }
        } catch (error) {
            console.error('Error updating invoice:', error);
        }
    };

    const subtotal = calculateSubtotal();
    const total = calculateTotal(subtotal);

    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [{
                amount: {
                    value: total.toFixed(2), // Use the calculated total amount
                },
                description: 'Payment', // You can also pass a description for better clarity
                custom_id: ticketData.ticket_id, // Custom field to pass ticket_id
                invoice_id: ticketData.invoice_id, // Pass invoice_id directly
            }],
        });
    };

    const onApprove = (data, actions) => {
        actions.order.capture().then(details => {
            console.log('Transaction Details:', details);
    
            // Get ticket and invoice data
            const invoiceId = details.purchase_units[0].invoice_id;
            const ticketId = details.purchase_units[0].custom_id;
    
            console.log('Ticket details', ticketId, invoiceId);
            
    
            // Handle post-transaction logic here
            getValidAccessToken().then(token => {
                console.log('Token details', token);
    
                axios.post(
                    `${apiUrl}/ticket/update-payment-details/`,
                    {
                        ticket_id: ticketId,
                        invoice_id: invoiceId,
                        transaction_id: details.id,
                        transactional_details: details, // If needed, pass only relevant details
                    },
                    {
                        headers: { 'Authorization': `Bearer ${token}` },
                    }
                )
                .then(response => {
                    if (response.data.success) {
                        setTicketData(response.data.data);
                        setTicketData((prevData) => ({ ...prevData, ticket_status: 'Payment Made' }));
                        alert('Payment was successfully processed.');
                    } else {
                        console.error('Error updating invoice:', response.data.error);
                        alert('There was an error updating the payment. Please try again.');
                    }
                })
                .catch(error => {
                    console.error('Error updating invoice:', error);
                    alert('An error occurred while processing your payment. Please try again.');
                });
            })
            .catch(error => {
                console.error('Error fetching token:', error);
                alert('An error occurred while fetching the access token. Please try again.');
            });
        })
        .catch(error => {
            console.error('Error capturing transaction:', error);
            alert('An error occurred during the transaction. Please try again.');
        });
    };
    
    const handleZelleModal = () =>{
        setOpenZelleModal(true);
    }
    const handleTransactionModal = async() =>{
      setLoading(true);
        const token = await getValidAccessToken();
        if(!token){
            alert('Please login to continue');

            return window.location.href = '/login';
        }
        const response = await axios.post(`${apiUrl}/users/check-payments`, {
            expected_amount: total.toFixed(2),
            expected_name: accountHolderName
        },
        {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const data = response.data;
        if(data.status === 'success'){
          setLoading(false);
           setOpenZelleModal(false);
           setOpenTransactionModal(true);
        }
        else if(data.status === 'not-received'){
            setLoading(false);
            setOpenZelleModal(false);
            setOpenTransactionModal(false);
            setOpenNameSearchModal(true);
        }
        else{
            setLoading(false);
            setOpenZelleModal(true);
            setOpenNameSearchModal(false);
        }

        
    }

    const handleNameSearchModal = async() =>{

        setLoading(true);
        const token = await getValidAccessToken();
        if(!token){
            alert('Please login to continue');

            return window.location.href = '/login';
        }
        const response = await axios.post(`${apiUrl}/users/check-payments`, {
            expected_amount: total.toFixed(2),
            expected_name: accountHolderName
        },
        {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const data = response.data;
        if(data.status === 'success'){
            setLoading(false);
           setOpenZelleModal(false);
           setOpenNameSearchModal(false);
           setOpenTransactionModal(true);
        }
        else{
            setLoading(false);
            setOpenZelleModal(false);
            setOpenNameSearchModal(true);
        }

    }

    const saveTransaction = async() =>{
      setLoading(true);
        const token = await getValidAccessToken();
        if(!token){
            alert('Please login to continue');

            return window.location.href = '/login';
        }
        
        try{
            const response = await axios.post(`${apiUrl}/update-zelle-payment/`, {
                ticket_id: ticketId,
                transaction_id: transactionId,
                ammount: total.toFixed(2)
            },
            {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if(response.data.success){
              setLoading(false);
                setTicketData(response.data.data);
                setTicketData((prevData) => ({ ...prevData, ticket_status: 'Payment Made' }));
                alert('Payment was successfully processed.');
                setOpenTransactionModal(false);
            }
            else{
              setLoading(false);
                console.error('Error updating invoice:', response.data.error);
                alert('There was an error updating the payment. Please try again.');
                setOpenTransactionModal(false);

            }
        }
        catch(error){
          setLoading(false);
            console.error('Error updating invoice:', error);
            alert('An error occurred while processing your payment. Please try again.');
            setOpenTransactionModal(false);
        }
    }

   

    return (
      <Box sx={{ p: 3 }}>
        <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
                            <Alert severity="error" sx={{ width: '100%' }}>
                                {errorMessage}
                            </Alert>
                        </Snackbar>
        {userType !== "user" &&
          ticketData.invoice_status === "Payment Received" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 3,
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  padding: "20px",
                  borderRadius: "8px",
                  backgroundColor: "success.main",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  boxShadow: "0px 4px 12px rgba(0, 128, 0, 0.3)",
                  width: "80%", // Adjust width as needed
                  maxWidth: "500px",
                }}
              >
                <CheckCircle sx={{ fontSize: 32, marginRight: 2 }} />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Payment is received!
                </Typography>
              </Paper>
            </Box>
          )}

        {userType === "user" &&
          ticketData.invoice_status === "Payment Received" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 3,
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  padding: "20px",
                  borderRadius: "8px",
                  backgroundColor: "success.main",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  boxShadow: "0px 4px 12px rgba(0, 128, 0, 0.3)",
                  width: "80%", // Adjust width as needed
                  maxWidth: "500px",
                }}
              >
                <CheckCircle sx={{ fontSize: 32, marginRight: 2 }} />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Thanks for your payment. Payment is successfully received!
                </Typography>
              </Paper>
            </Box>
          )}
        {isInvoiceEmpty && !isCreating ? (
          userType !== "admin" ? (
            <>
              <Typography>No invoice found</Typography>
              <Button variant="contained" onClick={handleCreateInvoice}>
                Create Invoice
              </Button>
            </>
          ) : (
            <Typography>Invoice Not Created</Typography>
          )
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                mb: 0,
              }}
            >
              {/* Logo Section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/log3.png`}
                  alt="Logo"
                  style={{ width: 300 }}
                />
              </Box>

              {/* Invoice Details Section */}
              <Box
                sx={{
                  textAlign: "right",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography variant="h6">Invoice</Typography>
                <Typography>
                  Invoice Number: {ticketData.invoice_id || "N/A"}
                </Typography>
                <Typography>Date: {invoiceData.date}</Typography>
              </Box>
            </Box>

            {/* From and To Fields Below the Logo */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                mt: 0,
              }}
            >
              <Typography>From: Save Tax LLC</Typography>
              <Typography>
                To: {customerData.first_name} {customerData.last_name}
              </Typography>
            </Box>

            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "secondary.main",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "0.875rem",
                        width: "70%",
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "secondary.main",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "0.875rem",
                        width: "10%",
                      }}
                    >
                      Rate
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "secondary.main",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "0.875rem",
                        width: "10%",
                      }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "secondary.main",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "0.875rem",
                        width: "10%",
                      }}
                    >
                      Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceData.items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {userType !== "user" ? (
                          <TextField
                            value={item.description}
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                            fullWidth
                            size="small"
                            disabled={
                              ticketData.invoice_status === "Payment Received"
                            } // Disable if invoice_status is 'Payment Received'
                          />
                        ) : (
                          <Typography>{item.description}</Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {userType !== "user" ? (
                          <TextField
                            type="number"
                            value={item.rate}
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                "rate",
                                parseFloat(e.target.value)
                              )
                            }
                            fullWidth
                            size="small"
                            inputProps={{ min: 0 }}
                            disabled={
                              ticketData.invoice_status === "Payment Received"
                            } // Disable if invoice_status is 'Payment Received'
                          />
                        ) : (
                          <Typography>${item.rate.toFixed(2)}</Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {userType !== "user" ? (
                          <TextField
                            type="number"
                            value={item.quantity}
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                "quantity",
                                parseInt(e.target.value)
                              )
                            }
                            fullWidth
                            size="small"
                            inputProps={{ min: 1 }}
                            disabled={
                              ticketData.invoice_status === "Payment Received"
                            } // Disable if invoice_status is 'Payment Received'
                          />
                        ) : (
                          <Typography>{item.quantity}</Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        ${(item.rate * item.quantity).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {userType !== "user" && (
              <Button onClick={addItem} sx={{ mt: 2 }}>
                Add Item
              </Button>
            )}

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Box sx={{ flexGrow: 1, mr: 2 }}>
                {userType !== "user" ? (
                  <TextField
                    label="Notes"
                    multiline
                    rows={4}
                    value={invoiceData.notes}
                    onChange={(e) => handleInputChange("notes", e.target.value)}
                    fullWidth
                    disabled={ticketData.invoice_status === "Payment Received"} // Disable if invoice_status is 'Payment Received'
                  />
                ) : (
                  <Typography>Notes: {invoiceData.notes}</Typography>
                )}
              </Box>
              <Box>
                <Typography>Subtotal: ${subtotal.toFixed(2)}</Typography>
                {userType !== "user" && (
                  <TextField
                    label="Discount"
                    type="number"
                    value={discount}
                    onChange={(e) => setDiscount(parseFloat(e.target.value))}
                    sx={{ mb: 2 }}
                    fullWidth
                    size="small"
                    disabled={ticketData.invoice_status === "Payment Received"} // Disable if invoice_status is 'Payment Received'
                  />
                )}
                {discount > 0 && (
                  <Typography>Discount: ${discount.toFixed(2)}</Typography>
                )}
                <Typography>Total: ${total.toFixed(2)}</Typography>
              </Box>
            </Box>

            {userType !== "user" &&
              ticketData.invoice_status !== "Payment Received" && (
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  sx={{ mt: 3 }}
                >
                  Submit
                </Button>
              )}

            {userType === "user" &&
              ticketData.invoice_status === "Invoice Generated" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <PayPalButtons
                    style={{
                      layout: "vertical", // You can keep this if you prefer a vertical layout
                      height: 40, // Set a desired height
                      width: 800, // Set a desired width (you may adjust this based on the available space)
                      padding: 55, // Optional: reduces the button padding to make it smaller
                      shape: "rect", // Optional: makes the button rectangular, rather than a pill shape
                    }}
                    createOrder={createOrder}
                    onApprove={onApprove}
                    fundingSource="paypal" // Default to PayPal and Credit/Debit option
                  />
                  <Button
                    onClick={handleZelleModal}
                    variant="contained"
                    sx={{ mt: -1 }}
                    style={{ height: 40, width: 150 }}
                  >
                    Pay With Zelle
                  </Button>
                </Box>
              )}
          </>
        )}
        <Modal
          open={openZelleModal}
          onClose={(event, reason) => {
            if (reason === "backdropClick") {
              return; // Prevent closing when clicking outside
            }
            // setOpenTransactionModal(false); // Close only when explicitly intended
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {loading ? <Box sx={{...style, alignItems:'center'}}><CircularProgress size="3rem" color='success'/></Box>
          :
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ fontWeight: "bold" }}
            >
              Please Follow the instructions below to pay with Zelle
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              1. Open your Zelle App<br></br>
              2. Click on Send Money<br></br>
              3. Enter the amount of ${total.toFixed(2)}
              <br></br>
              4. Enter the following email address:{" "}
              <strong>Info@savetaxllc.com</strong>
              <br></br>
              5. Click on Send
            </Typography>
            <Typography
              variant="caption"
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              Once you have made the payment, click on the button below
            </Typography>
            <Divider sx={{ mt: 2 }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Button
                onClick={handleTransactionModal}
                variant="contained"
                sx={{ mt: 2 }}
              >
                On it! I have paid
              </Button>
              <Button
                onClick={() => setOpenZelleModal(false)}
                variant="contained"
                sx={{ mt: 2, backgroundColor: "red", color: "white" }}
              >
                I will Pay Later
              </Button>
            </Box>
          </Box>
}
        </Modal>
        <Modal
          open={openTransactionModal}
          onClose={(event, reason) => {
            if (reason === "backdropClick") {
              return; // Prevent closing when clicking outside
            }
            // setOpenTransactionModal(false); // Close only when explicitly intended
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {loading ? <Box sx={{...style, alignItems:'center'}}><CircularProgress size="3rem" color='success'/></Box>
          :
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ fontWeight: "bold" }}
            >
              Please Enter the Transaction ID
            </Typography>
            <TextField
              id="outlined-basic"
              label="Transaction ID"
              variant="outlined"
              sx={{ mt: 2 }}
              helperText="Enter the Transcation id"
              onChange={(e) => setTransactionId(e.target.value)}
            />

            <Divider sx={{ mt: 2 }} />
            <Button
              onClick={saveTransaction}
              variant="contained"
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
          </Box>
}
        </Modal>
        <Modal
          open={openNameSearchModal}
          onClose={(event, reason) => {
            if (reason === "backdropClick") {
              return; // Prevent closing when clicking outside
            }
            // setOpenTransactionModal(false); // Close only when explicitly intended
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {loading ? <Box sx={{...style, alignItems:'center'}}><CircularProgress size="3rem" color='success'/></Box>
          :
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ fontWeight: "bold" }}
            >
              We could not able to find the transaction. Can you please enter the Zelle Account Holder's Name used for the transaction?
            </Typography>
            <TextField
              id="outlined-basic"
              label="Enter Name"
              variant="outlined"
              sx={{ mt: 2 }}
              helperText="Enter the Zelle Account Holder Name"
              onChange={(e) => setAccountHolderName(e.target.value)}
            />

            <Divider sx={{ mt: 2 }} />
            <Button
              onClick={handleNameSearchModal}
              variant="contained"
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
          </Box>
}
        </Modal>
      </Box>
    );
};

export default Invoice;
