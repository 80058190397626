import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';

import { Box, Button, Grid, TextField, Typography, TextareaAutosize, Radio, RadioGroup, FormControlLabel, IconButton } from '@mui/material';
import { getValidAccessToken } from '../../../utils/tokenUtils';
import jsPDF from 'jspdf';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const apiUrl = process.env.REACT_APP_API_URL;

const BusinessFormationForm = ({ userType, ticketData, setTicketData }) => {
    const [businessFormationDetails, setBusinessFormationDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isVerified, setIsVerified] = useState(ticketData?.organizer_verified);
    const [partners, setPartners] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');


    function toPascalCase(str) {
        return str
            .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
                index === 0 ? match.toUpperCase() : match.toUpperCase()
            )
            
    }
    

    useEffect(() => {
        const fetchBusinessFormationDetails = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'business_formation_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.business_formation_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setBusinessFormationDetails(parsedDetails);
                    setPartners(parsedDetails.partners || []);
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBusinessFormationDetails();
    }, [ticketData.tax_organizer_id]);

    const handleSubmit = async () => {
        try {
            setSuccessMessage('');

            const token = await getValidAccessToken();
            const response = await axios.post(
                `${apiUrl}/update-taxorganizer-field/`,
                {
                    tax_organizer_id: ticketData.tax_organizer_id,
                    column_name: 'business_formation_details',
                    column_value: JSON.stringify({ ...businessFormationDetails, partners }),
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
          //  console.log('Form saved successfully:', response.data);
            setSuccessMessage('updated successfully!');

        } catch (error) {
            console.error('Error saving form:', error);
            setSuccessMessage('Error updating form. Please try again.');
        }
    };

    const handleVerify = async () => {
        try {
            const token = await getValidAccessToken();
            const response = await axios.get(
                `${apiUrl}/verify-document/?ticket_id=${ticketData?.ticket_id}&column=organizer`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.data.success) {
                if (response.data.change_ticket_status) {
                    setTicketData((prevData) => ({ ...prevData, ticket_status: 'Under Tax Preparation' }));}
                setIsVerified(true);
                setTicketData({
                    ...ticketData,
                    organizer_verified: true,
                    organizer_verified_by: 'Agent Name', // Adjust as needed
                });
            }
        } catch (error) {
            console.error('Error verifying document:', error);
        }
    };

    const handleDownloadPdf = () => {
        const doc = new jsPDF();
        doc.text('Business Formation Details', 10, 10);
       // console.log(businessFormationDetails);
        Object.entries(businessFormationDetails || {}).forEach(([key, value], index) => {
            doc.text(`${key}: ${value}`, 10, 20 + index * 10);
        });
        doc.save('business_formation_details.pdf');
    };

    const handleAddPartner = () => {
        setPartners([...partners, { first_name: '', middle_name: '', last_name: '', ssn: '', address_line1: '', address_line2: '', city: '', state: '', zip_code: '', phone_no: '', email: '' }]);
    };

    const handleRemovePartner = (index) => {
        setPartners(partners.filter((_, i) => i !== index));
    };

    const handlePartnerChange = (index, field, value) => {
        const updatedPartners = [...partners];
        updatedPartners[index] = { ...updatedPartners[index], [field]: value };
        setPartners(updatedPartners);
    };

    const fields = [
        'first_name',
        'middle_name',
        'last_name',
        'ssn',
        'address_line1',
        'address_line2',
        'city',
        'state',
        'zip_code',
        'phone_no',
        'email'
    ];

    const renderPartners = () => (
        <>
            {partners.map((partner, index) => (
                <Box key={index} sx={{ mb: 2, border: '1px solid gray', borderRadius: '4px', p: 2 }}>
                     <Grid container spacing={2}>
            {fields.map((field) => {
                if (field === 'ssn') {
                    return (
                        <Grid item xs={12} sm={6} key={field}>
                            <InputMask
                                mask="999-99-9999"
                                value={partner[field] || ''}
                                onChange={(e) => handlePartnerChange(index, field, e.target.value)}
                                disabled={userType !== 'user'}
                            >
                                {(inputProps) => (
                                    <TextField
                                        {...inputProps}
                                        label={toPascalCase(field.replace(/_/g, ' '))}
                                        inputProps={{ style: { fontSize: '16px' } }}
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            </InputMask>
                        </Grid>
                    );
                } else {
                    return (
                        <Grid item xs={12} sm={6} key={field}>
                            <TextField
                                label={toPascalCase(field.replace(/_/g, ' '))}
                                inputProps={{ style: { fontSize: '16px' } }}
                                value={partner[field] || ''}
                                disabled={userType !== 'user'}
                                onChange={(e) => handlePartnerChange(index, field, e.target.value)}
                                fullWidth
                                size="small"
                            />
                        </Grid>
                    );
                }
            })}
            <Grid item xs={12}>
                <IconButton disabled={userType !== 'user'} color="error" onClick={() => handleRemovePartner(index)}>
                    <RemoveIcon />
                </IconButton>
            </Grid>
        </Grid>
                </Box>
            ))}
            <Button variant="outlined" color="primary" onClick={handleAddPartner} disabled={userType !== 'user'}>
                Add Partner
            </Button>
        </>
    );

    const renderFormFields = () => (
        <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={1}>
                {/* Render Partners */}
                {renderPartners()}

                {/* Entity Type */}
                <Grid item xs={12}>
                    <RadioGroup
                        row
                        value={businessFormationDetails?.entity_type || ''}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, entity_type: e.target.value })}
                        disabled={userType !== 'user' || isVerified}
                    >
                        <FormControlLabel size="small" disabled={userType !== 'user'} value="Limited Liability Company" control={<Radio />} label="Limited Liability Company" />
                        <FormControlLabel size="small" disabled={userType !== 'user'} value="S-Corporation" control={<Radio />} label="S-Corporation" />
                        <FormControlLabel size="small" disabled={userType !== 'user'} value="Corporation" control={<Radio />} label="Corporation" />
                        <FormControlLabel size="small" disabled={userType !== 'user'} value="Partnership" control={<Radio />} label="Partnership" />
                        <FormControlLabel size="small" disabled={userType !== 'user'} value="Not sure" control={<Radio />} label="Not sure" />
                    </RadioGroup>
                </Grid>

                {/* Entity Names */}
                <Grid item xs={6}>
                    <TextField
                        label="First Choice Entity Name"
                        disabled={userType !== 'user'}
                        inputProps={{ style: { fontSize: '16px' } }}
                        value={businessFormationDetails?.first_choice_entity_name || ''}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, first_choice_entity_name: e.target.value })}
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Second Choice Entity Name"
                        disabled={userType !== 'user'}
                        inputProps={{ style: { fontSize: '16px' } }}
                        value={businessFormationDetails?.second_choice_entity_name || ''}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, second_choice_entity_name: e.target.value })}
                        fullWidth
                        size="small"
                    />
                </Grid>

                {/* Message */}
                <Grid item xs={12}>
                    <TextareaAutosize
                        minRows={4}
                        inputProps={{ style: { fontSize: '16px' } }}
                        placeholder="Message"
                        value={businessFormationDetails?.message || ''}
                        disabled={userType !== 'user' }
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, message: e.target.value })}
                        style={{ width: '100%' }}
                    />
                </Grid>

               
            </Grid>
            <Box mt={2}>
                {userType === 'user' && (
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        {businessFormationDetails ? 'Update' : 'Submit'}
                    </Button>
                )}

{successMessage && (
                <Typography variant="body1" color={successMessage.includes('Error') ? 'error' : 'success'}>
                    {successMessage}
                </Typography>
            )}
               
            </Box>
        </Box>
    );

    if (loading) return <Typography>Loading...</Typography>;

    return (
        <Box>
            {userType !== 'user' ? (
                <>
                    {!businessFormationDetails ? (
                        <Typography>Details are not provided</Typography>
                    ) : (
                        <>
                      <Box>
            <Box display="flex" justifyContent="flex-end" mb={2}>
                {userType !== 'user' && isVerified && (
                    <Button variant="contained" color="secondary" disabled>
                        Verified
                    </Button>
                )}
                {userType !== 'user' && !isVerified && (
                    <Button variant="contained" color="primary" onClick={handleVerify}>
                        Verify
                    </Button>
                )}
                {userType !== 'user' && (
                    <Button variant="contained" color="primary" onClick={handleDownloadPdf} style={{ marginLeft: 10 }}>
                        Download PDF
                    </Button>
                )}
            </Box>
            {renderFormFields()}
        </Box>


                        </>
                    )}
                </>
            ) : (
                renderFormFields()
            )}
        </Box>
    );
};

export default BusinessFormationForm;
